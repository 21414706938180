import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useNavigate} from 'react-router-dom';
import { Tabs, Tab} from 'react-bootstrap';
import TabContactoTickets from '@app/pages/contactos/ver/TabContactoTickets';
import TabContactoContratos from '@app/pages/contactos/ver/TabContactoTratos';
import ContactosService from '@app/services/contactos.service';
import { ButtonBack, LoaderSpinner } from '@app/components/index';

function ContactoVerCVVPage() {
    const {id} = useParams();
    const [key, setKey] = useState('tickets');
    const [isLoading, setIsLoading] = useState(true);
    const [contacto, setContacto] = useState();
    const navigate = useNavigate();
    const actualizar = () =>{
        setIsLoading(true)
        ContactosService.getById(id,{type: 'cvv'}).then((response)=>{
            setContacto(response)
            setIsLoading(false)
        })
        .catch(()=>{
            navigate('/cvv');
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }
    
    useEffect(() => {
        actualizar();
    }, [])

    return (
        <>
        {!isLoading ? (
        <section className="content" id='scrollTop'>
            <div className="container-fluid p-1">
                <div className="row">
                    <div className="col-md-2">
                        <div className="card card-primary card-outline">
                            <ButtonBack />
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src="/img/default-profile.png"
                                        alt="User profile"
                                    />
                                </div>
                                <h3 className="profile-username text-center">
                                        {contacto?.nombre_c}
                                </h3>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Total Camaras</b>
                                        <span className="float-right">
                                            {contacto?.tratos?.length}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Total Ticket</b>
                                        <span className="float-right">
                                            {contacto?.tickets?.length}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div>
                            {
                                !isLoading && (
                                    <Tabs
                                        id="contactosTab"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                    >
                                        <Tab eventKey="tickets" title="Tickets" tabClassName="card-body">
                                            <TabContactoTickets tickets={contacto?.tickets} />
                                        </Tab>
                                        <Tab eventKey="contratos" title={contacto?.isCamera_c ? "Camaras" : "Contratos"} tabClassName="card-body">
                                            <TabContactoContratos tratos={contacto?.tratos} />
                                        </Tab>
                                    </Tabs>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ):(
            <LoaderSpinner />
        )}
        </>
    )
}



export default ContactoVerCVVPage
