import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectTipoCVV = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps,
    disabled,
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const options = [
        {
            value: 'CVV 1 - CAPITAL',
            label: 'CVV 1 - CAPITAL'
        },
        {
            value: 'CVV 2 - ORAN',
            label: 'CVV 2 - ORAN'
        },
        {
            value: 'CVV 3 - METAN',
            label: 'CVV 3 - METAN'
        },
        {
            value: 'CVV 4 - TARTAGAL',
            label: 'CVV 4 - TARTAGAL'
        },
        {
            value: 'CVV 5 - JVG',
            label: 'CVV 5 - JVG'
        },
        {
            value: 'CVV 6 - CAFAYATE',
            label: 'CVV 6 - CAFAYATE'
        }
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value]);

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={disabled}
            />
        </Styles>
    );
};

SelectTipoCVV.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectTipoCVV;