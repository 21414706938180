import store from '@app/store/index';
import AuthService from "../../services/auth.service";

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_MENSAJES = 'LOAD_MENSAJES';

export const login = (email, pass) => (dispatch) => {
    return AuthService.login(email, pass).then(
    (data) => {
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');
        dispatch(
        {
            type: LOGIN_USER,
            payload: { token: data.token,tokenNubi: data?.tokenNubi,tokenStr: data?.tokenStr },
        });
        return Promise.resolve();
    })
};

export const userme = () => (dispatch) => {
    const {token} = store.getState().auth;
    return AuthService.usermeTK({token: token}).then(
        (data) => {
            dispatch({
                type: LOAD_USER,
                payload: { currentUser: data },
            });
        return Promise.resolve();
    })
};

export const logout = () => (dispatch) => {
    return dispatch({
            type: LOGOUT_USER,
            payload: {}
        });
};

export const mensajes = () => (dispatch) => {
    return AuthService.mensajes({
        length:3,
        vistos: false,
    }).then((response)=>{
        dispatch({
            type: LOAD_MENSAJES,
            payload: response,
        });
    });
}