import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Modal,ButtonGroup} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Button } from '@app/components/index';

function ModalConfirm({show, hideModal, title, onClickConfirmVisible,onClickConfirmOculto,onClickCloseModal}) {
    const handleClose = () => hideModal();
    const onClickConfirmVisibl = () => {
        onClickConfirmVisible();
        handleClose();
    };
    const onClickConfirmOcult = () => {
        onClickConfirmOculto();
        handleClose();
    };
    const onCLoseAll = () =>{
        onClickCloseModal()
        handleClose()
    }
    const isLoadingBlock = useSelector(state => state.modal.isLoadingBlock);
    const isPresent = useSelector(state => state.modal.isPresent);

    useEffect(() => {
        if(!isLoadingBlock && isPresent){
            hideModal();
        }
    }, [isLoadingBlock])
    
    return (
        <Modal show={show} backdrop="static" className="fade" onHide={()=>onCLoseAll()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <ButtonGroup>
                <Button 
                    onClick={()=>{
                        onClickConfirmVisibl()
                    }}
                >
                    PUBLICO
                </Button>
                <Button 
                    onClick={()=>{
                        onClickConfirmOcult()
                    }}
                >
                    INTERNO
                </Button>
            </ButtonGroup>
            <Modal.Footer>
                <Button theme="default" onClick={()=>onCLoseAll()}>
                    CANCELAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalConfirm.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
};

ModalConfirm.defaultProps = {
    show: false,
    title: '',
};

export default ModalConfirm;