
const types = {

    ticketId: '[Tk] Id',
    ticketReloadStart: '[Tk] Reload Start',
    ticketReloadFinish: '[Tk] Reload Finish',

    ticketReloadIndStart: '[Tk] Reload Individual Start',
    ticketReloadIndFinish: '[Tk] Reload Individual Finish',

    ticketReloadGroupStart: '[Tk] Reload Group Start',
    ticketReloadGroupFinish: '[Tk] Reload Group Finish',

    ticketReloadPendienteStart: '[Tk] Reload Pendiente Start',
    ticketReloadPendienteFinish: '[Tk] Reload Pendiente Finish',

    ticketReloadInvalidStart: '[Tk] Reload Invalid Start',
    ticketReloadInvalidFinish: '[Tk] Reload Invalid Finish',

    ticketReloadExternaStart: '[Tk] Reload Externa Start',
    ticketReloadExternaFinish: '[Tk] Reload Externa Finish'
}

export default types