import React, { useEffect, useState } from 'react'
import { TratoCard } from '@app/components/index';

function TabsSearchTrato({ textSearch, data, handleCloseModal }) {
    const [tickets, setTickets] = useState(data || []);

    useEffect(() => {
        setTickets(data);
    }, [data])
    return (
        <div className="p-3 p-lg-5">
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    {tickets?.length > 0 ? (
                        tickets.map((ticket)=>(
                            <TratoCard
                                textSearch={textSearch}
                                item={ticket}
                                onCloseModal={handleCloseModal}
                                key={ticket?.id_t}
                                className="mb-3"
                            />
                        ))
                    ):(
                        <div className='alert alert-light mt-2 text-center'>
                            <i className="fas fa-user" style={{fontSize: 200, color: 'gray'}}></i>
                            <div style={{fontSize: 20, color: 'gray'}}>
                                No se encontraron camaras
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TabsSearchTrato