import { LoaderSpinner } from '@app/components/index';
import DownloadFile from '@app/services/downloadFile.service';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import AnimatedText from 'react-animated-text-content';

function ModalLoadingExcel({show, hideModal, form,tipo}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(true)

    const enviarArchivoOperatividad = () =>{
        DownloadFile.solicitarHistorial(form).then((resp)=>{
            setIsLoading(false)
            setTimeout(() => {
                handleClose()
            }, 4500);
        }).catch((errs)=>{
            console.log(errs)
        })
    }

    const enviarArchivoTicket = () =>{
        DownloadFile.solicitarTicket(form).then((resp)=>{
            setIsLoading(false)
            setTimeout(() => {
                handleClose()
            }, 4500);
        }).catch((errs)=>{
            console.log(errs)
        })
    }
    const enviarArchivoTicketNubicom = () =>{
        DownloadFile.solicitarTicketNubicom(form).then((resp)=>{
            setIsLoading(false)
            setTimeout(() => {
                handleClose()
            }, 4500);
        }).catch((errs)=>{
            console.log(errs)
        })
    }
    useEffect(() => {
        setTimeout(() => {
            if(tipo === 'operatividad'){
                enviarArchivoOperatividad()
            }else if(tipo === 'ticket'){
                enviarArchivoTicket()
            }else if(tipo === 'ticketEsp'){
                enviarArchivoTicketNubicom()
            }else{
                handleClose()
            }
        }, 2000);
    }, [])

    return (
        <Modal show={show} backdrop="static" size='lg'>
            <div className='col-12 bg-dark'>
                <div className='col-12 mt-2 mb-2' style={{backgroundColor: '#ff814c',height:500}}>
                    <div className='col-12 row' style={{backgroundColor: '#ff814c',height:500}}>
                        <div className='col-md-12 col-lg-7 d-flex justify-content-center align-items-center'>
                            <img src="/img/send-mail.gif" alt="user-avatar" style={{width: 340}} />
                        </div>
                        <div className='col-md-12 col-lg-5  d-flex justify-content-center align-items-center'>
                            {isLoading ?(
                                <AnimatedText
                                    type="words" // animate words or chars
                                    animation={{
                                        x: '200px',
                                        y: '-20px',
                                        scale: 1.1,
                                        ease: 'ease-in-out',
                                    }}
                                    animationType="throw"
                                    interval={0.06}
                                    duration={0.8}
                                    tag="h4"
                                    className="animated-paragraph"
                                    includeWhiteSpaces
                                    threshold={0.1}
                                    rootMargin="20%"
                                >
                                    Estamos procesando su socilitud...
                                </AnimatedText>
                            ):(
                                <AnimatedText
                                    type="words" // animate words or chars
                                    animation={{
                                        x: '200px',
                                        y: '-20px',
                                        scale: 1.1,
                                        ease: 'ease-in-out',
                                    }}
                                    animationType="throw"
                                    interval={0.06}
                                    duration={0.8}
                                    tag="h4"
                                    className="animated-paragraph"
                                    includeWhiteSpaces
                                    threshold={0.1}
                                    rootMargin="20%"
                                >
                                    En breve recibira su solicitud a su correo.
                                </AnimatedText>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ModalLoadingExcel;