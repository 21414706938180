import axios from 'axios';
const base = 'logs/tickets';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}


const LogsTicketsService = {
    getAll: getAll,
};
export default LogsTicketsService;
