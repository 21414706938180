import React from 'react'
import styled from 'styled-components';
import { Button } from '@app/components/index';
import {showModal} from 'react-redux-modal-provider';
import {useNavigate} from 'react-router-dom';
import ModalContratoTicket from '@app/modals/trato/ModalContratoTicket';


const Styles = styled.div`

`;

function ContratoCuentaCard({
    item,
}) {
    const navigate = useNavigate();
    const onOpenModal = () => {
        showModal(ModalContratoTicket, {item: item?.tickets, history:navigate, id:`#${item?.numero} - ${item?.descripcion}`});
    };
    const onOpenVer = () => {
        navigate(`/contrato/${item.numero}/cuenta`);
    }
    return (
        <Styles className="card border border-primary redondeado shadow-sm p-3 m-3">
            <div className="col-12">
                <div className="row">
                    <div className="col-10">
                        <div className="col-md-12 card-title text-truncate">
                            {`#${item?.numero} - ${item?.descripcion}`}
                        </div>
                        <div className="card-body">
                            <div className="col-md-12 mt-4">{`${item?.tipo} ${item?.nombre}`}</div>
                        </div>
                    </div>
                    <div className="col-2">
                        <Button onClick={()=>onOpenVer(item)}>
                            Ver Contrato
                        </Button>
                    </div>
                </div>

            </div>
            <Button block onClick={()=>onOpenModal()}>
                Ver Tickets
            </Button>
        </Styles>
    )
}

export default ContratoCuentaCard
