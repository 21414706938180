import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from '../../components/button/Button';
import {login} from '../../store/actions/auth';

const schema = Yup.object({
    email: Yup.string().email('Dirección de correo electrónico no válida').required(),
    pass: Yup.string().min(5, 'Debe tener 5 caracteres o más').max(30, 'Debe tener 30 caracteres o menos').required()
});

const LoginScreen = ({onUserLogin}) => {
    AOS.init();
    const [isAuthLoading, setAuthLoading] = useState(false);
    const navigate = useNavigate();
    const [t] = useTranslation();
    const [mostrarpass, setMostrarpass] = useState(false)

    const onLogin = (email, pass) => {
        setAuthLoading(true);
        onUserLogin(email, pass).then(() => {
            navigate('/loading');
        }).catch(()=>{
            setAuthLoading(false);
        });
    };

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            email: '',
            pass: ''
        }
    });

    const onSubmit = (values) => {
        onLogin(values.email,values.pass);
    }

    document.getElementById('root').classList = 'hold-transition login-page';

    return (
        <div className="login-box">
            <div className="card card-outline card-primary" data-aos="fade-down">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <img src="/img/login-911-salta.png" alt="login" className="logo"></img>
                    </Link>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder="Email"
                                    ref={register}
                                />
                            </div>
                            {errors.email ? (
                                <div className="default-invalid-feedback">
                                    {errors.email?.message}
                                </div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                                <input
                                    id="pass"
                                    name="pass"
                                    type={!mostrarpass ? `password` : ``}
                                    className={`form-control ${errors.pass ? 'is-invalid' : ''}`}
                                    placeholder="Contraseña"
                                    ref={register}
                                />
                                <div className="input-group-append">
                                    <div aria-hidden className="input-group-text" onClick={()=>setMostrarpass(!mostrarpass)}>
                                        <span className={mostrarpass ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                                    </div>
                                </div>
                            </div>
                            {errors.pass ? (
                                <div className="default-invalid-feedback">
                                    {errors.pass?.message}
                                </div>
                            ) : null}
                        </div>
                        <div className="row">
                            <div className="col-8">
                            {/**
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        {t('login.label.rememberMe')}
                                    </label>
                                </div>
                                */}
                            </div>
                            <div className="offset-3 mt-2 col-6">
                                <Button
                                    block
                                    type="submit"
                                    isLoading={isAuthLoading}
                                    disabled={isAuthLoading}
                                >
                                    {t('login.button.signIn.label')}
                                </Button>
                            </div>
                        </div>
                    </form>
                    <div className="social-auth-links text-center mt-2 mb-3"></div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (email,pass) => dispatch(login(email,pass))
});

export default connect(null, mapDispatchToProps)(LoginScreen);
