import React, {useState,useContext, useEffect} from 'react';
import {Button, InputDropZone, InputEditQuill, Select, SelectGruposEsp, SelectMotivoIng, TabCierreExterno} from '@app/components/index';
import { Tabs, Tab, Modal,Button as Btn} from 'react-bootstrap';
import TicketService from '@app/services/tickets.service';
import {toast} from 'react-toastify';
import Compressor from 'compressorjs';
import { SocketContext } from '../../context/SocketContext';


function ModalCierreTicket({show, hideModal, ticketId,onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [cierre, setCierre] = useState('CONECTIVIDAD')
    const [tipoResolucion, setTipoResolucion] = useState('REMOTO')
    const [tipoResolucionEmp, setTipoResolucionEmp] = useState(null)
    const [motivoFalla, setMotivoFalla] = useState(12)
    const [motivoFallaNombre, setMotivoFallaNombre] = useState('OTRO')
    const { sockets } = useContext( SocketContext );
    const [comentario, setComentario] = useState()
    const [key, setKey] = useState('correcto');
    const [foto, setFoto] = useState()
    const [foto2, setFoto2] = useState()
    const [foto3, setFoto3] = useState()
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png, video/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const onSubmit = () =>{
        if(comentario && comentario.length > 8){
            const form={
                id_tk: ticketId,
                contratoNubi_tk: cierre,
                comentarioCierre: comentario,
                type: 'cvv',
                tipoRes_dtk: tipoResolucion,
                empRes_dtk: tipoResolucionEmp,
                motivoIngId_dtk: motivoFalla,
                motivoIngNombre_dtk: motivoFallaNombre,
            }
            if(foto){
                form.foto = foto;
            }
            if(foto2){
                form.foto2 = foto2;
            }
            if(foto3){
                form.foto3 = foto3;
            }
            setIsLoading(true);
            TicketService.cerrarTicket(form).then(()=>{
                sockets?.emit( 'ticketRefresh', `cvv${ticketId}` );
                onGuardar();
                hideModal();
            })
            .finally(()=>{
                setIsLoading(false);
            })
        }else{
            toast.error('Falta el comentario', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
    }
    useEffect(() => {
        if(foto?.length > 0){
            const {name,size} = foto[0];
            if(size > 20000000){
                setFoto(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto(result);
                        }
                    })
                }
            }
        }
    }, [foto])

    useEffect(() => {
        if(foto2?.length > 0){
            const {name,size} = foto2[0];
            if(size > 20000000){
                setFoto2(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto2[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto2(result);
                        }
                    })
                }
            }
        }
    }, [foto2])
    useEffect(() => {
        if(foto3?.length > 0){
            const {name,size} = foto3[0];
            if(size > 20000000){
                setFoto3(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto3[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto3(result);
                        }
                    })
                }
            }
        }
    }, [foto3])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`¿Esta seguro de cerrar el Ticket #${ticketId}?`}
                </Modal.Title>
            </Modal.Header>
                <Tabs
                    id="contactosTab"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="correcto" title="Ticket Valido" tabClassName="card-body">
                        <div className='ml-3 mr-3'>
                            <h5 className='m-3 text-center text-bold'>Nuevos Requisitos</h5>
                            <div className='row'>
                                <div className='col-3 mb-2'>
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="Prioridad">
                                            Modo de resolucion
                                        </label>
                                        <Select
                                            defaultValue={tipoResolucion}
                                            onChange={(opt)=>setTipoResolucion(opt?.value || null)}
                                            options={[
                                                {
                                                    label:'INSITU',
                                                    value: 'INSITU',
                                                },
                                                {
                                                    label: 'REMOTO',
                                                    value: 'REMOTO',
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className='col-3 mb-2'>
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="Prioridad">
                                            Áreas
                                        </label>
                                        <SelectGruposEsp
                                            id="selectGrupo2"
                                            contactoId={tipoResolucionEmp}
                                            onChange={(opt) => setTipoResolucionEmp(opt.value)}
                                        />
                                    </div>
                                </div>
                                <div className='col-6 mb-2'>
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="Prioridad">
                                            Motivo de Falla
                                        </label>
                                        <SelectMotivoIng
                                            id="selectmotivofalla"
                                            value={motivoFalla}
                                            onChange={(opt)=>{
                                                setMotivoFalla(opt?.value || null)
                                                setMotivoFallaNombre(opt?.label || null)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <h5 className='m-3 text-center text-bold'>Por favor indique el contrato con el que se cerro el ticket.</h5>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Btn block variant={cierre === 'CONECTIVIDAD' ? 'primary' : 'outline-danger'} onClick={()=>{setCierre('CONECTIVIDAD')}}>CONECTIVIDAD</Btn>
                                </div>
                                <div className='col-6'>
                                    <Btn block variant={cierre === 'MANTENIMIENTO' ? 'primary' : 'outline-danger'} onClick={()=>{setCierre('MANTENIMIENTO')}}>MANTENIMIENTO</Btn>
                                </div>
                            </div>
                            <h5 className='text-bold ml-1'>Comentario:</h5>
                            <div className="row ml-1 mr-1">
                                <div className='col-12'>
                                    <InputEditQuill 
                                        id={1}
                                        template=''
                                        onChangeText={(html)=>{
                                            setComentario(html)
                                        }}
                                    />
                                </div>
                                <p className="col-12">Limite permitido por archivo (20Mb)</p>
                                <div className="attachments d-flex ml-3 mb-3">
                                    <div className="video-holder">
                                    <InputDropZone
                                        id="fotos1"
                                        accept={acceptInputFile}
                                        defaultImage={foto}
                                        backgroundImage="fileUpload"
                                        onChange={setFoto} 
                                        setFoto={setFoto}
                                    />
                                    </div>
                                    <div className="image-holder ml-3">
                                        <InputDropZone
                                            id="fotos2"
                                            accept={acceptInputFile}
                                            defaultImage={foto2}
                                            backgroundImage="fileUpload"
                                            onChange={setFoto2} 
                                            setFoto={setFoto2}
                                        />
                                    </div>
                                    <div className="image-holder ml-3">
                                        <InputDropZone
                                            id="fotos3"
                                            accept={acceptInputFile}
                                            defaultImage={foto3}
                                            backgroundImage="fileUpload"
                                            onChange={setFoto3} 
                                            setFoto={setFoto3}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='float-right'>
                                <Button theme="default" size='xl' onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button
                                    size='xl'
                                    theme="primary"
                                    onClick={onSubmit}
                                    isLoading={isLoading}
                                >
                                    Cerrar Ticket
                                </Button>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="incidenteexterno" title="Incidente Externo" tabClassName="card-body">
                        <TabCierreExterno ticketId={ticketId} hideModal={hideModal} onGuardar={onGuardar} />
                    </Tab>
                </Tabs>
        
        </Modal>
    );
}
export default ModalCierreTicket;