import axios from 'axios';

function ticketExcel(query = null) {
    return axios.get('download/excelcvv',{
        responseType: 'blob',
        params: query
    });
}
function ticketPdf(query = null) {
    return axios.get('download/pdfcvv',{
        responseType: 'blob',
        params: query
    });
}
function historyExcel(query = null) {
    return axios.get('download/hisexcel',{
        responseType: 'blob',
        params: query
    });
}

function solicitarHistorial(query = null) {
    return axios.get('download/solihisexcel', {
        params: query
    });
}

function solicitarTicket(query = null) {
    return axios.get('download/solitkexcel', {
        params: query
    });
}

function mapActualCamaras() {
    return axios.get('download/mapactual');
}

function solicitarTicketNubicom(query = null) {
    return axios.get('download/solitkexcelesp', {
        params: query
    });
}

const DownloadFile = {
    ticketExcel,
    ticketPdf,
    historyExcel,
    solicitarHistorial,
    solicitarTicket,
    mapActualCamaras,
    solicitarTicketNubicom
};
export default DownloadFile;
