import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AOS from 'aos';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import {Dropdown} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import {Button} from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import ContactosAction from '@app/store/actions/contactos';
import ContactosService from '@app/services/contactos.service';
import ModalCVVEditar from '@app/modals/contacto/ModalCVVEditar';
import usePermisos from '@app/hooks/usePermisos';


const CvvCamarasPage = ({
    iniciarContacto,
    iniciarContrato
}) => {
    AOS.init();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const permisos = usePermisos();

    const actualizar = async () => {
        ContactosService.getAll({
            type: 'cvv'
        }).then((r)=>{
            setItems(r)
        })
    };

    const onGuardar = () => {
        actualizar();
    };

    const onClickNuevo = () => {
        showModal(ModalCVVEditar, {onGuardar: onGuardar});
    };

    const onOpenVer = (item) => {
        navigate(`/cvv/${item.id_c}/ver`);
    }

    const onOpenEditar = (item) => {
        showModal(ModalCVVEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Registrado',
                Filter: false,
                accessor: 'createdAt',
                disableGlobalFilter: true,
                Width: 90,
                Cell: ({row}) => {
                    if(row.original?.createdAt){
                        return FechaFunction.format(row.original?.createdAt,'dd/MM/yyyy');
                    }
                    return '';
                },
            },{
                Header: 'Nombre',
                accessor: 'nombre_c',
            },{
                Header: 'Direccion',
                accessor: 'direccion_c',
            },
            {
                Width: 170,
                Header: '',
                accessor: 'id',
                disableSortBy: false,
                Filter: false,
                Cell: ({row}) => (
                    permisos && permisos['contacto.abm911'] ? (
                        <>
                            <button 
                                type="button"
                                className="btn-circle"
                                style={{background:'#CBC9C9',fontSize:'20px'}}
                                onClick={()=>onOpenVer(row.original)}
                            >
                                <i className="fas fa-eye" />
                            </button>
                            <button 
                                type="button"
                                className="btn-circle ml-2"
                                style={{fontSize:'18px',color:'blue',backgroundColor: '#d3d3d3d3'}}
                                onClick={()=>onOpenEditar(row.original)}
                            >
                                <i className="fas fa-edit" />
                            </button>
                        </>
                    ):(
                        <button 
                            type="button"
                            className="btn-circle"
                            style={{background:'#CBC9C9',fontSize:'20px'}}
                            onClick={()=>onOpenVer(row.original)}
                        >
                            <i className="fas fa-eye" />
                        </button>
                    )
                )
            }
        ],
        [permisos]
    );

    useEffect(()=>{
        actualizar();
    },[])

    useEffect(() =>{ 
        iniciarContacto(null);
        iniciarContrato(null);
    },[])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 my-1 col-md-6 col-lg-9 mt-lg-3" data-aos="fade-right">
                        <h2 className='user-select-none'>Centro de Video Vigilancia</h2>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-lg-3 my-1" data-aos="fade-down">
                    {permisos && permisos['contacto.abm911'] &&(
                        <Button color="primary" className="btn-block w-75 float-right" onClick={()=>onClickNuevo()}>
                            NUEVO CVV
                        </Button>
                    )}
                    </div>
                    <div className="col-12" data-aos="fade-right">
                        <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CvvCamarasPage);
