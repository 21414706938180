import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, DateSingle, Select,SelectMotivoTicket} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import CanalesService from '@app/services/canales.service';
import {yupResolver} from '@hookform/resolvers/yup';
import FechaFunction from '@app/functions/FechaFunction';
import TicketService from '@app/services/tickets.service';
import {showModal} from 'react-redux-modal-provider';
import ModalTicketInvalido from '@app/modals/ticket/ModalTicketInvalido';
import { SocketContext } from '@app/context/SocketContext';

const schema = Yup.object({
});

function ModalTicketPrioridad({show, hideModal, item,trato, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [prioridad, setPrioridad] = useState('Nivel 1 (Leves)');
    const [motivo, setMotivo] = useState(null);
    const { sockets } = useContext( SocketContext );

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });
    const cerrarModal = () =>{
        hideModal();
    }
    const onOpenTicketInvalido = () =>{
        showModal(ModalTicketInvalido, { ticketId:item.id_tk, onGuardar:onGuardar, hide:cerrarModal  } )
    }
    const vtoTicket = watch('vtoTicket');

    const onSubmit = (data) => {
        if(motivo){
            const form = {
                ...data,
                id_tk: item.id_tk,
                fechavencimiento_tk:vtoTicket,
                prioridad_tk:prioridad,
                motivo_tk: motivo,
                propertys: 'cvv',
                createdEmp_tk: 'CVV'
            };
            setIsLoading(true);
            TicketService.update(form).then(()=>{
                sockets?.emit('ticketRefresh', `cvv${item?.id_tk}`)
                if(onGuardar){
                    onGuardar();
                }
                hideModal();
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
    };
    useEffect(() => {
        register('vtoTicket');
    }, [register]);

    useEffect(() => {
        if(trato?.isCapital_t){
            switch (prioridad) {
                case 'Nivel 1 (Leves)':
                    setValue('vtoTicket', FechaFunction.addDaysDate(new Date(item.createdAt),4))
                    break;
                case 'Nivel 2 (Graves)':
                    setValue('vtoTicket', FechaFunction.addHoursDate(new Date(item.createdAt),4))
                    break;
                case 'Nivel 3 (Criticos)':
                    setValue('vtoTicket', FechaFunction.addHoursDate(new Date(item.createdAt),4))
                    break;
                default:
                    setValue('vtoTicket', FechaFunction.addDaysDate(new Date(item.createdAt)))
                    break;
            }
        }else{
            switch (prioridad) {
                case 'Nivel 1 (Leves)':
                    setValue('vtoTicket', FechaFunction.addDaysDate(new Date(item.createdAt),4))
                    break;
                case 'Nivel 2 (Graves)':
                    setValue('vtoTicket', FechaFunction.addHoursDate(new Date(item.createdAt),4))
                    break;
                case 'Nivel 3 (Criticos)':
                    setValue('vtoTicket', FechaFunction.addHoursDate(new Date(item.createdAt),4))
                    break;
                default:
                    setValue('vtoTicket', FechaFunction.addDaysDate(new Date(item.createdAt)))
                    break;
            }
        }
    }, [prioridad])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Prioridad Ticket
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label
                            className="label-control"
                            htmlFor="motivo_tk"
                        >
                            Motivo
                        </label>
                        <SelectMotivoTicket
                            id="motivo_tk"
                            type='cvv'
                            value={motivo}
                            onChange={(opt)=>setMotivo(opt.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="Prioridad">
                            Prioridad
                        </label>
                        <Select
                            defaultValue={prioridad}
                            onChange={(opt)=>setPrioridad(opt?.value || null)}
                            options={[
                                {
                                    label: 'Nivel 1 (Leves)',
                                    value: 'Nivel 1 (Leves)',
                                },
                                {
                                    label: 'Nivel 2 (Graves)',
                                    value: 'Nivel 2 (Graves)',
                                },
                                {
                                    label: 'Nivel 3 (Criticos)',
                                    value: 'Nivel 3 (Criticos)',
                                }
                            ]}
                        />
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="vtoTicket">
                            Fecha de Vencimiento
                        </label>
                        <DateSingle
                            id="vtoTicket"
                            value={vtoTicket}
                            className={`form-control ${errors.vtoTicket? 'is-invalid': ''}`}
                            err
                            minDate={new Date()}
                            onChange={(value)=>setValue('vtoTicket',value)}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    theme="primary"
                    onClick={onOpenTicketInvalido}
                    isLoading={isLoading}
                >
                    Ticket Invalido/Mal Cargado
                </Button>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalTicketPrioridad.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_can: PropTypes.number,
    })
};

ModalTicketPrioridad.defaultProps = {
    show: false,
    item: {
        id_can: 0,
    }
};

export default ModalTicketPrioridad;