import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, InputDropZone} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import CustomExternalTicketService from '@app/services/customExternal.service';
import Compressor from 'compressorjs';

const schema = Yup.object({
    texto: Yup.string().max(200).required()
});

function ModalCancelarPreTicket({show, hideModal, item, onGuardar}) {
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, x-png'
    const [imgPreviewF1, setImgPreviewF1] = useState(null);
    const [imgPreviewF2, setImgPreviewF2] = useState(null);
    const extensionPermitida = ['png','jpg','jpeg','x-png'];

    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = (data) =>{
        const form ={
            id_ptk: item?.id_ptk,
            texto: data.texto
        }
        if(imgPreviewF1){
            form.adj1= imgPreviewF1;
        }
        if(imgPreviewF2){
            form.adj2= imgPreviewF2;
        }
        CustomExternalTicketService.preTicketInvalido(form).then(()=>{
            if(onGuardar){
                onGuardar();
            }
            setIsLoading(false)
            handleClose()
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    useEffect(() => {
        if(imgPreviewF1?.length > 0){
            const {name,size} = imgPreviewF1[0];
            if(size > 20000000){
                setImgPreviewF1(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF1[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF1(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF1])
    useEffect(() => {
        if(imgPreviewF2?.length > 0){
            const {name,size} = imgPreviewF2[0];
            if(size > 10000000){
                setImgPreviewF2(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF2[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF2(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF2])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Ticket Invalido/Mal Cargado - Incidencia #${item?.idIncidencia_ptk}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label className="label-control" htmlFor="texto">
                                Motivo
                            </label>
                            <input
                                className={`form-control ${errors.texto? 'is-invalid': ''}`}
                                id="texto"
                                name="texto"
                                type="text"
                                autoComplete="off"
                                ref={register}
                            />
                            {errors.texto && (
                                <div className="invalid-feedback">
                                    Debe completar el motivo.
                                </div>
                            )}
                        </div>
                        <h4>Archivos adjuntos</h4>
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className='col-5 mb-2'>
                                    <InputDropZone
                                        id="foto1"
                                        accept={acceptInputFile}
                                        defaultImage={imgPreviewF1}
                                        backgroundImage="fileUpload"
                                        onChange={setImgPreviewF1}
                                        setFoto={setImgPreviewF1}
                                        isDeletedNow={!setImgPreviewF1}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className='col-5'>
                                    <InputDropZone
                                        id="foto2"
                                        accept={acceptInputFile}
                                        backgroundImage="fileUpload"
                                        defaultImage={imgPreviewF2}
                                        onChange={setImgPreviewF2}
                                        setFoto={setImgPreviewF2}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        theme='default'
                        size='xl'
                        onClick={handleClose}
                        style={{width: 100}}
                        isLoading={isLoading}
                    >
                        Cerrar
                    </Button>
                    <Button
                        size='xl'
                        onClick={handleSubmit(onSubmit)}
                        style={{width: 100}}
                        isLoading={isLoading}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}

ModalCancelarPreTicket.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_ptk: PropTypes.number,
    })
};

ModalCancelarPreTicket.defaultProps = {
    show: false,
    item: {
        id_ptk: 0,
    }
};

export default ModalCancelarPreTicket;