import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/esm/Modal';
import MotivoTicketService from '@app/services/motivoTicket.service';
import {Button} from '@app/components/index';

const schema = Yup.object({
    motivo_mt: Yup.string().max(255).required()
});

function ModalMotivoTareaEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    
    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item
    });


    const onSubmit = (values) => {
        const data = {
            ...item,
            ...values,
            type: 'cvv'            
        };
        setIsLoading(true);
        if (item.id_mt > 0) {
            MotivoTicketService.update(data)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            MotivoTicketService.register(data)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleClose = () => hideModal();

    useEffect(()=>{
        register('motivo_mt')
    },[register])
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_mt > 0
                        ? 'Editar motivo ticket'
                        : 'Registrar motivo ticket'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="label-control" htmlFor="motivo_mt">
                                    Motivo
                                </label>
                                <input
                                    className={`form-control ${errors.motivo_mt ? 'is-invalid': ''}`}
                                    id="motivo_mt"
                                    name="motivo_mt"
                                    type="text"
                                    autoComplete="off"
                                    ref={register}
                                />
                                {errors.nombre ? (
                                    <div className="invalid-feedback">
                                        {errors.nombre?.message}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalMotivoTareaEditar.defaultProps = {
    show: false,
    item: {
        id_mt: 0,
        motivo_mt: '',
    }
};

export default ModalMotivoTareaEditar;
