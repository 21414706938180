import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, SelectTipoCam, SelectTipoCVV, SelectTipoOperatividad, SelectTipoTecnologia } from '@app/components/index';

function ModalFiltroMapa({show, hideModal,items,setFiltroItems,itemsFiltro,setItemsFiltro}) {
    const [tipoCVV, setTipoCVV] = useState(null);
    const [tipoCam, setTipoCam] = useState(null);
    const [tipoTec, setTipoTec] = useState(null);
    const [tipoOperatividad, setTipoOperatividad] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => hideModal();
    useEffect(() => {
        if(itemsFiltro?.tipoCVV){
            setTipoCVV(itemsFiltro.tipoCVV)
        }
        if(itemsFiltro?.tipoCam){
            setTipoCam(itemsFiltro.tipoCam)
        }
        if(itemsFiltro?.tipoTec){
            setTipoTec(itemsFiltro.tipoTec)
        }
        if(itemsFiltro?.tipoOperatividad){
            setTipoOperatividad(itemsFiltro.tipoOperatividad)
        }
    }, [])

    const onSubmit = () =>{
        const form = {}
        let dataFinal = items
        if(tipoCVV){
            dataFinal = dataFinal.filter((cam)=> cam.cvv === tipoCVV)
            form.tipoCVV = tipoCVV
        }
        if(tipoCam){
            dataFinal = dataFinal.filter((cam)=> cam.tipo === tipoCam)
            form.tipoCam = tipoCam
        }
        if(tipoTec){
            dataFinal = dataFinal.filter((cam)=> cam.tecnologia === tipoTec)
            form.tipoTec = tipoTec
        }
        if(tipoOperatividad){
            dataFinal = dataFinal.filter((cam)=> cam.operatividad === tipoOperatividad)
            form.tipoOperatividad = tipoOperatividad
        }
        setItemsFiltro(form)
        setFiltroItems(dataFinal)
        handleClose()
    }
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>
                    Filtro de Equipos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-12 row'>
                    <div className="col-12">
                        <label>CVV:</label>
                        <SelectTipoCVV
                            disabled={isLoading}
                            isClearable
                            id="tipoCVV"
                            name="tipoCVV"
                            value={tipoCVV}
                            onChange={(opt)=> setTipoCVV(opt?.value || null)}
                        />
                    </div>
                    <div className="col-6 mt-1">
                        <label>Tipo:</label>
                        <SelectTipoCam
                            disabled={isLoading}
                            isClearable
                            id="tipoCam"
                            name="tipoCam"
                            value={tipoCam}
                            onChange={(opt)=> setTipoCam(opt?.value || null)}
                        />
                    </div>
                    <div className="col-6 mt-1">
                        <label>Tecnologia:</label>
                        <SelectTipoTecnologia
                            disabled={isLoading}
                            isClearable
                            id="tipoTec"
                            name="tipoTec"
                            value={tipoTec}
                            onChange={(opt)=> setTipoTec(opt?.value || null)}
                        />
                    </div>
                    <div className="col-12">
                        <label>Operatividad:</label>
                        <SelectTipoOperatividad
                            disabled={isLoading}
                            isClearable
                            id="tipoOperatividad"
                            name="tipoOperatividad"
                            value={tipoOperatividad}
                            onChange={(opt)=> setTipoOperatividad(opt?.value || null)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={()=>onSubmit()}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalFiltroMapa;
