import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`
    width: 100%;
    input.form-control {
        cursor: pointer;
    }
`;

const MonthSingle = ({
    id, 
    value, 
    onChange, 
    disabled, 
    showTimeSelect,
    ...defaultProps
}) => {
    const [fecha, setFecha] = useState(value?FechaFunction.parse(value):null);

    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <>
                <input
                    onClick={onClick}
                    className="form-control"
                    value={valueField}
                    ref={ref}
                    readOnly
                    style={{textAlign: 'center'}}
                />
                <i 
                    className='fa fa-chevron-down'
                    style={{position: 'absolute',display: 'flex',justifyContent: 'center',alignItems: 'center', right:14,top:4,width: '40px',height: '30px'}}
                    onClick={onClick}
                    aria-hidden="true"
                />
            </>
        )
    );

    const onDateSelected = (newDate) => {
        setFecha(newDate);
        if (onChange) {
            if(FechaFunction.isDate(newDate)){
                onChange(FechaFunction.format(newDate));
            } else {
                onChange(null);
            }
        }
    };

    useEffect(() => {
        if(typeof value === 'string'){
            setFecha(FechaFunction.parse(value))
        }
        return () => {
            
        }
    }, [value])
    return (
        <Styles>
            <DatePicker
                {...defaultProps}
                id={id}
                name={id}
                disabled={disabled}
                dateFormat="MMMM yyyy"
                showTimeSelect={showTimeSelect}
                customInput={<CustomInput />}
                selected={fecha}
                onChange={date => onDateSelected(date)}
                showMonthYearPicker
            />
        </Styles>
    );
};

MonthSingle.defaultProps = {
    value: new Date(),
    disabled: false,
    showTimeSelect: false,
};

export default MonthSingle;
