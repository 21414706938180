import React, { useState,useEffect } from 'react'
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {  FormTratosCam } from '@app/components/index';


function TabTratoEditar({
    item
}) {
    const {control,errors,register, setValue,watch} = useForm({
    });
    const [detalle, setDetalle] = useState({});
    useEffect(() => {
        if(item?.tratocam.length > 0){
            setDetalle(item.tratocam[0])
        }
    }, [])
    useEffect(() => {
        register('lugarId_t')
    }, [register])

    return (
        <div className="p-5">
            <FormTratosCam isModal item={item} detalle={detalle} control={control} errors={errors} register={register} setValue={setValue} watch={watch} hideTareaForm />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TabTratoEditar)
