import axios from 'axios';
const base = 'acciones';
const base2 = 'acciones/autorizados';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}
function getAllAutorizados(query = null) {
    return axios.get(base2, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

const AccionesService = {
    getAll,
    getAllAutorizados,
    register,
    update
};
export default AccionesService;
