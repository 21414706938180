import React, {useState, useEffect,useContext} from 'react';
import {Modal,Collapse} from 'react-bootstrap';
import {Button, InputEditQuill, SelectTrato, InputDropZone} from '@app/components/index';
import * as Yup from 'yup';
import TicketService  from '@app/services/tickets.service';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Compressor from 'compressorjs';
import { SocketContext } from '../../context/SocketContext';

const schema = Yup.object({
    titulo_tk: Yup.string().min(4).required(),
    cuerpo_tk: Yup.string().min(9).required(),
});

function ModalMultiCrearTicket911({show, hideModal, onGuardar,cameras,camaraId,tickerId,ticketIdOld}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [imgPreviewF1, setImgPreviewF1] = useState(null);
    const [imgPreviewF2, setImgPreviewF2] = useState(null);
    const [imgPreviewF3, setImgPreviewF3] = useState(null);
    const [imgPreviewF4, setImgPreviewF4] = useState(null);
    const { sockets } = useContext( SocketContext );

    const [tratos, setTratos] = useState( camaraId || []);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png, video/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const {handleSubmit,errors,register, setValue} = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = (data) =>{
        setIsLoading(true);
        const form = {
            ...data,
            ticketIndv: true,
            propertys: 'cvv',
            createdEmp_tk: 'CVV'
        };
        if(data.cuerpo_tk && data.cuerpo_tk.length < 8 ){
            toast.error('El ticket no contiene un titulo o descripcion!', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }else{
            form.tratos = tratos;
            if(ticketIdOld){
                form.tickerId = ticketIdOld;
            }
            if(imgPreviewF1){
                form.foto1= imgPreviewF1;
            }
            if(imgPreviewF2){
                form.foto2= imgPreviewF2;
            }
            if(imgPreviewF3){
                form.foto3= imgPreviewF3;
            }
            if(imgPreviewF4){
                form.foto4= imgPreviewF4;
            }
            TicketService.register(form).then(()=>{
                sockets?.emit( 'newTicket','1');
                sockets?.emit('tkPendiente');
                if(ticketIdOld){
                    sockets?.emit('ticketRefresh',`cvv${ticketIdOld}`);
                }
                if(onGuardar){
                    onGuardar(true);
                }
                hideModal();
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    }
    useEffect(() => {
        register('titulo_tk');
        register('cuerpo_tk');
    }, [register]);

    useEffect(() => {
        if(imgPreviewF1?.length > 0){
            const {name,size} = imgPreviewF1[0];
            if(size > 20000000){
                setImgPreviewF1(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF1[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF1(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF1])
    useEffect(() => {
        if(imgPreviewF2?.length > 0){
            const {name,size} = imgPreviewF2[0];
            if(size > 10000000){
                setImgPreviewF2(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF2[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF2(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF2])
    useEffect(() => {
        if(imgPreviewF3?.length > 0){
            const {name,size} = imgPreviewF3[0];
            if(size > 10000000){
                setImgPreviewF3(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF3[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF3(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF3])
    useEffect(() => {
        if(imgPreviewF4?.length > 0){
            const {name,size} = imgPreviewF4[0];
            if(size > 10000000){
                setImgPreviewF4(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF4[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF4(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF4])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Modal.Header closeButton>
                        <div className="col-11 ml-5 text-center">
                            <h2>{tickerId ? `Continuidad del Ticket #${tickerId} ` :'Nuevo Ticket'}</h2>
                        </div>
                        </Modal.Header>
                    </div>
                    <Modal.Body>
                        <div className="col-12 bg-white my-2 my-md-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="telefono_c">
                                            Camaras
                                        </label>
                                        <SelectTrato
                                            isMulti={cameras}
                                            camera={cameras}
                                            id="id_t"
                                            disabled={camaraId}
                                            value={tratos}
                                            isInvalid={errors.id_t}
                                            filtroEsp={[]}
                                            onChange={(opt) => setTratos(opt.map(op=>(op.value)))}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="titulo_tk">
                                            Titulo
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.titulo_tk? 'is-invalid': ''}`}
                                            id="titulo_tk"
                                            name="titulo_tk"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="cuerpo_tk">
                                            Descripcion
                                        </label>
                                        <InputEditQuill
                                            id="cuerpo_tk"
                                            template=''
                                            onChangeText={(html) => {
                                                setValue(
                                                    'cuerpo_tk',
                                                    html
                                                    );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 col-12">
                                    <div className='mb-2'>Limite permitido por archivo (20Mb)</div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-12">
                                            <div className='col-5 mb-2'>
                                                <InputDropZone
                                                    id="foto1"
                                                    accept={acceptInputFile}
                                                    defaultImage={imgPreviewF1}
                                                    backgroundImage="fileUpload"
                                                    onChange={setImgPreviewF1} 
                                                    setFoto={setImgPreviewF1}
                                                    isDeletedNow={!setImgPreviewF1}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className='col-5'>    
                                                <InputDropZone
                                                    id="foto2"
                                                    accept={acceptInputFile}
                                                    backgroundImage="fileUpload"
                                                    defaultImage={imgPreviewF2}
                                                    onChange={setImgPreviewF2}
                                                    setFoto={setImgPreviewF2}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className='col-5'>    
                                                <InputDropZone
                                                    id="foto3"
                                                    accept={acceptInputFile}
                                                    backgroundImage="fileUpload"
                                                    defaultImage={imgPreviewF3}
                                                    onChange={setImgPreviewF3}
                                                    setFoto={setImgPreviewF3}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className='col-5'>
                                                <InputDropZone
                                                    id="foto4"
                                                    accept={acceptInputFile}
                                                    backgroundImage="fileUpload"
                                                    defaultImage={imgPreviewF4}
                                                    onChange={setImgPreviewF4}
                                                    setFoto={setImgPreviewF4}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </div>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    disabled={tratos.length === 0}
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalMultiCrearTicket911;
