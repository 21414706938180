import React,{useState,useEffect, useRef} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useSelector} from 'react-redux';
import {showModal} from 'react-redux-modal-provider';
import { Doughnut,Pie,Bar, getElementAtEvent } from 'react-chartjs-2';
import { Button, MonthSingle, RangeDate, SmallBox, TablePaginated } from '@app/components/index';
import TablaTicketService from '@app/services/tablaTicket.service';
import ModalVistaDashboard from '@app/modals/ticket/ModalVistaDashboard';
import DownloadFile from '@app/services/downloadFile.service';
import FechaFunction from '@app/functions/FechaFunction';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import usePermisos from '@app/hooks/usePermisos';
import { Card } from 'react-bootstrap';

import { Chart as ChartJS,ArcElement, CategoryScale,LinearScale,BarElement, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import ModalLoadingExcel from '@app/modals/notifications/ModalLoadingExcel';
ChartJS.register(ArcElement, CategoryScale, LinearScale,BarElement, PointElement, LineElement, Tooltip, Legend);

const Dashboard = ({
    user
}) => {
    AOS.init();
    const permisos = usePermisos();
    const [ticketsTIC, setTicketsTIC] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [ticketCon, setTicketCon] = useState({});
    const tratoscam = useSelector(state => state.default.tratoscam);
    const [camarasFilter, setCamarasFilter] = useState([]);
    const [operatividadDate, setOperatividadDate] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const [operatividadInfo, setOperatividadInfo] = useState([])
    const [operatividadDateInfo, setOperatividadDateInfo] = useState([])
    const [ticketDate, setTicketDate] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const actualizar = (data) => {
        TablaTicketService.countTicketTic(data).then((c)=>{
            setTicketsTIC(c)
        })
    }
    useEffect(() => {
        const mantenimiento = ticketsTIC?.valido?.filter((tk)=>tk.contratoNubi_tk === 'MANTENIMIENTO')
        const conectividad = ticketsTIC?.valido?.filter((tk)=>tk.contratoNubi_tk === 'CONECTIVIDAD')
        setTicketCon({
            mantenimiento,
            conectividad
        })
    }, [ticketsTIC])

    const labelsGral= [`Abiertos - ${ticketsTIC?.abiertos?.length}` , `Cerrados - ${ticketsTIC?.cerrado?.length}`];
    const dataGral = {
        labels: labelsGral,
        datasets: [
            {
                label: 'Hola',
                data: [ticketsTIC?.abiertos?.length, ticketsTIC?.cerrado?.length],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };
    const labelsClose= [`Validos - ${ticketsTIC?.valido?.length}` , `Mal Cargados - ${ticketsTIC?.invalido?.length}`, `Externos - ${ticketsTIC?.externo?.length}`];
    const dataClose = {
          labels: labelsClose,
          datasets: [
            {
              label: 'Hola',
              data: [ticketsTIC?.valido?.length, ticketsTIC?.invalido?.length, ticketsTIC?.externo?.length],
              backgroundColor: [
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(161, 164, 170 , 0.2)',
              ],
              borderColor: [
                  'rgba(75, 192, 192, 1)',
                  'rgba(255, 99, 132, 1)',
                  'rgba(161, 164, 170 , 1)',
              ],
              borderWidth: 2,
            },
          ],
    };

    const labelsContratos= [`Mantenimiento - ${ticketCon?.mantenimiento?.length}` , `Conectividad - ${ticketCon?.conectividad?.length}`];
    const dataContratos = {
        labels: labelsContratos,
        datasets: [
          {
            label: 'Hola',
            data: [ticketCon?.mantenimiento?.length, ticketCon?.conectividad?.length],
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 2,
          },
        ],
    };
    const totalesDoughnutOptions = {
        responsive: true,
        legend: {
            position: 'left',
        },
        title: {
            display: true,
            text: 'Tipo de tareas programadas y registradas'
        },
        animation: {
            animateScale: true,

        },
        circumference: 180,
        rotation: -90,
    };
    const onOpenModalVerTic = (estado) =>{
        switch (estado) {
            case 'CREADOS':
                showModal(ModalVistaDashboard, {items: ticketsTIC?.creados, estado: 'CREADOS'});
                break;
            case 'ABIERTOS':
                showModal(ModalVistaDashboard, {items: ticketsTIC?.abiertos, estado: 'ABIERTOS'});
                break;
            case 'CERRADO':
                showModal(ModalVistaDashboard, {items: ticketsTIC?.cerrado, estado: 'CERRADO'});
                break;
            case 'VENCIDOS':
                showModal(ModalVistaDashboard, {items: ticketsTIC?.vencidos, estado: 'VENCIDOS'});
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const fechaIni = FechaFunction.format(ticketDate.startDate,'yyyy-MM-dd' );
        const fechaFin = FechaFunction.format(ticketDate.endDate,'yyyy-MM-dd' );
        const fechas={
            fecIni:fechaIni,
            fecFin:fechaFin,
        }
        actualizar(fechas)
    }, [ticketDate])
    /* Proyectos */
    const filtroCountss = tratoscam.filter((cam)=> cam.proyecto_t === 'SALTA SEGURA' && cam.tipoMaterial_t === 'CAMARA')
    const filtroCountvs = tratoscam.filter((cam)=> cam.proyecto_t === 'VERANO SEGURO' && cam.tipoMaterial_t === 'CAMARA')

    useEffect(() => {
        setCamarasFilter(filtroCountss)
    }, [tratoscam])

    const labelsCamxProy= [`Salta Segura - ${filtroCountss?.length || 0}` , `Verano Segura - ${filtroCountvs?.length || 0}`];
    const dataCamxProy = {
        labels: labelsCamxProy,
        datasets: [
            {
                label: 'Hola',
                data: [1210, 399],
                backgroundColor: [
                    'rgba(51, 102, 204, 0.5)',
                    'rgba(255, 153, 0, 0.5)',
                ],
                borderColor: [
                    'rgba(51, 102, 204, 1)',
                    'rgba(255, 153, 0, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };
    const chartRef = useRef(null);
    const columns = React.useMemo(
        () => [
            {
                Width: 150,
                Header: 'Nombre',
                accessor: 'nombre_t',
            },
            {
                Header: 'CVV',
                accessor: 'nombre_c',
            },
            {
                Header: 'Proyecto',
                accessor: 'proyecto_t',
            },
            {
                Header: 'Localidad',
                accessor: 'nombre_l'
            },
            {
                Header: 'Direccion',
                accessor: 'direccion_t',
            },
        ],
        []
    );

    const onClick2 = (event) => {
        const { current: chart } = chartRef;
        if (!chart) return;
        const element = getElementAtEvent(chart, event);
        if (!element.length) return;
        const { index } = element[0];
        if(index === 0){
            const filtro = tratoscam.filter((cam)=> cam.proyecto_t === 'SALTA SEGURA')
            setCamarasFilter(filtro)
        }else if(index === 1){
            const filtro = tratoscam.filter((cam)=> cam.proyecto_t === 'VERANO SEGURO')
            setCamarasFilter(filtro)
        }else{
            setCamarasFilter([])
        }
    };

    const onSearchOperativ = () =>{
        setIsLoading(true)
        TablaTicketService.operatividadCam({
            fecIni: FechaFunction.format(operatividadDate.startDate,'yyyy-MM-dd'),
            fecFin: FechaFunction.format(operatividadDate.endDate,'yyyy-MM-dd')
        }).then((resp)=>{
            setIsLoading(false)
            setOperatividadInfo(resp)
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    const solicitarReporteOperatividad = () =>{
        const form = {
            fecIni: FechaFunction.format(operatividadDate.startDate,'yyyy-MM-dd'),
            fecFin: FechaFunction.format(operatividadDate.endDate,'yyyy-MM-dd')
        }
        showModal(ModalLoadingExcel,{form: form,tipo: 'operatividad'})
    }
    const solicitarReporteTicket = () =>{
        const form = {
            fecIni: FechaFunction.format(ticketDate.startDate,'yyyy-MM-dd'),
            fecFin: FechaFunction.format(ticketDate.endDate,'yyyy-MM-dd')
        }
        showModal(ModalLoadingExcel,{form: form,tipo: 'ticket'})
    }
    const solicitarReporteTicketNubicom = () =>{
        const form = {
            fecIni: FechaFunction.format(ticketDate.startDate,'yyyy-MM-dd'),
            fecFin: FechaFunction.format(ticketDate.endDate,'yyyy-MM-dd')
        }
        showModal(ModalLoadingExcel,{form: form,tipo: 'ticketEsp'})
    }
    useEffect(() => {
        setIsLoading(true)
        const date = new Date();
        date.setDate(date.getDate() - 1);
        TablaTicketService.operatividadCam({
            fecIni: FechaFunction.format(date,'yyyy-MM-dd'),
            fecFin: FechaFunction.format(date,'yyyy-MM-dd')
        }).then((resp)=>{
            setIsLoading(false)
            setOperatividadInfo(resp)
            if( Array.isArray(resp) && resp.length > 0){
                setOperatividadDateInfo(resp[0])
            }
        }).catch(()=>{
            setIsLoading(false)
        })
    }, [])

    const onSelectDayOperativ = (dateInfo) =>{
        setOperatividadDateInfo(dateInfo)
    }
    const onDowloadExcelDay = (dateInfo) =>{
        if(dateInfo?.fecha){
            const fechaSeparar = dateInfo.fecha.split('-')
            const fecha = `${fechaSeparar[2]}-${fechaSeparar[1]}-${fechaSeparar[0]}`
            AuxiliarFunction.downloadFile(DownloadFile.historyExcel({fecha}),`operatividad-${fecha}.xlsx`)
        }
    }
    const columnsOperatividad = React.useMemo(
        () => [
            {
                Width: 150,
                Header: 'Fecha',
                accessor: 'fecha',
            },
            {
                Header: 'Operativas',
                accessor: 'totalOperativas',
            },
            {
                Header: 'No Operativas',
                accessor: 'totalNoOperativas',
            },
            {
                Header: 'S/Estanco-S/Energia',
                accessor: 'sinEnergiaSinEstanco',
            },
            {
                Header: 'Total',
                accessor: 'totalOpNoOpSEE',
            },
            {
                Header: 'SLA No_Operatividad<10%',
                accessor: 'totalSLA',
            },
            {
                Header: 'Acciones',
                disableSortBy: true,
                Filter: false,
                accessor: 'nombre_l',
                Width:120,
                Cell: ({row}) => (
                    <button
                        type="button"
                        className="btn-circle"
                        style={{background:'#CBC9C9',fontSize:'18px'}}
                        onClick={()=>onSelectDayOperativ(row.original)}
                    >
                        <i className="fas fa-eye" />
                    </button>
                )
            }
        ],
        []
    );
    /* Operatividad */
    const labelsOperatividadGral= [`Operativo`, `No Operativo`,'S/Estanco-S/Energia'];
    const dataOperatividadGral = {
        labels: labelsOperatividadGral,
        datasets: [
            {
                data: [
                    operatividadDateInfo?.operativas + operatividadDateInfo?.operativasReq || 0,
                    operatividadDateInfo.noOperarivas + operatividadDateInfo.noOperarivasTerceros + operatividadDateInfo.noOperarivasVandalismo || 0,
                    operatividadDateInfo?.sinEnergiaSinEstanco],
                backgroundColor: [
                    'rgba(0, 128, 0, 0.5)',
                    'rgba(255, 0, 0, 0.5)',
                    'rgba(15, 70, 190, 0.5)',
                ],
                borderColor: [
                    'rgba(0, 128, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(15, 70, 190, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };
    const labelsOperatividad= [`Operativo`,`Op. c/Requerimiento` , `No Operativo`, `No Op. p/Terceros`, `No Op. p/Vandalismo`, 'S/Estanco-S/Energia'];
    const dataOperatividad = {
        labels: labelsOperatividad,
        datasets: [
            {
                label: 'Interpolar',
                data: [
                    operatividadDateInfo?.operativas || 0,
                    operatividadDateInfo?.operativasReq || 0,
                    operatividadDateInfo?.noOperarivas || 0,
                    operatividadDateInfo?.noOperarivasTerceros || 0,
                    operatividadDateInfo?.noOperarivasVandalismo || 0,
                    operatividadDateInfo?.sinEnergiaSinEstanco || 0,
                ],
                backgroundColor: [
                    'rgba(0, 128, 0, 0.5)',
                    'rgba(255, 173, 0, 0.5)',
                    'rgba(255, 0, 0, 0.5)',
                    'rgba(255, 192, 203, 0.5)',
                    'rgba(109, 0, 109, 0.5)',
                    'rgba(15, 70, 190, 0.5)',
                ],
                borderColor: [
                    'rgba(0, 128, 0, 1)',
                    'rgba(255, 173, 0, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(255, 192, 203, 1)',
                    'rgba(109, 0, 109, 1)',
                    'rgba(15, 70, 190, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };
    const columnsDateInfo = React.useMemo(
        () => [
            {
                Width: 150,
                Header: 'Nombre',
                accessor: 'nombre_t',
            },
            {
                Header: 'CVV',
                accessor: 'nombre_c',
            },
            {
                Header: 'Operatividad',
                accessor: 'operatividad_hcam'
            },
            {
                Header: 'Estado',
                accessor: 'estado_hcam'
            },
            {
                Header: 'Motivo',
                accessor: 'motivo_hcam',
            },
        ],
        []
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 my-lg-12 my-12 mb-lg-3">
                    <div className="row">
                        <div className='col-lg-6 col-md-12'>
                            <h2 className="text-gray-dark mt-lg-3 user-select-none" data-aos="fade-right">Dashboard General Tickets</h2>
                        </div>
                        <div className="col-lg-2 col-md-12 mt-lg-4">
                            <h4 className="text-gray-dark user-select-none" data-aos="fade-right">Seleccione la fecha:</h4>
                        </div>
                        <div className="col-lg-2 mt-lg-4">
                            <RangeDate
                                minDate={new Date('2022-12-02')}
                                initianDate={ticketDate}
                                onChange={(e)=>setTicketDate(e)}
                                isDisabled={isLoading}
                            />
                        </div>
                        <div className="col-lg-2 mt-lg-5 col-md-12">
                            <Button
                                block
                                onClick={()=>solicitarReporteTicket()}
                                isLoading={isLoading}
                                theme='success'
                                tooltipText='El rango de fecha sera la informacion solicitada de arriba.'
                            >
                                <i className="far fa-file-excel mr-1" />
                                Solicitar Reporte Completo
                            </Button>
                            {user && user?.razonSocial_p && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                <Button
                                    block
                                    onClick={()=>solicitarReporteTicketNubicom()}
                                    isLoading={isLoading}
                                    theme='success'
                                    tooltipText='El rango de fecha sera la informacion solicitada de arriba.'
                                >
                                    <i className="far fa-file-excel mr-1" />
                                    Solicitar Reporte Especial
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-md-12">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="row ml-lg-5">
                            <div className="col-lg-3 col-md-6 mr-4 border-redondo">
                                <SmallBox
                                    count={ticketsTIC?.creados?.length || 0}
                                    title="Total de Tickets Creados"
                                    type="info"
                                    icon='fas fa-file-alt'
                                    onClick={() => onOpenModalVerTic('CREADOS')}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 mr-4 border-redondo">
                                <SmallBox
                                    count={ticketsTIC?.abiertos?.length || 0}
                                    title="Total Tickets Abiertos"
                                    type="success"
                                    icon='fas fa-users-cog'
                                    onClick={() => onOpenModalVerTic('ABIERTOS')}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 mr-4 border-redondo">
                                <SmallBox
                                    count={ticketsTIC?.cerrado?.length || 0}
                                    title="Total Tickets Cerrados"
                                    icon='fas fa-clipboard-check'
                                    onClick={() => onOpenModalVerTic('CERRADO')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="row ml-lg-5">
                            <div id='div1PDF' className="col-lg-3 col-md-12">
                                <div className="card user-select-none" style={{backgroundColor:'white'}}>
                                    <div className="card-body text-center" style={{backgroundColor:'white'}}>
                                        <b style={{fontSize: 18}}>{`Total de Tickets: ${ticketsTIC?.creados?.length} `}</b>
                                        <Pie data={dataGral} />
                                    </div>
                                </div>
                            </div>
                            <div id='div2PDF' className="col-lg-3 col-md-12">
                                <div className="card" style={{backgroundColor:'white'}}>
                                    <div className="card-body text-center user-select-none" style={{backgroundColor:'white'}}>
                                        <b style={{fontSize: 18}}>Tipo de Cierres</b>
                                        <Doughnut data={dataClose} />
                                    </div>
                                </div>
                            </div>
                            { user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                <div id='div3PDF' className="col-lg-3 col-md-12">
                                    <div className="card user-select-none" style={{backgroundColor:'white'}}>
                                        <div className="card-body text-center" style={{backgroundColor:'white'}}>
                                            <b style={{fontSize: 20}}>Contratos</b>
                                            <Doughnut
                                                data={dataContratos}
                                                options={totalesDoughnutOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-12 my-lg-12 my-12 mb-lg-3">
                <h1 className="text-gray-dark mt-lg-3 user-select-none text-center">Proyectos</h1>
            </div>
            <div className="col-lg-12 col-md-12">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="row ml-lg-3">
                            <div id='div1PDF' className="col-lg-3 col-md-12">
                                <div className="card user-select-none" style={{backgroundColor:'white'}}>
                                    <div className="card-body text-center" style={{backgroundColor:'white'}}>
                                        <b style={{fontSize: 18}}>{`Camaras por proyecto: ${filtroCountss?.length + filtroCountvs?.length || 0} `}</b>
                                        <Pie
                                            data={dataCamxProy}
                                            ref={chartRef}
                                            onClick={(e)=>onClick2(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-12">
                                <TablePaginated isShowHeader={false} columns={columns} data={camarasFilter} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="col-lg-12 my-lg-12 my-12 mb-lg-3">
                <h1 className="text-gray-dark mt-lg-3 user-select-none text-center">Operatividad</h1>
            </div>
            <div className="col-lg-12 col-md-12 mt-4" style={{marginBottom: 150}}>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="row">
                            <div className="row col-lg-5 col-md-12">
                                <div className='row col-12'>
                                    <div className="col-lg-5 text-right mt-lg-2">
                                        <h5 className="text-gray-dark user-select-none">Seleccione la fecha:</h5>
                                    </div>
                                    <div className='col-lg-5 mt-lg-4 '>
                                        <RangeDate
                                            minDate={new Date('2022-11-16')}
                                            initianDate={operatividadDate}
                                            onChange={(e)=>setOperatividadDate(e)}
                                            isDisabled={isLoading}
                                        />
                                        <div className='d-flex justify-content-center mt-1'>
                                            <Button
                                                block
                                                onClick={()=>onSearchOperativ()}
                                                isLoading={isLoading}
                                            >
                                                <i className="fas fa-search mr-1" />
                                                Buscar
                                            </Button>
                                        </div>
                                        <div className='d-flex justify-content-center mt-1'>
                                            <Button
                                                block
                                                onClick={()=>solicitarReporteOperatividad()}
                                                isLoading={isLoading}
                                                theme='success'
                                                tooltipText='El rango de fecha sera la informacion solicitada de arriba.'
                                            >
                                                <i className="far fa-file-excel mr-1" />
                                                Solicitar Reporte Completo
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-lg-7 col-md-12">
                                <div className="col-lg-12 col-md-12">
                                    <TablePaginated isShowHeader={false} columns={columnsOperatividad} data={operatividadInfo} />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 row mt-4'>
                            <div className="col-lg-4 col-md-12">
                                <div className="card user-select-none" style={{backgroundColor:'white'}}>
                                    <div className="card-body text-center" style={{backgroundColor:'white',marginBottom:27}}>
                                        <b style={{fontSize: 18}}>{`Porcentaje `}</b>
                                        <Pie
                                            data={dataOperatividadGral}
                                            onClick={(e)=>onClick2(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="card user-select-none" style={{backgroundColor:'white'}}>
                                    <div className="card-body text-center" style={{backgroundColor:'white'}}>
                                        <b style={{fontSize: 18}}>{operatividadDateInfo?.fecha || ''}</b>
                                        <Bar data={dataOperatividad} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <TablePaginated isShowHeader={false} columns={columnsDateInfo} data={operatividadDateInfo?.camaras || []} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.currentUser
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
