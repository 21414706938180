import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import MotivoTicketService from '@app/services/motivoTicket.service';

const Styles = styled.div``;

const SelectMotivoTicket = ({id, value, onChange, isInvalid, isClearable,isDisabled,type,emp}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const motivoTk = useSelector(state => state.default.motivoTk);
    const motivoTkCvv = useSelector(state => state.default.motivoTkCvv);

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    useEffect(() => {
        if(type === 'cvv'){
            setItems(motivoTkCvv)
        }else{
            const filtro = motivoTk.filter((mt)=> mt.createdEmp_mt === emp )
            setItems(filtro)
        }
    }, [motivoTk,motivoTkCvv])

    useEffect(() => {
        const newOptions = items.map((item) => {
            return {
                value: item.id_mt,
                label: item.motivo_mt,
                descripcion: item.cuerpo_mt,
                destino: item.grupoId_mt,
                tipoTicket: item.tipoTicketId_mt,
                subTipoTicket: item.subTipoTicketId_mt,
                nivelAdm: item.nivelAdmTkId_mt,
                motivoBaja: item.motivoBajaTk_mt,
                dayResolucion: item.dayResolucion_mt
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.motivo_mt,value:va.id_mt})));
        }
    }, [items]);
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                isDisabled={isDisabled}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectMotivoTicket.defaultProps = {
    isClearable: false
};

export default SelectMotivoTicket;
