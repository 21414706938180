import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ProductosService from '@app/services/productos.service';

const Styles = styled.div``;

const SelectProductos = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    stack,
    ...defaultProps
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_pr === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_pr === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };
    useEffect(() => {
        ProductosService.getAll().then((response) => {
            setItems(response);
        });
    }, []);
    useEffect(() => {
        const newOptions = items
            .map((item) => {
            return {
                value: item.id_pr,
                label: item.nombre_pr,
                importe: item.importe_pr,
                modelo: item.modelo_pr,
                marca: item.marca_pr,
            };
        });
        setOptions(newOptions);
        if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = items.find(it=>it.id_pr === va);
                if(item){
                    return {
                        label:item.nombre_pr,
                        value:item.id_pr,
                    };  
                }
                return {
                    label:va.nombre_pr,
                    value:va.id_pr,
                };
                
            }))
        }
    }, [items]);

    const conditionalProps = {}
    if(stack && stack.length === 0){
        conditionalProps.menuPortalTarget = document.body;
    }
    return (
        <Styles>
            <Select
                {...defaultProps}
                isDisabled={disabled}
                inputId={id}
                name={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                noOptionsMessage={()=>"Sin opciones"}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectProductos.defaultProps = {
    isClearable: false,
    onChange: () => {},
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(SelectProductos)

