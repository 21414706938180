import React,{ useEffect, useRef, useState }  from 'react';
import {Popover} from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    font-size: small;
    display: flex;
`;

function EficienciaCostoEstadoBadge({
    item,
    periodo,
}){
    /**
     * BUENO - MALO - REGULAR - NO SOLUCION
     */
    const [estado, setEstado] = useState(null); 
    const [incremento, setIncremento] = useState(0);
    const [costoMinimo, setCostoMinimo] = useState(0);
    const [costoPromedio, setCostoPromedio] = useState(0);
    const [eficienciaEstado, setEficienciaEstado] = useState('');
    const [esSolucionCompleta, setEsSolucionCompleta] = useState(false);
    const [destinos, setDestinos] = useState(0);
    const targetRef = useRef();

    const overlay = () => {
        
        return (
            <Popover id={item.id}>
                <Popover.Title as="h3">{`Eficiencia ${costoMinimo}`}</Popover.Title>
                <Popover.Content>
                    {`Incremento: ${incremento}`}
                    <br />
                    {`Eficiencia: ${eficienciaEstado}`}
                    <br />
                    {`Promedio: ${costoPromedio}`}
                </Popover.Content>
            </Popover>
        );
    }

    useEffect(() => {
        const {
            costoMinimoTMPromedio,
            costoMinimoTTPromedio,
            tareasTM,
            tareasTT,
            eficienciaCostoEstadoTM,
            eficienciaCostoEstadoTT,
            costoMinimoTM,
            costoMinimoTT,
            incrementoCostoTM,
            incrementoCostoTT,
            esSolucionCompletaTM,
            esSolucionCompletaTT,
        } = item;
        if(periodo==='TM'){
            if(costoMinimoTM === null && tareasTM.length >1){
                setEstado(null);
            } else if(tareasTM.length === 0){
                setEstado('REGULAR');
            } else {
                setEstado(eficienciaCostoEstadoTM);
                setIncremento(incrementoCostoTM);
            }
            setCostoPromedio(costoMinimoTMPromedio);
            setCostoMinimo(costoMinimoTM);
            setEficienciaEstado(eficienciaCostoEstadoTM);
            setEsSolucionCompleta(esSolucionCompletaTM);
            setDestinos(item.tareasTM.length);
        } else if(periodo==='TT') {
            if(costoMinimoTT === null && tareasTT.length >1){
                setEstado(null);
            } else if(tareasTT.length === 0){
                setEstado('REGULAR');
            } else {
                setEstado(eficienciaCostoEstadoTT);
                setIncremento(incrementoCostoTT);
            }
            setCostoPromedio(costoMinimoTTPromedio);
            setCostoMinimo(costoMinimoTT);
            setEficienciaEstado(eficienciaCostoEstadoTT);
            setEsSolucionCompleta(esSolucionCompletaTT);
            setDestinos(item.tareasTT.length);
        }
        return () => {
            
        }
    }, [item]);
    return (
        <Styles ref={targetRef}>
            {periodo}
            &nbsp;
            {/** 
            <OverlayTrigger
                trigger="click"
                overlay={overlay}
                placement="auto-start"
                target={targetRef.current}
            >
            */}
                <div className="">
                {
                    estado === 'BUENO' && (
                        <div className="badge badge-success">{`UP ${incremento}${destinos>1?'%':'km'}`}</div>
                    )
                }
                {
                    estado === 'MALO' && (
                        <div className={`badge ${esSolucionCompleta?'badge-warning':'badge-danger'}`}>{`UP ${incremento}${destinos>1 && esSolucionCompleta?'%':'km'}`}</div>
                    )
                }
                {
                    estado === 'REGULAR' && (
                        <b>OK</b>
                    )
                }
                {
                    estado === null && (
                        <b>NO SOL</b>
                    )
                }
                </div>
            {/** 
            </OverlayTrigger>
            */}
        </Styles>
    )
}

export default EficienciaCostoEstadoBadge;