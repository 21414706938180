import React, {useState, useEffect} from 'react';
import { Tabs, Tab,Modal} from 'react-bootstrap';
import {Button, LogsCard, LogsTicketOld, LogsTimeCard} from '@app/components/index';
import * as Yup from 'yup';
import LogsTicketsService from '@app/services/logsTickets.service';
import TicketService from '@app/services/tickets.service';

const schema = Yup.object({
});

function ModalTicketIdLogs({show, hideModal, ticketId, ticketIdOld, type}) {
    const handleClose = () => hideModal();
    const [logs, setLogs] = useState([]);
    const [userTicket, setUserTicket] = useState([]);
    const [logsRecorrido, setlogsRecorrido] = useState([]);
    const [key, setKey] = useState('tickets');

    const actualizar = () =>{
        LogsTicketsService.getAll({tickets: ticketId, type}).then((response) => {
            setLogs(response);
        })
        TicketService.getAllUxT({id: ticketId, type}).then((resp) => {
            setUserTicket(resp)
        })
        if(ticketIdOld){
            LogsTicketsService.getAll({logs: ticketIdOld,type}).then((data)=>{
                setlogsRecorrido(data)
            })
        }
    }
    useEffect(() => {
        actualizar()
    }, []);
    
    return (        
                <Modal show={show} backdrop="static" onHide={handleClose} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`Historial del Ticket #${ticketId}`} 
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Tabs
                        id="contactosTab"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="tickets" title="Tickets" tabClassName="card-body">
                        {logs &&
                            logs?.map((lg)=>{
                                return <LogsCard item={lg} keyst={lg.id_lt} type={type} />
                            })
                        }
                        </Tab>
                            <Tab eventKey="tiempo" title="Tiempo" tabClassName="card-body">
                            {userTicket &&
                                userTicket?.map((ulg)=>{
                                    return <LogsTimeCard item={ulg} keys={ulg.id_uxt} />
                                })
                            }
                            </Tab>
                            <Tab eventKey="historial" title="Historial" tabClassName="card-body">
                                {logsRecorrido && logsRecorrido.length > 0 &&
                                    logsRecorrido?.map((lg)=>{
                                        return <LogsTicketOld item={lg} key={lg.id_tk} type={type} handleClose={handleClose} />
                                    })
                                }
                            </Tab>
                    </Tabs>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button theme="default" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>


                </Modal>

    );
}

export default ModalTicketIdLogs;
