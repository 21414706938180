import React from 'react'
import { Fab } from 'react-tiny-fab';
import { TablePaginated } from '@app/components/index';
import 'react-tiny-fab/dist/styles.css';
import {useNavigate} from 'react-router-dom';
import {showModal} from 'react-redux-modal-provider';
import ModalTicketTrato from '@app/modals/trato/ModalTicketTrato';

function TabContactoTratos({
    tratos
}) {
    const navigate = useNavigate();
    const onLinkClick = () => {
        return document.getElementById("scrollTop").scrollIntoView();
    }
    const onOpenModal = (trato) => {
        showModal(ModalTicketTrato, {item: trato, history:navigate});
    };
    const onOpenVer = (tra) => {
        if(tra?.isCamera_t){
            navigate(`/tratos/${tra.id_t}/vercam`);
        }else{
            navigate(`/trato/${tra.id_t}/ver`);
        }
    }
    const columns = React.useMemo(
        () => [
            {
                Width: 200,
                Header: 'Nro',
                accessor: 'nombre_t',
            },{
                Header: 'Direccion',
                accessor: 'direccion_t',
            },
            {
                Width: 200,
                Header: '',
                accessor: 'id',
                disableSortBy: false,
                Filter: false,
                Cell: ({row}) => (
                    <>
                        <button 
                            type="button"
                            className="btn-circle"
                            style={{fontSize:'18px',color:'#2d6238',backgroundColor: '#d3d3d3d3'}}
                            onClick={()=>onOpenModal(row.original)}
                        >
                            <i className="fas fa-inbox" />
                        </button>
                        <button 
                            type="button"
                            className="btn-circle ml-2"
                            style={{fontSize:'16px',color:'white',borderWidth: 2,backgroundColor: '#d55353'}}
                            onClick={()=>onOpenVer(row.original)}
                        >
                            <i className="fas fa-arrow-right" />
                        </button>
                    </>
                )
            }
        ],
        []
    );
    return (
        <div className="p-3 col-12">
            <TablePaginated columns={columns} data={tratos} />
        </div>
    )
}

export default TabContactoTratos
