import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import TabsTicketCreate from '@app/pages/tickets/tabsTickets/TabsTicketCreate';
import TabsTicketGrupal from '@app/pages/tickets/tabsTickets/TabsTicketGrupal';
import TabsTicketIndividually from '@app/pages/tickets/tabsTickets/TabsTicketIndividually';
import { Button } from '@app/components/index';
import { showModal } from 'react-redux-modal-provider';
import ModalMultiCrearTicket911 from '@app/modals/ticket/ModalMultiCrearTicket911';
import TabsTicketPendiente from '@app/pages/tickets/tabsTickets/TabsTicketPendiente';
import TabsTicketSituacionExterna from '@app/pages/tickets/tabsTickets/TabsTicketSituacionExterna';
import usePermisos from '@app/hooks/usePermisos';
import TablaTicketService from '@app/services/tablaTicket.service';
import ModalEspNubicomCreated from '@app/modals/ticket/ModalEspNubicomCreated';

function TicketsPage({
    user
}) {
    const permisos = usePermisos();
    const [key, setKey] = useState('individual');
    const [filtro, setFiltro] = useState("TODOS");
    const [acceso, setAcceso] = useState(false);
    const [tkCreated, setTkCreated] = useState([])
    const [tkCreatedPage, setTkCreatedPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false);

    const actualizarCreated = (modal) =>{
        setIsLoading(true)
        TablaTicketService.getAll({
            consulta: 'creados',
            cvv: true,
            page: tkCreatedPage
        })
        .then((response)=>{
            setIsLoading(false)
            if(!modal){
                setTkCreated((prev) => prev.concat(response.content));
                setHasMore((tkCreatedPage+1) < response.totalPages);
            }else{
                setTkCreated(response.content);
                setTkCreatedPage(0)
                setHasMore(true)
            }
        })
        .catch(()=>{
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if(tkCreatedPage > 0){
            actualizarCreated();
        }else{
            actualizarCreated(true);
        }
    }, [tkCreatedPage])

    const onClickTicketNuevo911 = () => {
        showModal(ModalEspNubicomCreated, { onGuardar: actualizarCreated, cameras: true });
    }

    useEffect(() => {
        if (user && !user.canalpriv_p) {
            setAcceso(false)
            setKey('grupo')
        }else if(user && user.canalpriv_p){
            setAcceso(true)
        }
    }, [user])
    return (
        <div className="container-fluid p-1">
            <div className="row">
                <div className="col-md-12 col-lg-10 my-lg-3 align-self-center user-select-none">
                    <h2> Tablero de Tickets </h2>
                </div>
                {user && permisos['ticket.registerNubiEsp'] &&(
                    <>
                        <div className="col-6 col-lg-2 align-self-center">
                            <Button className="btn-block" onClick={onClickTicketNuevo911}>
                                Crear Ticket
                            </Button>
                        </div>
                    </>
                )}
            </div>
                { user && user?.razonSocial_p === 'Policia de la Provincia de Salta' ?(
                <div className="row">
                    <div className="align-self-center col-1 text-right col-8 col-lg-10">
                        <label>Filtro</label>
                    </div>
                    <div className="col-4 col-md-2 align-self-center">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => (setFiltro(e.target.value))}>
                                <option value="TODOS" defaultValue>Todos</option>
                                <option value="ABIERTO">Abiertos</option>
                                <option value="CERRADO">Cerrados</option>
                            </select>
                        </div>
                    </div>
                </div>
            ):(
                <div className="row">
                    <div className="align-self-center col-1 text-right col-8 col-lg-10">
                        <label>Filtro</label>
                    </div>
                    <div className="col-4 col-md-2 align-self-center">
                        <div className="form-group">
                            <select className="form-control" onChange={(e) => (setFiltro(e.target.value))}>
                                <option value="TODOS" defaultValue>Todos</option>
                                <option value="ABIERTO">Abiertos</option>
                                <option value="PORVENCER">Por vencer</option>
                                <option value="VENCEHOY">Vencen Hoy</option>
                                <option value="VENCIDO">Vencidos</option>
                                <option value="CERRADO">Cerrados</option>
                            </select>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-12">
                    <Tabs
                        id="TicketsTab"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        {acceso && (
                            <Tab eventKey="individual" title="Personal" tabClassName="card-body">
                                <TabsTicketIndividually filtro={filtro} />
                            </Tab>
                        )}
                        <Tab eventKey="grupo" title="Grupal" tabClassName="card-body">
                            <TabsTicketGrupal filtro={filtro} />
                        </Tab>
                        {permisos['ticket.update911'] && (
                            <Tab eventKey="pendiente" title="Pendientes" tabClassName="card-body">
                                <TabsTicketPendiente />
                            </Tab>
                        )}
                        
                        {(permisos['trato.getAll911']) && (
                            <Tab eventKey="situacionExterna" title="Situacion Externa" tabClassName="card-body">
                                <TabsTicketSituacionExterna />
                            </Tab>
                        )}
                        {!acceso && (
                            <Tab eventKey="creados" title="Creados" tabClassName="card-body">
                                <TabsTicketCreate filtro={filtro} items={tkCreated} setTkCreatedPage={setTkCreatedPage} hasMore={hasMore} isLoading={isLoading} />
                            </Tab>
                        )}
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});


export default connect(mapStateToProps, null)(TicketsPage);