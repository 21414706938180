import React,{useEffect, useState} from 'react';
import { MapClickCamera, SelectContacto, SelectLugares, SelectTipoCam, SelectTipoTegnologiaCam } from '@app/components/index'
import { Controller } from "react-hook-form";

function FormTratosCam({
    isModal,
    control,
    errors,
    register,
    setValue,
    item,
    watch,
    detalle
}) {
    const [cvvId, setCvvId] = useState(item?.contactoId_t || 0);
    const [tipoCam, setTipoCam] = useState();
    const [tipoTecCam, setTipoTecCam] = useState();
    let lugarId = watch('lugarId_t',item?.lugarId_t)
    const idContxEmp = watch('contactoId_t',item?.contactoId_t);

    const [coordenadas, setCoordenadas] = useState({
        lat: -24.789090,
        lng: -65.410255
    });


    useEffect(() => {
        setValue('lugarId_t',null)
        lugarId = null;
    }, [cvvId])

    useEffect(() => {
        if(item && item.id_t > 0){
            setValue('nombre_t',item.nombre_t);
            setValue('estadoCamara_t',item.estadoCamara_t);
            setValue('direccion_t',item.direccion_t);
            setValue('latitud_t',`${item?.latitud_t},${item.longitud_t}`);
            setValue('longitud_t',item.longitud_t);
            setValue('contactoId_t',item.contactoId_t);
            setValue('caosCamara_t',item.caosCamara_t);
            setValue('caosConectividad_t',item.caosConectividad_t);
            setValue('proyecto_t',item.proyecto_t);
            setCoordenadas({
                lat:Number(item?.latitud_t),
                lng: Number(item?.longitud_t)
            })
        }
    }, [])

    useEffect(() => {
        if(detalle){
            setValue('objetivo_dcam',detalle?.objetivo_dcam);
            setValue('tipoCam_dcam',detalle?.tipoCam_dcam);
            setValue('tecnologiaCam_dcam',detalle?.tecnologiaCam_dcam);
            setValue('poste_dcam',detalle?.poste_dcam);
            setValue('soporte_dcam',detalle?.soporte_dcam);            
            setValue('panelNodo_dcam',detalle?.panelNodo_dcam);
            setValue('sidewalk_dcam',detalle?.sidewalk_dcam);
            setValue('jurisdiccion_dcam',detalle?.jurisdiccion_dcam);
            setValue('id_dcam',detalle?.id_dcam);
            setValue('microSD_dcam',detalle?.microSD_dcam);
            setValue('cajaEstancoNubicom_dcam',detalle?.cajaEstancoNubicom_dcam);
            setValue('soporteNubicom_dcam',detalle?.soporteNubicom_dcam);
            setTipoCam(detalle?.tipoCam_dcam)
            setTipoTecCam(detalle?.tecnologiaCam_dcam)
        }else{
            setValue('objetivo_dcam',item?.objetivo_dcam);
            setValue('tipoCam_dcam',item?.tipoCam_dcam);
            setValue('tecnologiaCam_dcam',item?.tecnologiaCam_dcam);
            setValue('poste_dcam',item?.poste_dcam);
            setValue('soporte_dcam',item?.soporte_dcam);            
            setValue('panelNodo_dcam',item?.panelNodo_dcam);
            setValue('sidewalk_dcam',item?.sidewalk_dcam);
            setValue('jurisdiccion_dcam',item?.jurisdiccion_dcam);
            setValue('id_dcam',item?.id_dcam);
            setValue('microSD_dcam',item?.microSD_dcam);
            setValue('cajaEstancoNubicom_dcam',item?.cajaEstancoNubicom_dcam);
            setValue('soporteNubicom_dcam',item?.soporteNubicom_dcam);
            setTipoCam(item?.tipoCam_dcam)
            setTipoTecCam(item?.tecnologiaCam_dcam)
        }
    }, [detalle])
    return (
        <>
            <div className="col-12 bg-white my-2 my-md-0 mt-lg-3">
                <div className="row">
                    <div className={`col-12 ${isModal?'col-md-12':'col-md-4'} text-md-left text-gray mb-3`}>
                        <h5>
                            <i className="fas fa-angle-down mr-2"></i>
                            Datos de la Camara
                        </h5>
                    </div>
                    <div className={`col-12 ${isModal?'col-md-12':'offset-md-1 col-md-7'}`}>
                        <div className={`row rounded ${isModal?'':'border-md border shadow-sm'} bg-white  p-2`}>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="contactoId_t">
                                        CVV
                                    </label>
                                    <Controller
                                        name="contactoId_t"
                                        control={control}
                                        render={({onChange,value},{invalid}) => (
                                            <SelectContacto
                                                camera
                                                id="contactoId_t"
                                                name="contactoId_t"
                                                value={idContxEmp}
                                                disabled
                                                onChange={(opt)=>{
                                                    onChange(opt.value)
                                                    setCvvId(opt.value)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.contactoId_t ? (
                                        <div className="default-invalid-feedback">
                                            El CVV es requerido
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombre_t">
                                        Nombre
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.nombre_t? 'is-invalid': ''}`}
                                        id="nombre_t"
                                        name="nombre_t"
                                        autoComplete="off"
                                        disabled
                                        ref={register}
                                    />
                                    {errors.nombre_t && (
                                        <div className="invalid-feedback">
                                            El nombre es requerido
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="lugarId_t">
                                        Localidad
                                    </label>
                                        <SelectLugares
                                            id="lugarId_t"
                                            cvv={cvvId}
                                            disabled
                                            name="lugarId_t"
                                            value={lugarId}
                                            onChange={(opt)=>{
                                                setValue('lugarId_t',opt.value)
                                            }}
                                        />
                                        {errors.lugarId_t ? (
                                        <div className="default-invalid-feedback">
                                            Lugar vacio / Lugar no pertenece al CVV
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="direccion_t">
                                        Direccion
                                    </label>
                                    <input
                                        className={`form-control ${errors.direccion_t? 'is-invalid': ''}`}
                                        id="direccion_t"
                                        disabled
                                        name="direccion_t"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.direccion_t && (
                                        <div className="invalid-feedback">
                                            {errors.direccion_t.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="tipoCam_dcam">
                                        Tipo Camara
                                    </label>
                                    <SelectTipoCam
                                        id="tipoCam_dcam"
                                        name="tipoCam_dcam"
                                        disabled
                                        value={tipoCam}
                                        onChange={(opt)=>{
                                            setValue('tipoCam_dcam',opt.value)
                                            setTipoCam(opt.value)
                                        }}
                                    />
                                    {errors.tipoCam_dcam && (
                                        <div className="invalid-feedback">
                                            {errors.tipoCam_dcam.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="tecnologiaCam_dcam">
                                        Tecnologia
                                    </label>
                                    <SelectTipoTegnologiaCam
                                        tipoCam={tipoCam}
                                        id="tecnologiaCam_dcam"
                                        name="tecnologiaCam_dcam"
                                        disabled
                                        value={tipoTecCam}
                                        onChange={(opt)=>{
                                            setValue('tecnologiaCam_dcam',opt.value)
                                        }}
                                    />
                                    {errors.tecnologiaCam_dcam && (
                                        <div className="invalid-feedback">
                                            {errors.tecnologiaCam_dcam.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="latitud_t">
                                        Coordenadas
                                    </label>
                                    <input
                                        className={`form-control ${errors.latitud_t? 'is-invalid': ''}`}
                                        id="latitud_t"
                                        disabled
                                        name="latitud_t"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="proyecto_t">
                                        Proyecto
                                    </label>
                                    <input
                                        className={`form-control ${errors.proyecto_t? 'is-invalid': ''}`}
                                        id="proyecto_t"
                                        disabled
                                        name="proyecto_t"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="estadoCamara_t">
                                        Estado Camara
                                    </label>
                                    <input
                                        className={`form-control ${errors.estadoCamara_t? 'is-invalid': ''}`}
                                        id="estadoCamara_t"
                                        disabled
                                        name="estadoCamara_t"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12">
                                <div className="form-group">
                                <MapClickCamera
                                    coordinates={coordenadas}
                                    options={{
                                        maxZoom: 21,
                                        minZoom: 14,
                                    }}
                                    onChange={(e)=>{
                                        setCoordenadas(e);
                                        setValue('latitud_t',e.lat);
                                        setValue('longitud_t',e.lng);
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

FormTratosCam.defaultProps = {
    isModal: false,
    hideTareaForm: false,
};

export default FormTratosCam;
