import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { v4 as uuidv4 } from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const icons = {
    facebook: 'fab fa-facebook',
    google: 'fab fa-google',
    googlePlus: 'fab fa-google-plus'
};

const AppButton = ({
    tooltipText,
    tooltipTheme,
    tooltipPlace,
    tooltipbackground,
    children,
    isLoading,
    icon,
    theme = 'primary',
    disabled,
    onClick,
    ...otherProps
}) => {
    let spinnerTemplate;
    let iconTemplate;
    const idBtn = uuidv4();
    if (isLoading) {
        spinnerTemplate = (
            <Spinner
                className="ml-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        );
    }
    if (Array.isArray(icon)){
        iconTemplate = <FontAwesomeIcon className="mr-2" icon={icon} />;
    } else if (icon && icons[icon]) {
        iconTemplate = <i className={`${icons[icon]} ${children?'mr-2':''}`} />;
    } else if (typeof icon === 'string') {
        iconTemplate = <i className={`${icon} ${children?'mr-2':''}`} />;
    }

    return (
        <>
            {
                tooltipText !== null ? (
                    <>
                        <Button
                            {...otherProps}
                            onClick={onClick}
                            variant={theme}
                            disabled={isLoading || disabled}
                            data-tip
                            data-for={idBtn}
                        >
                            {
                                !isLoading && (
                                    <>{iconTemplate}</>
                                )
                            }
                            {children}
                            {spinnerTemplate}
                        </Button>
                        <ReactTooltip id={idBtn} place={tooltipPlace} type={tooltipTheme} effect="float" backgroundColor={tooltipbackground}>
                            <span>{tooltipText}</span>
                        </ReactTooltip>
                    </>
                ):(
                    <Button
                        {...otherProps}
                        onClick={onClick}
                        variant={theme}
                        disabled={isLoading || disabled}
                    >
                        {
                            !isLoading && (
                                <>{iconTemplate}</>
                            )
                        }
                        {children}
                        {spinnerTemplate}
                    </Button>
                )
            }
        </>
    );
};

AppButton.defaultProps = {
    tooltipText: null,
    tooltipTheme: null,
    tooltipPlace: 'right',
    tooltipbackground:null,
    size: 'sm',
    onClick: () => {},
};

export default AppButton;
