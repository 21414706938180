import React from 'react';
import {Navigate} from 'react-router-dom';
import {connect} from 'react-redux';

const PublicRoute = ({children, isLoggedIn}) => {
    return isLoggedIn
        ? <Navigate to="/loading" />
        : children
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PublicRoute);
