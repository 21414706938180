import axios from 'axios';
const base = 'comentarios';

function register(data) {
    const form = new FormData();
    form.append('ticketId_com',data.ticketId_com);
    form.append('cuerpo_com',data.cuerpo_com);
    if(data.isVisible_com || data.isVisible_com === false){
        form.append('isVisible_com',data.isVisible_com);
    }
    if(data.propertys){
        form.append('propertys',data.propertys);
    }
    if(data?.foto){
        if(Array.isArray(data.foto) && data.foto.length>0){
            form.append('file0',data.foto[0]);
        } else {
            form.append('file0',data.foto);
        }
    }
    if(data?.foto2){
        if(Array.isArray(data.foto2) && data.foto2.length>0){
            form.append('file1',data.foto2[0]);
        } else {
            form.append('file1',data.foto2);
        }
    }
    if(data?.foto3){
        if(Array.isArray(data.foto3) && data.foto3.length>0){
            form.append('file2',data.foto3[0]);
        } else {
            form.append('file2',data.foto3);
        }
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}
function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

const comentarioTicketService = {
    register: register,
    update: update,
};
export default comentarioTicketService;
