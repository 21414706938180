import React, {useEffect, useState ,useContext} from 'react';
import { Modal} from 'react-bootstrap';
import Compressor from 'compressorjs';
import {toast} from 'react-toastify';
import { InputDropZone, InputEditQuill, Button } from '@app/components/index';
import TicketService from '@app/services/tickets.service';
import { SocketContext } from '../../context/SocketContext';


function ModalTicketInvalido({show, hideModal, ticketId, onGuardar,hide}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [foto, setFoto] = useState(null);
    const [foto2, setFoto2] = useState(null);
    const [foto3, setFoto3] = useState(null);
    const comentarioDefault = 'Casos menores de escasa incidencia en el funcionamiento del sistema, que no se han repetido en el tiempo: micro cortes únicos, de un tiempo muy corto, que pueden haberse producido por la baja de tensión de alimentación, cortes eléctricos o punto afectado al reinicio de un servidor o al mantenimiento preventivo/correctivo. <strong> Se cierra el ticket.</strong>';
    const [comentario, setComentario] = useState(comentarioDefault);
    const { sockets } = useContext( SocketContext );
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png, video/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'


    const onSubmit = () =>{
        if(comentario && comentario.length > 8){
            const form={
                cuerpo_com: comentario,
                id_tk: ticketId
            }
            if(foto){
                form.foto = foto;
            }
            if(foto2){
                form.foto2 = foto2;
            }
            if(foto3){
                form.foto3 = foto3;
            }
            TicketService.ticketInvalido(form).then(()=>{
                onGuardar();
                sockets?.emit( 'ticketRefresh', `cvv${ticketId}` );
                hideModal();
                hide();
            })
        }else{
            toast.error('Falta el comentario', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
    }

    useEffect(() => {
        if(foto?.length > 0){
            const {name,size} = foto[0];
            if(size > 20000000){
                setFoto(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto(result);
                        }
                    })
                }
            }
        }
    }, [foto])

    useEffect(() => {
        if(foto2?.length > 0){
            const {name,size} = foto2[0];
            if(size > 20000000){
                setFoto2(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto2[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto2(result);
                        }
                    })
                }
            }
        }
    }, [foto2])
    useEffect(() => {
        if(foto3?.length > 0){
            const {name,size} = foto3[0];
            if(size > 20000000){
                setFoto3(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto3[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto3(result);
                        }
                    })
                }
            }
        }
    }, [foto3])
    return (        
        <Modal show={show} backdrop="static" onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Ticket Invalido / Mal Cargado: #${ticketId}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='col-12'>
                <span style={{fontWeight:'bold', textAlign:'center'}}>Comentario </span>
                ( El comentario sera visible )
                <InputEditQuill
                    id="Comentario"
                    template={comentarioDefault}
                    onChangeText={(html)=>{
                        setComentario(html)
                    }}
                />
            </div>
            <p className="col-12">Limite permitido por archivo (20Mb)</p>
            <div className="attachments d-flex ml-3 mb-3">
                <div className="video-holder">
                    <InputDropZone
                        id="fotos1"
                        accept={acceptInputFile}
                        defaultImage={foto}
                        backgroundImage="fileUpload"
                        onChange={setFoto} 
                        setFoto={setFoto}
                    />
                </div>
                <div className="image-holder ml-3">
                    <InputDropZone
                        id="fotos2"
                        accept={acceptInputFile}
                        defaultImage={foto2}
                        backgroundImage="fileUpload"
                        onChange={setFoto2} 
                        setFoto={setFoto2}
                    />
                </div>
                <div className="image-holder ml-3">
                    <InputDropZone
                        id="fotos3"
                        accept={acceptInputFile}
                        defaultImage={foto3}
                        backgroundImage="fileUpload"
                        onChange={setFoto3} 
                        setFoto={setFoto3}
                    />
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" size='xl' onClick={handleClose}>
                    Cerrar
                </Button>
                <Button
                    size='xl'
                    theme="primary"
                    onClick={onSubmit}
                    isLoading={isLoading}
                >
                    Cerrar Ticket
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalTicketInvalido;
