import {combineReducers} from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {reducer as modalProvider} from 'react-redux-modal-provider';
import defaultSlice from '@app/store/slices/default.slice';
import modalSlice from '@app/store/slices/modal.slice';
import recibosSlice from '@app/store/slices/recibos.slice';
import authReducer from './reducers/auth';
import mapasReducer from './reducers/mapas';
import contactosReducer from './reducers/contactos';
import ticketReducer from './reducers/ticket';

const rootReducer = combineReducers({
    auth: authReducer,
    mapas: mapasReducer,
    contactos: contactosReducer,
    recibos: recibosSlice,
    default: defaultSlice,
    modal: modalSlice,
    ticketrefresh: ticketReducer,
    modalProvider
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}),
});

export default store;
