import React from 'react'
import styled from 'styled-components'

const Styles = styled.div`

ul {
    justify-content: center;
    align-items: center;
}

ul button {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin: 5px 1px 0 0;
    text-indent: 17px;
    position: relative;
    margin-left: 10px;
}

ul button:before {
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    left: -2px;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent #fff;
    z-index: 0;
}

ul button.flecha:after {
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    right: -15px;
    border-style: solid;
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent #ccc;
    z-index: 10;
    top: 0;
}

ul button.active.flecha {
    background: orange;
}

ul button.active.flecha:after {
    border-left-color: orange;
}
ul button {
    background: #ccc;
    border: none
}
button.active.flecha {
    margin-left: 10px
}
button.flecha {
    margin-left: 10px;
    padding-left: 7px;
    padding-right: 10px;
}
`;

const StageArrow = ({seleccionado}) => {
    const items = [{
        titulo: 'Abierto'
    },{
        titulo: 'Fecha Asignada'
    },{
        titulo: 'Reprogramada'
    },{
        titulo: 'Cerrado'
    }]
    return (
        <Styles>
                <ul>
                    { items &&(
                        items.map((it)=>(
                            <button
                                className={seleccionado === it.titulo ? 'flecha active' : 'flecha'}
                                type='button'
                            >
                                    <div>{it.titulo}</div>
                            </button>
                        ))
                    )}
                </ul>
        </Styles>
    )
}
export default StageArrow
