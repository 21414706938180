import React from 'react'
import {showModal} from 'react-redux-modal-provider';
import { Button } from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import { Card } from 'react-bootstrap';
import ModalPreTicketPreview from '@app/modals/preTicket/ModalPreTicketPreview';

function PreTicketVistaCard({
    item,
    onGuardar
}) {
    const onVerTicket = () => {
        showModal(ModalPreTicketPreview, {item: item, onGuardar: onGuardar});
    };

    return (
        <Card style={{backgroundColor:'#F8F8F8'}}>
            <div className='ml-3 mt-2 pill pillPENDIENTE'><span>PENDIENTE</span></div>
            <div className='col-lg-12 col-md-12'>
                <div className='row'>
                    <div className='col-1 mt-2 ml-3'>
                        <img src="/img/911-logo.png" alt="login" className="iconoEmpTicketCard"></img>
                    </div>
                    <div className='col-lg-10 col-md-12'>
                        <Card.Body>
                            <div className="row">
                                <div className="col-10">
                                    <h6 style={{color:'#345380'}}>
                                        {`Pre-Ticket # ${item?.id_ptk} - ${item?.titulo_ptk}`}
                                    </h6>
                                    <div className="row">
                                        <footer className="blockquote-footer ml-2">
                                            {`Incidencia Nro ${item?.idIncidencia_ptk} - ${FechaFunction.format(new Date(item?.fechaCreated_ptk),'dd/MM/yyyy HH:mm:ss')}`}
                                        </footer>
                                    </div>
                                </div>
                                <div className="col-lg-2 mt-3 col-md-12">
                                    <Button
                                        className="btn btn-block"
                                        style={{fontSize:"0.8em"}}
                                        onClick={onVerTicket}
                                    >
                                        Ver Ticket
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default PreTicketVistaCard;