import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Styles = styled.div``;

const SelectSubTipoTicket = ({id, value, onChange, isInvalid, isClearable,tipoTicket,isDisabled}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const subTipoTk = useSelector(state => state.default.subTipoTk);

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        setItems(subTipoTk);
    }, [subTipoTk]);

    useEffect(() => {
        let filtros =items;
        if(tipoTicket ){
            filtros = items.filter((it)=> it.tipoTicketId_sttk === tipoTicket )
        }
        const newOptions = filtros.map((item) => {
            return {
                value: item.id_sttk,
                label: item.nombre_sttk,
                resolucion: item.resolucion_sttk
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.nombre_sttk,value:va.id_sttk})));
        }else{
            setSelectedValue(null);
        }
    }, [items,tipoTicket,value])
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={isDisabled}
            />
        </Styles>
    );
};

SelectSubTipoTicket.defaultProps = {
    isClearable: false
};

export default SelectSubTipoTicket;
