
import React from 'react'
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

const ButtonToolTips = ({
    tooltipPlace,
    tooltipTheme,
    tooltipText,
    className
}) => {
    const idBtn = uuidv4(); 

  return (
      <>
        <i
            className={`${className} fas fa-question-circle`}
            style={{fontSize:'25px', color:'blue'}}
            data-tip
            data-for={idBtn}
        />
            <ReactTooltip id={idBtn} place={tooltipPlace} type={tooltipTheme} effect="float">
                <span>{tooltipText}</span>
            </ReactTooltip>
      </>
  )
}

export default ButtonToolTips