import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, Select} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    optionId: Yup.mixed().required(),
});

function ModalSelect({
    show,
    hideModal,
    title,
    message,
    label,
    value,
    options,
    onConfirm,
    size
}) {

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        onConfirm({
            value: data.optionId
        });
        hideModal();
    }

    useEffect(() => {
        register('optionId');
    }, [register]);

    useEffect(() => {
        setValue('optionId', value);
    }, []);
    
    return (
        <Modal show={show} size={size} backdrop="static" onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="optionId">
                            {label}
                        </label>
                        <Select 
                            id="optionId"
                            isInvalid={errors.optionId}
                            options={options}
                            defaultValue={value}
                            onChange={(opt)=>setValue('optionId',opt.value)}
                        />
                        {errors.optionId && (
                            <div className="default-invalid-feedback">
                                {errors.optionId.message}
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    theme="default"
                    onClick={hideModal}
                >
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalSelect.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
        })
    ),
    onConfirm: PropTypes.func,
    size: PropTypes.oneOf(['sm','md','lg','xl'])
};

ModalSelect.defaultProps = {
    show: false,
    title:'',
    message:'',
    label:'',
    value:null,
    options: [],
    onConfirm: () => {},
    size:'sm',
};

export default ModalSelect;
