import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import LugarService from '@app/services/lugares.service';
import {yupResolver} from '@hookform/resolvers/yup';
const schema = Yup.object({
    nombre_l: Yup.string().max(200).required(),
});

function ModalLugares({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id_l: item.id_l
        };
        setIsLoading(true);
        if (form.id_l > 0) {
            LugarService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            LugarService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        if (item.id_l > 0) {
            setValue('nombre_l', item.nombre_l);
        }
    }, []);

    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_l > 0 ? 'Editar Lugar' : 'Registrar Lugar'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_l">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre_l? 'is-invalid': ''}`}
                            id="nombre_l"
                            name="nombre_l"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.nombre_l && (
                            <div className="invalid-feedback">
                                {errors.nombre_l.message}
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalLugares.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_l: PropTypes.number,
        nombre_l: PropTypes.string
    })
};

ModalLugares.defaultProps = {
    show: false,
    item: {
        id_l: 0,
        nombre_l: ''
    }
};

export default ModalLugares;