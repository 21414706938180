import React, {useState,useEffect,useContext} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, DateSingle} from '@app/components/index';
import * as Yup from 'yup';
import TicketService  from '@app/services/tickets.service';
import FechaFunction from '@app/functions/FechaFunction';
import { SocketContext } from '../../context/SocketContext';

const schema = Yup.object({
});

function ModalReaperturaTicket({show, hideModal, onGuardar,ticket}) {
    const [isLoading, setIsLoading] = useState(false);
    const [fvencimiento, setFVencimeinto] = useState(null);
    const handleClose = () => hideModal();
    const { sockets } = useContext( SocketContext );

    useEffect(() => {
        if(ticket && ticket.estado_tk !== 'VENCIDO'){
            if(ticket?.isInvalid_tk){
                const fecha = FechaFunction.addDaysDate(new Date(),1)
                setFVencimeinto(fecha)
            }else{
                const diasRestantes =  FechaFunction.diffDays(ticket.cerrado_tk, ticket.fechavencimiento_tk);
                const fecha = FechaFunction.addDaysDate(new Date(),diasRestantes)
                 setFVencimeinto(fecha)
            }
        }else{
            const fecha = FechaFunction.addDaysDate(new Date(),1)
            setFVencimeinto(fecha)
        }
    }, [])
    const onSubmit = () =>{
        const form = {
            id_tk: ticket.id_tk,
            fechavencimiento_tk: fvencimiento
        }
        TicketService.reaperturaTicket(form).then(()=>{
            hideModal();
            onGuardar();
            sockets?.emit( 'ticketRefresh', `cvv${ticket.id_tk}` )

        })
    }
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Reapertura del ticket # ${ticket?.id_tk} `}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background: 'white'}}>
                    <div className="col-5">
                        <label className="label-control" htmlFor="vtoTicket">
                            Fecha de Vencimiento
                        </label>
                        <DateSingle
                            id="vtoTicket"
                            value={fvencimiento}
                            err
                            minDate={new Date()}
                            onChange={(val)=> setFVencimeinto(val)}
                        />
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button
                    theme="primary"
                    onClick={onSubmit}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalReaperturaTicket;
