import React, { useEffect, useState } from 'react'
import { TicketVistaCard, TratoCard } from '@app/components/index';

function TabsSearchAll({ textSearch, data, handleCloseModal }) {
    const [tickets, setTickets] = useState(data || []);

    useEffect(() => {
        setTickets(data);
    }, [data])
    return (
        <div className="p-3 p-lg-5">
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    {(tickets?.camara?.length > 0 || tickets?.incidencia?.length > 0 || tickets?.ticket?.length > 0) ? (
                        <>
                            {tickets?.ticket?.length > 0 && (
                                <h4>Tickets</h4>
                            )}
                            {tickets?.ticket?.map((ticket)=>(
                                <TicketVistaCard
                                    textSearch={textSearch}
                                    item={ticket}
                                    tratos={[]}
                                    onCloseModal={handleCloseModal}
                                    key={ticket.id_tk}
                                    className="mb-3"
                                    hideClickMap
                                />
                            ))}
                            {tickets?.incidencia?.length > 0 && (
                                <>
                                    <div className="dropdown-divider" />
                                    <h4>Incidencias</h4>
                                </>
                            )}
                            {tickets?.incidencia?.map((ticket)=>(
                                <TicketVistaCard
                                    textSearch={textSearch}
                                    item={ticket}
                                    tratos={[]}
                                    onCloseModal={handleCloseModal}
                                    key={ticket.id_tk}
                                    className="mb-3"
                                    hideClickMap
                                />
                            ))}
                            {tickets?.camara?.length > 0 && (
                                <>
                                    <div className="dropdown-divider" />
                                    <h4>Camaras</h4>
                                </>
                            )}
                            {tickets?.camara?.map((ticket)=>(
                                <TratoCard
                                    textSearch={textSearch}
                                    item={ticket}
                                    onCloseModal={handleCloseModal}
                                    key={ticket?.id_t}
                                    className="mb-3"
                                />
                            ))}
                        </>
                    ):(
                        <div className='alert alert-light mt-2 text-center'>
                            <i className="fas fa-user" style={{fontSize: 200, color: 'gray'}}></i>
                            <div style={{fontSize: 20, color: 'gray'}}>
                                No se encontraron resultados
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TabsSearchAll