import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import styled from 'styled-components';
const Styles = styled.div`
button.btnBack{
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    position: absolute
}

    `;

const ButtonBack = ({
    state
}) => {
    const navigate = useNavigate();
    const redireccionar = () =>{
        if(!state){
            navigate(-1)
        }else{
            navigate(-2)
        }
    }
    return (
        <Styles>
            <Button 
                className='btnBack'
                onClick={()=>redireccionar()}
            >
                <i className="fas fa-arrow-left" />
            </Button>
        </Styles>
    );
};


export default ButtonBack;
