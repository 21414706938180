import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CanalesService from '@app/services/canales.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm} from '@app/components/index';
import AccionesService from '@app/services/acciones.service';
import ModalRol from '@app/modals/rol/ModalRol';
import { useNavigate } from 'react-router-dom';

const PermisoPage = ({
    user
}) => {
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const actualizar = async () => {
        const response = await AccionesService.getAllAutorizados();
        setItems(response)
    };
    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar el rol con sus permisos',
            message: '¿Esta seguro que desea eliminar el rol con sus permisos?',
            onConfirm: () => {
                CanalesService.destroy(item.id_can).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalRol, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre_r'
            },
            {
                Header: 'Acciones',
                accessor: 'acciones'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    if( row.original.id_r >= 99 ){
                        return (
                            <Dropdown size="sm">
                                <Dropdown.Toggle
                                    variant="danger"
                                    id="dropdown-basic"
                                >
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => onOpenEditar(row.original)}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => onEliminar(row.original)}
                                    >
                                        Eliminar
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    }
                    return ''
                }
            }
        ],
        []
    );

    useEffect(() => {
        if(user){
            if(user && user.id_p < 9999){
                actualizar();
            }else{
                navigate('/')
            }
        }
    }, [user]);
    return (
            <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 my-lg-3">
                            <h3>Rol - Permisos</h3>
                        </div>
                        <div className="col-lg-2 my-lg-3">
                            <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                NUEVO
                            </Button>
                        </div>

                        <div className="col-12 col-lg-12">
                               <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
            </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PermisoPage);
