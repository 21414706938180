import axios from 'axios';
const base = 'empresas';
function getAll() {
    return axios.get(base);
}


const EmpresaService = {
    getAll: getAll,
};
export default EmpresaService;
