import axios from 'axios';
const base = 'servicios';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    const form = new FormData();
    form.append('empresaId_s',data.empresaId_s);
    form.append('moneda_s',data.moneda_s);
    form.append('categorias_s',data.categorias_s);
    form.append('nombre_s',data.nombre_s);
    form.append('nombreCorto_s',data.nombreCorto_s);
    form.append('importe_s',data.importe_s);
    form.append('descripcion_s',data.descripcion_s);
    form.append('anchoBajada_s',data.anchoBajada_s);
    form.append('tipoAnchoBajada_s',data.tipoAnchoBajada_s);
    form.append('anchoSubida_s',data.anchoSubida_s);
    form.append('tipoAnchoSubida_s',data.tipoAnchoSubida_s);
    form.append('fecVigencia_s',data.fecVigencia_s);
    form.append('fecVencimiento_s',data.fecVencimiento_s);
    if(Array.isArray(data.img_s) && data.img_s.length>0){
        form.append('img_s',data.img_s[0]);
    } else {
        form.append('img_s',data.img_s);
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function update(data) {
    const form = new FormData();
    form.append('id_s',data.id_s);
    form.append('empresaId_s',data.empresaId_s);
    form.append('moneda_s',data.moneda_s);
    form.append('categorias_s',data.categorias_s);
    form.append('nombre_s',data.nombre_s);
    form.append('nombreCorto_s',data.nombreCorto_s);
    form.append('importe_s',data.importe_s);
    form.append('descripcion_s',data.descripcion_s);
    form.append('anchoBajada_s',data.anchoBajada_s);
    form.append('tipoAnchoBajada_s',data.tipoAnchoBajada_s);
    form.append('anchoSubida_s',data.anchoSubida_s);
    form.append('tipoAnchoSubida_s',data.tipoAnchoSubida_s);
    form.append('fecVigencia_s',data.fecVigencia_s);
    form.append('fecVencimiento_s',data.fecVencimiento_s);
    if(Array.isArray(data.img_s) && data.img_s.length>0){
        form.append('img_s',data.img_s[0]);
    } else {
        form.append('img_s',data.img_s);
    }
    return axios.put([base, data.id_s].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const ServicioService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy
};
export default ServicioService;