import axios from 'axios';
const base = 'tickets';
const baseUxT = 'usuariotickets';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    const form = new FormData();
    if(!data.ticketIndv){
        form.append('MotivoTicketId',data.MotivoTicketId);
        form.append('cuerpo_tk',data.cuerpo_tk);
        form.append('prioridad_tk',data.prioridad_tk);
        form.append('tipoId_uxt',data.tipoId_uxt);
        form.append('tipo_uxt',data.tipo_uxt);
        form.append('titulo_tk',data.titulo_tk);
        form.append('vtoTicket',data.vtoTicket);
        form.append('estado_tk',data.estado_tk);
    }else{
        form.append('titulo_tk',data.titulo_tk);
        form.append('cuerpo_tk',data.cuerpo_tk);
        if(data.tickerId){
            form.append('tickerId',data.tickerId);
        }
        if(data.ticketIdOld_tk){
            form.append('ticketIdOld_tk',data.ticketIdOld_tk);
        }
        if(data.createdEmp_tk === 'STRONG'){
            form.append('MotivoTicketId',data.MotivoTicketId);
            form.append('tipoId_uxt',data.tipoId_uxt);
            form.append('tipo_uxt',data.tipo_uxt);
            form.append('estado_tk',data.estado_tk);
            form.append('prioridad_tk',data.prioridad_tk);

        }else if(data.createdEmp_tk === 'NUBICOM'){
            form.append('MotivoTicketId',data.MotivoTicketId);
            form.append('tipoId_uxt',data.tipoId_uxt);
            form.append('tipo_uxt',data.tipo_uxt);
            form.append('estado_tk',data.estado_tk);
            form.append('prioridad_tk',data.prioridad_tk);
            form.append('tipoTicketId_tk',data.tipoTicketId_tk);
            form.append('subTipoTicketId_tk',data.subTipoTicketId_tk);
            form.append('fechavencimiento_tk',data.fechavencimiento_tk);
            if(data?.nivelAdmTkId_tk){
                form.append('nivelAdmTkId_tk',data.nivelAdmTkId_tk);
                if(data?.motivoBajaTk_tk){
                    form.append('motivoBajaTk_tk',data.motivoBajaTk_tk);
                }
            }

        }
    }
    form.append('propertys',data.propertys);
    form.append('createdEmp_tk',data.createdEmp_tk);
    if(data.cuentaContratoId_txt){
        form.append('cuentaContratoId_txt',data.cuentaContratoId_txt);
    }else if(data.id_t){
        form.append('id_t',data.id_t);
    }else if(data.tratos){
        form.append('tratos',data.tratos);
    }
    if(data?.foto1){
        if(Array.isArray(data.foto1) && data.foto1.length>0){
            form.append('foto1',data.foto1[0]);
        } else {
            form.append('foto1',data.foto1);
        }
    }
    if(data?.foto2){
        if(Array.isArray(data.foto2) && data.foto2.length>0){
            form.append('foto2',data.foto2[0]);
        } else {
            form.append('foto2',data.foto2);
        }
    }
    if(data?.foto3){
        if(Array.isArray(data.foto3) && data.foto3.length>0){
            form.append('foto3',data.foto3[0]);
        } else {
            form.append('foto3',data.foto3);
        }
    }
    if(data?.foto4){
        if(Array.isArray(data.foto4) && data.foto4.length>0){
            form.append('foto4',data.foto4[0]);
        } else {
            form.append('foto4',data.foto4);
        }
    }

    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getById(id,query = null) {
    return axios.get([base, id].join('/'),{
        headers: query
    });
}
function update(data) {
    return axios.put([base, data.id_tk].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}
function asignar(data) {
    return axios.post(baseUxT, data);
}
function cerrarTicket(data) {
    if( data.type === 'multi' ){
        return axios.put([base, data.id_tk].join('/close/'),data);
    }

    const form = new FormData();
        form.append('id_tk',data.id_tk);
        form.append('comentarioCierre',data.comentarioCierre);
        form.append('contratoNubi_tk',data.contratoNubi_tk);
        form.append('type',data.type);

        if(data?.foto){
            if(Array.isArray(data.foto) && data.foto.length>0){
                form.append('foto1',data.foto[0]);
            } else {
                form.append('foto1',data.foto);
            }
        }
        if(data?.foto2){
            if(Array.isArray(data.foto2) && data.foto2.length>0){
                form.append('foto2',data.foto2[0]);
            } else {
                form.append('foto2',data.foto2);
            }
        }
        if(data?.foto3){
            if(Array.isArray(data.foto3) && data.foto3.length>0){
                form.append('foto3',data.foto3[0]);
            } else {
                form.append('foto3',data.foto3);
            }
        }
    return axios.put([base, data.id_tk].join('/close/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function situacionExterna(data) {
    const form = new FormData();

    form.append('id_tk',data.id_tk);
    form.append('situacion',data.situacion);
    form.append('cuerpo_com',data.cuerpo_com);
    if(data?.foto){
        if(Array.isArray(data.foto) && data.foto.length>0){
            form.append('file0',data.foto[0]);
        } else {
            form.append('file0',data.foto);
        }
    }
    if(data?.foto2){
        if(Array.isArray(data.foto2) && data.foto2.length>0){
            form.append('file1',data.foto2[0]);
        } else {
            form.append('file1',data.foto2);
        }
    }
    if(data?.foto3){
        if(Array.isArray(data.foto3) && data.foto3.length>0){
            form.append('file2',data.foto3[0]);
        } else {
            form.append('file2',data.foto3);
        }
    }
    return axios.put([base, data.id_tk].join('/externo/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function ticketInvalido(data) {
    const form = new FormData();

    form.append('id_tk',data.id_tk);
    form.append('cuerpo_com',data.cuerpo_com);
    if(data?.foto){
        if(Array.isArray(data.foto) && data.foto.length>0){
            form.append('file0',data.foto[0]);
        } else {
            form.append('file0',data.foto);
        }
    }
    if(data?.foto2){
        if(Array.isArray(data.foto2) && data.foto2.length>0){
            form.append('file1',data.foto2[0]);
        } else {
            form.append('file1',data.foto2);
        }
    }
    if(data?.foto3){
        if(Array.isArray(data.foto3) && data.foto3.length>0){
            form.append('file2',data.foto3[0]);
        } else {
            form.append('file2',data.foto3);
        }
    }
    return axios.put([base, data.id_tk].join('/invalidtk/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getAllUxT(query = null) {
    return axios.get(baseUxT, {
        params: query
    });
}

function reaperturaTicket(data) {
    return axios.put([base, data.id_tk].join('/reapertura/'),data);
}

function getTareaTask(id) {
    return axios.get([base,'tareas', id].join('/'))
}

function closePostCierre(id) {
    return axios.put([base, id].join('/closeasig/'));
}
function registerEsp(info) {
    return axios.post('tickets/esp', info)
}

const TicketService = {
    getAll,
    register,
    getById,
    update,
    destroy,
    asignar,
    cerrarTicket,
    getAllUxT,
    situacionExterna,
    ticketInvalido,
    reaperturaTicket,
    getTareaTask,
    closePostCierre,
    registerEsp
};
export default TicketService;