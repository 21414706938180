import React, {useEffect, useState} from 'react';
import {Button, LogsTicketOld } from '@app/components/index';
import { Modal } from 'react-bootstrap';

import LogsTicketsService from '@app/services/logsTickets.service';


function ModalLogsRecorrido({show, hideModal, ticketId, ticketIdOld}) {
    const handleClose = () => hideModal();
    const [logsRecorrido, setlogsRecorrido] = useState([]);

    useEffect(() => {
        if(ticketIdOld){
            LogsTicketsService.getAll({logs: ticketIdOld, type:'cvv'}).then((data)=>{
                setlogsRecorrido(data)
            })
        }
    }, [])


    return (        
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Historial del ticket #${ticketId}`}
                </Modal.Title>
            </Modal.Header>
                {logsRecorrido.length > 0 ? (
                    logsRecorrido?.map((lg)=>{
                        return <LogsTicketOld item={lg} key={lg.id_lt} handleClose={handleClose} />
                    })
                ):(
                    <div className='alert alert-light mt-2 text-center'>
                        {`El ticket #${ticketId} no tiene relacion con otro ticket.`}
                    </div>
                )}
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalLogsRecorrido;
