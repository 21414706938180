import React from 'react'
import Carousel, { consts } from 'react-elastic-carousel';
import styled from 'styled-components';
import { Button } from '@app/components/index';

const Flex = styled.div`
    display: flex;
    flex-direction: row;
`;
const Square = styled.div`
    cursor: pointer;
    transition: all 250ms ease-in 0s;
    background-color: ${props => props.active?'rgb(139, 195, 74)':'transparent'};
    transform: ${props => props.active?'scale(1)':'scale(1.1)'};
    box-shadow: rgb(85 85 85) 0px 0px 2px 1px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 10px;
`;

const sizes = {
    comment: [
        { width: 1, itemsToShow: 2 },
        { width: 250, itemsToShow: 3, itemsToScroll: 3, pagination: false },
        { width: 550, itemsToShow: 4 },
        { width: 850, itemsToShow: 5, itemsToScroll: 3 },
        { width: 1150, itemsToShow: 6 },
        { width: 1450, itemsToShow: 7 },
    ],
    small: [
        { width: 1, itemsToShow: 2 },
        { width: 550, itemsToShow: 3, itemsToScroll: 3, pagination: false },
        { width: 850, itemsToShow: 4 },
        { width: 1150, itemsToShow: 5, itemsToScroll: 3 },
        { width: 1450, itemsToShow: 6 },
        { width: 1750, itemsToShow: 7 },
    ],
    normal: [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ]
}

function CarouselBase({
    pagination,
    children,
    size,
}){
    const customArrow = ({ type, onClick: onClicArrow, isEdge }) => {
        const pointer = type === consts.PREV ? 'fas fa-chevron-left' : 'fas fa-chevron-right'
        return <Button icon={pointer} disabled={isEdge} onClick={onClicArrow} />
    }

    const customPagination = ({ pages, activePage, onClick }) => {
        return (
        <Flex direction="row">
            {pages.map(page => {
            const isActivePage = activePage === page
            return (
                <Square
                    key={page}
                    onClick={() => onClick(page)}
                    active={isActivePage}
                />
            )
            })}
        </Flex>
        )
    }
    return (
        <Carousel
            pagination={pagination}
            renderArrow={customArrow}
            renderPagination={customPagination}
            breakPoints={sizes[size]}
        >
            {children}
        </Carousel>
    )
}
CarouselBase.defaultProps = {
    pagination: false,
    size: 'normal', /** small | normal */
};

export default CarouselBase;