import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import UserService from '@app/services/personas.service';

const Styles = styled.div``;

const SelectUser = ({id, value, onChange, isInvalid, isClearable,grupo, filter,...defaultProps}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_p === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_p === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };
    useEffect(() => {
        const form = {
            type:filter
        }
        if(grupo){
            form.group = grupo;
        }
        UserService.getAll(form).then((response) => {
            setItems(response);
        })
    }, [])

    useEffect(() => {
        const newOptions = items.map((item) => {
            return {
                value: item.id_p,
                label: item.nombre_p,
                dni: item.dni_p
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
                const item = items.find(it=>it.id_p === va);
                if(item){
                    return {
                        value:item.id_p,
                        label:item.nombre_p,
                        dni: item.dni_p
                    };  
                }
                return {
                    value:va.id_p,
                    label:va.nombre_p,
                    dni: va.dni_p
                };
                
            }))
        }
    }, [items]);
    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectUser.defaultProps = {
    isClearable: false
};

export default SelectUser;
