import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import CanalesService from '@app/services/canales.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import ModalOperadores from '@app/modals/operadores/ModalOperadores';
import {Button, ModalConfirm} from '@app/components/index';
import UserService from '@app/services/personas.service';


const OperadoresPage = ({
    user
}) => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const actualizar = async () => {
        const response = await UserService.getAll({type: 'operadores'});
        setItems(response);
    };
    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar el Canal de Tarea',
            message: '¿Esta seguro que desea eliminar el Canal de Tarea?',
            onConfirm: () => {
                CanalesService.destroy(item.id_can).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalOperadores, {item: item, onGuardar: onGuardar});
    };
    const onOpenRenewPass = (item) => {
        showModal(ModalConfirm, {
            title: 'Enviar nueva contraseña',
            message: '¿Esta seguro que desea enviar una nueva contraseña al usuario?',
            onConfirm: () => {
                UserService.renewPassUser(item.id_p).then(() => {
                    actualizar();
                });
            }
        });
    };

    const columns = React.useMemo(
        () => [
            {
                width: 10,
                Header: 'Nro',
                accessor: 'id_p'
            },
            {
                Header: 'Nombre',
                accessor: 'nombre_p'
            },
            {
                Header: 'Email',
                accessor: 'email_p'
            },
            {
                Header: 'Puesto',
                accessor: 'puesto_p'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenRenewPass(row.original)}
                                >
                                    Enviar Nueva Contraseña
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        if(user){
            if(user && user.id_p < 9999){
                actualizar();
            }else{
                navigate('/')
            }
        }
    }, [user]);

    return (
            <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 my-lg-3">
                            <h3>Operadores</h3>
                        </div>
                        <div className="offset-2 col-4 offset-lg-4 col-lg-2 my-lg-3">
                            <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                NUEVO
                            </Button>
                        </div>
                        <div className="col-12 col-lg-12">
                                <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
            </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OperadoresPage);