import React from 'react';
import CreatableSelect from 'react-select/creatable';
import {toast} from 'react-toastify';

const createOption = (label) => ({
    label,
    value: label,
});
/* eslint-disable no-useless-escape */
const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const SelectInsertEmail =  ({value,onChange}) => {
    const [inputValue, setInputValue] = React.useState('');

    const handleKeyDown = (event) => {
        if (!inputValue || inputValue?.length < 7) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if(validateEmail(inputValue)){
                    onChange((prev) => [...prev, createOption(inputValue)]);
                    setInputValue('');
                    event.preventDefault();
                }else{
                    toast.error('No es un correo valido', {
                        position: "top-right",
                        autoClose: 2500,
                        pauseOnHover: false,
                    });
                }
                break;
            default:
                break;
        }
    };

    return (
        <CreatableSelect
            components={{ DropdownIndicator: null }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue) => onChange(newValue)}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Ingrese el/los correo"
            value={value}
        />
    );
};

export default SelectInsertEmail