import React, { useState } from 'react';
import ModalBuscador from '@app/modals/buscador/ModalBuscador';
import { useNavigate } from 'react-router-dom';
import {showModal} from 'react-redux-modal-provider';
import User from './user-dropdown/UserDropdown';

const Header = ({toggleMenuSidebar,countNotifi,showNotifi}) => {
    const navigate = useNavigate();
    const [textSearch, setTextSearch] = useState('')
    const redireccionar = (tk) =>{
        navigate(`/tickets/${tk.id_tk}/ver`)
    }
    const onOpenModalSearch = (text) => {
        setTextSearch(text)
        if(text?.length > 1){
            showModal(ModalBuscador,{textSearch: text})
        }
    }
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button
                        onClick={() => toggleMenuSidebar()}
                        type="button"
                        className="nav-link"
                        data-widget="pushmenu"
                    >
                        <i className="fas fa-bars" />
                    </button>
                </li>
            </ul>
            <div className='col-lg-3 col-md-4 searchBox d-none d-md-inline-block'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text align-items-center">
                            <i className="fas fa-search iconesp" style={{ lineHeight: 'normal',marginLeft: '-3.5%', display: 'inline-block' }}></i>
                        </span>
                    </div>
                    <input
                        type="text"
                        className='form-control'
                        id="titulo_tk"
                        name="titulo_tk"
                        autoComplete="off"
                        value={textSearch}
                        onChange={(e) => onOpenModalSearch(e.target.value)}
                    />
                </div>
            </div>
            <ul className="navbar-nav ml-auto">
                <button
                    className="nav-link"
                    style={{backgroundColor:'transparent', border: 'none'}}
                    type="button"
                    onClick={()=> showNotifi()}
                >
                    <i className="far fa-bell" style={{fontSize: '20px', marginTop: 5}} />
                    {(countNotifi > 0 && (
                        <span className="badge badge-danger navbar-badge" style={{fontSize: '13px'}}>{countNotifi || 0}</span>
                    ))}
                </button>
                <User />
            </ul>
        </nav>
    );
};

export default Header;
