import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, SelectContacto, SelectLugares} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import TratoService from '@app/services/tratos.service';
const schema = Yup.object({
    nombre_t: Yup.string().max(200).required(),
});

function ModalTratoSimple({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });
    
    const [lugarNom, setLugarNom] = useState(item?.lugarNombre_t)
    const lugar = watch('lugarId_t',item?.lugarId_t);
    const contacto = watch('contactoId_t',item?.contactoId_t);
    const [cvvId, setCvvId] = useState(item?.contactoId_t || 0);

    const onSubmit = (data) => {
        const form = {
            ...item,
            ...data,
            id_t: item.id_t,
            type: 'cvv'
        };
        if(contacto && lugar){
            setIsLoading(true);
            form.contactoId_t = contacto;
            form.lugarId_t = lugar;
            form.lugarNombre_t = lugarNom;
            form.isCamera_t = true;
            form.tipoMaterial_t = 'OTROS';
            form.categoria_t = 'GOBIERNO';
            if(contacto === 1 || contacto === '1'){
                form.isCapital_t = true;
            }
            if(item?.id_t > 0){
                form.id_t = item.id_t;
                TratoService.update(form).then(()=>{
                    onGuardar();
                    hideModal();
                })
                .finally(()=>{
                     setIsLoading(false);
                })
            }else{
                TratoService.register(form).then(()=>{
                    onGuardar();
                    hideModal();
                })
                .finally(()=>{
                    setIsLoading(false);
                })
            }
        }

    };

    useEffect(() => {
        register('lugarId_t');
        register('contactoId_t');
    }, [register]);

    useEffect(() => {
        if (item.id_t > 0) {
            setValue('nombre_t', item.nombre_t);
            setValue('obs_t', item.obs_t);
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_t > 0 ? 'Editar Equipo' : 'Registrar Equipo'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="contacto">
                            Contacto
                        </label>
                        <SelectContacto
                            camera
                            id="contacto"
                            name="contacto"
                            value={contacto}
                            onChange={(opt)=>{
                                setValue('contactoId_t',opt.value)
                                setCvvId(opt.value)
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="lugarId_t">
                            Lugar
                        </label>
                        <SelectLugares
                            id="lugarId_t"
                            name="lugarId_t"
                            cvv={cvvId}
                            value={lugar}
                            onChange={(opt)=>{
                                setValue('lugarId_t',opt.value)
                                setLugarNom(opt.label)
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_t">
                            Nombre y Mac
                        </label>
                        <input
                            className={`form-control ${errors.nombre_t? 'is-invalid': ''}`}
                            id="nombre_t"
                            name="nombre_t"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.nombre_t && (
                            <div className="invalid-feedback">
                                {errors.nombre_t.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="obs_t">
                            Observaciones
                        </label>
                        <textarea
                            className={`form-control ${errors.obs_t? 'is-invalid': ''}`}
                            id="obs_t"
                            name="obs_t"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalTratoSimple.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_t: PropTypes.number,
        nombre_t: PropTypes.string
    })
};

ModalTratoSimple.defaultProps = {
    show: false,
    item: {
        id_t: 0,
    }
};

export default ModalTratoSimple;