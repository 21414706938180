import React from 'react'
import FechaFunction from '@app/functions/FechaFunction';
import { useNavigate } from 'react-router-dom';

function LogsTicketOld({
    item,
    type,
    handleClose
}) {
    const navigate = useNavigate();
    const redirect = ()=>{
        if(handleClose){
            handleClose()
        }
        navigate(`/tickets/${item.id_tk}/ver`)
    }
    return (
        <div className="log-card mt-3">
            <div className='col-12'>
                <div className='row'>
                    <button 
                        type="button"
                        className="btn-circle"
                        onClick={()=> redirect()}
                    >
                        <span>{item.id_tk}</span>
                    </button>
                    <h5 className='mt-2 ml-2'>{`Titulo: ${item.titulo_tk}`}</h5>
                    <footer className="blockquote-footer text-right ml-4 mt-4">
                        {item && item.cerrado_tk ? (
                            `Estado: Cerrado ${FechaFunction.format(new Date(item.cerrado_tk),'dd/MM/yyyy HH:mm:ss')}`
                        ):(
                            `Estado: Abierto ${FechaFunction.format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')}`
                        )}
                    </footer>
                </div>
            </div>
        </div>
    )
}

LogsTicketOld.defaultProps = {
    canShowMore:false,
    buttons:null,
};

export default LogsTicketOld;
