import React, {useState, useEffect, useContext} from 'react';
import AOS from 'aos';
import { useDispatch,useSelector, connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import {Button,TablePaginated} from '@app/components/index';
import ModalCamaraEdit from '@app/modals/camaras/ModalCamaraEdit';
import ModalTratoSimple from '@app/modals/trato/ModalTratoSimple';
import { actualizarTratosCam } from '@app/store/slices/default.slice';
import { SocketContext } from '../../context/SocketContext';

const TratosCamPage = ({
    user
}) => {
    AOS.init();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const tratoscam = useSelector(state => state.default.tratoscam);
    const { sockets } = useContext( SocketContext );

    useEffect(() => {
        setItems(tratoscam)
    }, [tratoscam])

    const onGuardar = () => {
        dispatch(actualizarTratosCam())
        sockets?.emit('newTrato','');
    };
    const onClickEditCamera = (cam) => {
        if(cam?.tipoMaterial_t === 'CAMARA'){
            showModal(ModalCamaraEdit, { item: cam ,onGuardar: onGuardar});
        }else{
            showModal(ModalTratoSimple, { item: cam ,onGuardar: onGuardar});
        }
    }
    const onClickNewCamera = () => {
        showModal(ModalCamaraEdit, { onGuardar: onGuardar});
    }

    const onClickNewTratSimple = () => {
        showModal(ModalTratoSimple, {onGuardar: onGuardar});
    }

    const onOpenVer = (item) => {
        navigate(`/tratos/${item.id_t}/vercam`);
    }

    const columns = React.useMemo(
        () => [
            {
                Width: 350,
                Header: 'Nombre',
                accessor: 'nombre_t',
            },
            {
                Header: 'Localidad',
                Filter: false,
                accessor: 'lugar.nombre_l',
                Cell: ({row}) => {
                    return (
                        row.original?.nombre_l
                    )
                },
            },
            {
                Header: 'Direccion',
                accessor: 'direccion_t',
            },
            {
                Width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: false,
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenVer(row.original)}
                                >
                                    Ver
                                </Dropdown.Item>
                                {/* {user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                    <Dropdown.Item
                                        onClick={() => onClickEditCamera(row.original)}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                )} */}
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="my-1 col-md-6 col-lg-9 mt-lg-3" data-aos="fade-right">
                        <h2 className='user-select-none'>Camaras / Equipos</h2>
                    </div>
                    {user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                        <>
                            {/* <div className="my-1 col-md-3 col-lg-2 mt-lg-3" data-aos="fade-down">
                                <Button className="btn-block" onClick={onClickNewCamera}>
                                    NUEVA CAMARA
                                </Button>
                            </div> */}
                            <div className="my-1 col-md-3 col-lg-2 mt-lg-3" data-aos="fade-down">
                                <Button className="btn-block" onClick={onClickNewTratSimple}>
                                    NUEVO EQUIPO
                                </Button>
                            </div>
                        </>
                    )}
                    <div className="col-12" data-aos="fade-right">
                        <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
    stack: state.modalProvider.stack,

});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(TratosCamPage);
