import React, { useEffect, useMemo, useState } from 'react'
import {connect} from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import CustomExternalTicketService from '@app/services/customExternal.service';
import { Button } from '@app/components/index';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Badge from 'react-bootstrap/Badge'
import ModalPreTicketViewCreated from '@app/modals/preTicket/ModalPreTicketViewCreated';
import {showModal} from 'react-redux-modal-provider';
import ModalPreTicketPreview from '@app/modals/preTicket/ModalPreTicketPreview';
import FechaFunction from '@app/functions/FechaFunction';

const PreTicketScreen = ({user}) => {
    const [items, setItems] = useState([])
    const navigate = useNavigate();
    const actualizar = async() =>{
        await CustomExternalTicketService.getAll({all: true}).then((resp)=>{
            setItems(resp)
        })
    }
    const onOpenVer = (data) =>{
        showModal(ModalPreTicketViewCreated,{items:data.tickets,idIncidencia: data.idIncidencia_ptk})
    }
    const onCreatedTickets = (data,hidButton) =>{
        showModal(ModalPreTicketPreview,{item:data,onGuardar: actualizar,hideButton:hidButton})
    }
    const columns = useMemo(
        () => [
            {
                Header: 'Fecha',
                accessor: 'fechaCreated_ptk',
                Filter: false,
                Width: 120,
                Cell: ({row}) => FechaFunction.format(new Date(row.original.fechaCreated_ptk), 'dd-MM-yyyy HH:mm:ss')
            },
            {
                Header: 'Nro',
                accessor: 'id_ptk',
                Width: 120,
            },
            {
                Header: 'Nro Incidencia',
                accessor: 'idIncidencia_ptk',
                Width: 150,
            },
            {
                Header: 'Titulo',
                accessor: 'titulo_ptk'
            },
            {
                Header: 'Cant. Tickets',
                Filter: false,
                accessor: 'tickets',
                Cell: ({row}) => row.original?.tickets?.length || 0
            },
            {
                Header: 'Estado',
                Filter: false,
                accessor: 'estado',
                Cell: ({row}) => {
                    if(row.original?.isDeleted_ptk && !row.original?.isCreated_ptk){
                        return <h6><Badge variant="danger">Eliminado</Badge></h6>
                    }
                    if(!row.original?.isDeleted_ptk && row.original?.isCreated_ptk){
                        return <h6><Badge variant="success">Creado</Badge></h6>
                    }
                    if(row.original?.isDeleted_ptk && row.original?.isCreated_ptk){
                        return <h6><Badge variant="success">Creado 2da vez</Badge></h6>
                    }
                    return <Badge variant="dark">Pendiente</Badge>
                }
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {row.original?.isCreated_ptk && (
                                    <Dropdown.Item
                                        onClick={() => onOpenVer(row.original)}
                                    >
                                        Ver
                                    </Dropdown.Item>
                                )}
                                {!row.original?.isDeleted_ptk && (
                                    <Dropdown.Item
                                        onClick={() => onCreatedTickets(row.original,false)}
                                    >
                                        Crear Tickets
                                    </Dropdown.Item>
                                )}
                                {row.original?.isDeleted_ptk && !row.original?.isCreated_ptk && (
                                    <Dropdown.Item
                                        onClick={() => onCreatedTickets(row.original,true)}
                                    >
                                        Reapertura
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        if(user){
            if(user && user.id_p < 9999){
                actualizar();
            }else{
                navigate('/')
            }
        }
    }, [user]);


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-12 my-lg-3">
                    <h3>Pre-Tickets</h3>
                </div>
                <div className="col-12 col-lg-12">
                    <TablePaginated columns={columns} data={items} />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PreTicketScreen);