import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {TicketCard} from '@app/components/index';


function ModalVistaDashboard({show, hideModal, items,estado}) {
    const handleClose = () => hideModal();

    return (
        <Modal show={show} backdrop="false" onHide={handleClose} size="lg">
            <div className="container" style={{backgroundColor: '#f5f5f5'}}>
                <div className="col-12">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {`TICKETS ${estado}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="offset-lg-12 col-lg-12">
                                { items.length > 0 ?(
                                    items?.map((ticket)=>{
                                        return <TicketCard hideModal={hideModal} item={ticket} key={ticket.id_tk} className="mb-3" notHideModal hidenronom />
                                    })
                                ):(
                                    <div className='alert alert-light mt-2 text-center'>
                                        Aún no se crearon tickets para el periodo seleccionado
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </div>
        </Modal>
    );
}

export default ModalVistaDashboard;
