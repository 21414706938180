import TicketService from '@app/services/tickets.service';
import React, {useState, useEffect, forwardRef,} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import styled from 'styled-components';

const Styles = styled.div`

    .divTextBox {
        height: 50px;
        overflow: hidden;
        position: relative;
    }
    .divTextBox span {
        position: absolute;
        white-space: nowrap;
        transform: translateX(0);
        transition: 5s;
    }
    .divTextBox:hover span {
        transform: translateX(calc(200px - 100%));
    }
`;
const InputTicketSearch = forwardRef(({
    id,
    field,
    value,
    onSelect,
    ...defaultProps
},ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);
        TicketService.getAll({search: query,exclusive:true}).then((data)=>{
            setOptions(
                data.map((item) =>{
                    const clienteNombre = `${item.id_tk}`;
                    const tituloTicket = item.titulo_tk;
                    const descripTicket = item.cuerpo_tk
                    return {
                        ...item,
                        [field]: `${clienteNombre} ${tituloTicket}`,
                        clienteNombre: clienteNombre,
                        tituloTicket: tituloTicket,
                        descripTicket: descripTicket
                    }
                })
            )
        })
        .finally(() => {
            setIsLoading(false);
        }); 
    };

    const onSelected = (newSelect) => {
        onSelect(newSelect);
    };

    useEffect(() => {
        ref.current.clear()
        ref.current.setState({text:value})
    }, [value])

    return (
        <AsyncTypeahead
            {...defaultProps}
            clearButton
            ref={ref}
            id={id}
            inputProps={{
                id: id
            }}
            isLoading={isLoading}
            labelKey={field}
            minLength={1}
            onSearch={handleSearch}
            onChange={onSelected}
            filterBy={() => true}
            options={options}
            placeholder="Buscar Nro/Titulo..."
            emptyLabel="No hay coincidencias"
            promptText="Escribe para buscar..."
            renderMenuItemChildren={(option, props) => {
                let cortarTitulo = `#${option?.clienteNombre || ''} ${option?.tituloTicket || ''}`;
                let cortarDescr = option?.descripTicket?.replace(/<[^>]*>?/gm, '');
                if(cortarTitulo.length > 34){
                    cortarTitulo = `${cortarTitulo.substring(0,34)}...`
                }
                if(cortarDescr.length > 80){
                    cortarDescr = `${cortarDescr.substring(0,80)}...`
                }
                return (
                    <Styles>
                        <div className={cortarDescr?.length > 40 && "divTextBox"} style={{color:'black'}}>
                            <strong>{cortarTitulo}</strong>
                            <br />
                            <span>
                                <small className='ml-3'>{cortarDescr}</small>
                            </span>
                        </div>
                    </Styles>
                )
            }}
            useCache={false}
        />
    );
});

/**
 * documento
 * clienteId
 */
 InputTicketSearch.defaultProps = {
    field: 'nombreCompleto',
    value: '',
    onSelect: () => {},
};

export default InputTicketSearch;
