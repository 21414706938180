import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import UserService from '@app/services/personas.service';
import {yupResolver} from '@hookform/resolvers/yup';
const schema = Yup.object({
    nombre_p: Yup.string().max(200).required(),
    email_p: Yup.string().max(200).required(),
});

function ModalOperadores({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id_p: item.id_p
        };
        setIsLoading(true);
        if (form.id_p > 0) {
            UserService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            UserService.register(form).then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        if (item.id_p > 0) {
            setValue('nombre_p', item.nombre_p);
            setValue('email_p', item.email_p);
        }
    }, []);

    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_p > 0 ? 'Editar Operador' : 'Registrar Operador'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_p">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre_p? 'is-invalid': ''}`}
                            id="nombre_p"
                            name="nombre_p"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.nombre_p && (
                            <div className="invalid-feedback">
                                {errors.nombre_p.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_p">
                            Email
                        </label>
                        <input
                            className={`form-control ${errors.email_p? 'is-invalid': ''}`}
                            id="email_p"
                            name="email_p"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.email_p && (
                            <div className="invalid-feedback">
                                {errors.email_p.message}
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalOperadores.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_p: PropTypes.number,
        nombre_p: PropTypes.string
    })
};

ModalOperadores.defaultProps = {
    show: false,
    item: {
        id_p: 0,
        nombre_p: ''
    }
};

export default ModalOperadores;