import React, { useEffect, useState } from 'react'
import { TicketCard } from '@app/components/index';
import usePermisos from '@app/hooks/usePermisos';
import { Button } from 'react-bootstrap';

function TabTratoTickets({
    tickets
}) {
    const [filtro, setfiltro] = useState(tickets);
    const [filtroTK, setfiltroTK] = useState('');
    const permisos = usePermisos();

    useEffect(() => {
        if(filtroTK === ''){
            setfiltro(tickets)
        }else{
            setfiltro(tickets?.filter((tk) => tk.estado_tk === filtroTK))
        }
    }, [filtroTK])


    return (
        <div className="p-5">
            <div className="row">
                <Button style={{width: '100px'}} variant={filtroTK === 'ABIERTO' ? 'success' : 'outline-success'} onClick={()=>{setfiltroTK('ABIERTO')}}>Abiertos</Button>
                {permisos['ticket.update911'] &&(
                    <>
                        <Button style={{width: '100px'}} variant={filtroTK === 'PORVENCER' ? 'warning' : 'outline-warning'} onClick={()=>{setfiltroTK('PORVENCER')}}>Por Vencer</Button>
                        <Button style={{width: '100px'}} variant={filtroTK === 'VENCEHOY' ? 'danger' : 'outline-danger'} onClick={()=>{setfiltroTK('VENCEHOY')}}>Vence Hoy</Button>
                        <Button style={{width: '100px'}} variant={filtroTK === 'VENCIDO' ? 'dark' : 'outline-dark'} onClick={()=>{setfiltroTK('VENCIDO')}}>Vencidos</Button>
                    </>
                )}
                <Button style={{width: '100px'}} variant={filtroTK === 'CERRADO' ? 'dark' : 'outline-dark'} onClick={()=>{setfiltroTK('CERRADO')}}>Cerrados</Button>
                <Button style={{width: '100px'}} variant={filtroTK === '' ? 'primary' : 'outline-danger'} onClick={()=>{setfiltroTK('')}}>Todos</Button>
                <h5 className="m-2">
                    Total Ticket Filtrados: 
                    {filtro?.length}
                </h5>
            </div>
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    {
                        filtro?.map((ticket)=>{
                            return <TicketCard item={ticket} key={ticket?.id_tk} className="mb-3" hidenronom />
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default TabTratoTickets