import React, {useEffect, useState} from 'react';
import AOS from 'aos';
import { Button, ButtonToolTips, MapBase, MarkerTratos} from '@app/components/index';
import { connect } from 'react-redux';
import {showModal} from 'react-redux-modal-provider';
import DownloadFile from '@app/services/downloadFile.service';
import ModalFiltroMapa from '@app/modals/trato/ModalFiltroMapa';

const TratosCameraMapPage = () => {
    AOS.init();
    const [coordenadas, setCoordenadas] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [zoom, setZoom] = useState(15);
    const [items, setItems] = useState([]);
    const [itemsFiltro, setItemsFiltro] = useState({});
    const [dataFiltro, setDataFiltro] = useState([]);

    const actualizar = () =>{
        setIsLoading(true)
        DownloadFile.mapActualCamaras().then((resp)=>{
            setIsLoading(false)
            setItems(resp)
            setDataFiltro(resp)
        })
    }
    const onOpenModalFiltro = () =>{
        showModal(ModalFiltroMapa,{ items: items, setFiltroItems: setDataFiltro,itemsFiltro:itemsFiltro,setItemsFiltro:setItemsFiltro})
    }
    const onOpenLimpiarFiltro = () =>{
        setItemsFiltro({})
        setDataFiltro(items)
    }
    useEffect(() => {
        actualizar()
    }, [])


    return (
        <>
            <div className="container-fluid">
                <div className="col-12 row">
                    <div className="col-lg-9 col-md-6 row mt-lg-4">
                        <div>
                            <h2 className='user-select-none mr-2'>Ubicaciones</h2>
                        </div>
                        <div>
                            <ButtonToolTips className='mt-2' tooltipTheme='info' tooltipPlace='right' tooltipText='Ubicaciones de cada camara' />
                        </div>
                    </div>
                    <div className="col-lg-3 mt-lg-4 col-md-5">
                        <div className='row'>
                            <Button
                                onClick={()=>onOpenModalFiltro()}
                                style={{width: 120}}
                                disabled={isLoading}
                            >
                                Filtros
                            </Button>
                            <Button
                                onClick={()=>onOpenLimpiarFiltro()}
                                style={{width: 120}}
                                disabled={isLoading}
                            >
                                Limpiar Filtro
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <MapBase
                        mapContainerStyle={{
                            width: '100%',
                            height: 600
                        }}
                        isSearchable={false}
                        coordinates={coordenadas}
                        options={{
                            maxZoom: 23,
                            minZoom: 8,
                        }}
                        zoom={zoom}
                    >
                    { dataFiltro?.map((it) => (
                            <MarkerTratos item={it} key={it.id_t} zoom={zoom} />
                    ))}
                    </MapBase>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TratosCameraMapPage)