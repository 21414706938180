import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LugarService from '@app/services/lugares.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalLugares from '@app/modals/configuraciones/ModalLugares';
import {Button, ModalConfirm} from '@app/components/index';
import { useNavigate } from 'react-router-dom';


const LugaresPage = ({
    user
}) => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const actualizar = async () => {
        const response = await LugarService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar el Lugar',
            message: '¿Esta seguro que desea eliminar el Lugar?',
            onConfirm: () => {
                LugarService.destroy(item.id_l).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalLugares, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre_l'
            },
            {
                Header: 'Latitud',
                accessor: 'latitud_l'
            },
            {
                Header: 'Longitud',
                accessor: 'longitud_l'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        if(user){
            if(user && user.id_p < 9999){
                actualizar();
            }else{
                navigate('/')
            }
        }
    }, [user]);

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 col-lg-6 my-lg-3">
                        <h3>Lugares</h3>
                    </div>
                    <div className="offset-2 col-4 offset-lg-4 col-lg-2 my-lg-3">
                        <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                            NUEVO
                        </Button>
                    </div>
                    <div className="col-12 col-lg-12">
                           <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LugaresPage);
