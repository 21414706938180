import React, {useContext, useState} from 'react'
import styled from 'styled-components';
import { Card, Collapse } from 'react-bootstrap';
import FechaFunction from '@app/functions/FechaFunction';
import { InputReadQuill, ModalConfirm } from '@app/components/index';
import { PhotoConsumer } from 'react-photo-view';
import ReactPlayer from 'react-player'
import {showModal} from 'react-redux-modal-provider';
import Button from '@app/../node_modules/react-bootstrap/esm/Button';
import comentarioTicketService from '@app/services/comentarioTicket.service';
import { saveAs } from 'file-saver';
import { SocketContext } from '../../context/SocketContext';

const Styles = styled.div`

`;

function ComentarioTicketCard({
    item,
    user,
    estado,
    onGuardar,
    autorizado,
    cvv
}) {
    const token = localStorage.getItem('token');
    const { sockets } = useContext( SocketContext );
    const esCreador = item?.comCreadoPor?.dni_p === user?.dni_p || item?.createdPersonaId_com === user?.id_p;
    const comPrivado = item?.isVisible_com;
    const [visible, setVisible] = useState(false);

    const onComentarioVisible = () => {
        showModal(ModalConfirm, {
            title: 'Volver el Comentario Visible',
            message: '¿Esta seguro que desea volver visible el comentario?',
            onConfirm: () => {
                comentarioTicketService.update({
                    id: item.id_com,
                    type: 'cvv'
                }).then(()=>{
                    onGuardar();
                    sockets?.emit( 'ticketRefresh', `cvv${item.ticketId_com}` );
                })
            }
        });
    };

    return (
        <div className="row">
            <Card className={`tktCard ${esCreador ? 'comentarioPropio col-11 col-md-11 col-lg-11' : 'comentarioExterno col-10 col-md-11 col-lg-11 order-1'} ${!comPrivado && 'comPrivado'}`}>
                <InputReadQuill
                    id="cuerpo_tk"
                    template={item.cuerpo_com}
                />
                <footer className="blockquote-footer text-right">
                    {item && !item?.isVisible_com && cvv &&('Comentario Interno - ')}
                    {`${item?.comCreadoPor?.nombre_p || item?.nombrePersonal_com  } - ${FechaFunction.format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}`}
                    { !item?.isVisible_com && !estado && autorizado && cvv /* && autorizar */ && (
                        <Button
                            className="text-blue"
                            variant="light"
                            onClick={() =>onComentarioVisible()}
                        >
                            <i className="fas fa-edit "></i>
                        </Button>
                    )}
                </footer>
                    <div className="row">
                        {
                            item?.img?.map((img)=>{
                                const tipe = img.path_ic.split('.');
                                if(tipe[1] === 'jpg' || tipe[1] === 'png' || tipe[1] === 'jpeg' || tipe[1] === 'svg' || tipe[1] === 'x-png'){
                                    return (
                                        <div className='col-lg-3 col-md-12 mb-2'>
                                            <div className="d-flex justify-content-center">
                                                <PhotoConsumer 
                                                    key={`IMG${img.path_ic}`}
                                                    src={`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`}
                                                    intro={img.path_ic}
                                                >
                                                        <img 
                                                            src={`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`}
                                                            alt={`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`}
                                                            style={{width:60,height:60, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 85%)'}}
                                                        />
                                                    
                                                </PhotoConsumer>
                                            </div>
                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </div>
                                        </div>
                                    )
                                }
                                if(tipe[1] === 'wmv' || tipe[1] === 'mp4' || tipe[1] === 'avi' || tipe[1] === 'mov'){  
                                    return( 
                                        <div className='col-lg-3 col-md-12 mb-2'>
                                            <div className="d-flex justify-content-center">
                                                <ReactPlayer
                                                    key={`Video${img.path_ic}`}
                                                    url={`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`}    
                                                    width='155px'
                                                    height='60px'
                                                    controls
                                                />
                                            </div>
                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </div>
                                        </div>
                                    )
                                }
                                if(tipe[1] === 'pdf'){  
                                    return(
                                        <div className='col-lg-3 col-md-12 mb-2'>
                                            <div 
                                                className="d-flex justify-content-center"
                                                aria-hidden="true"
                                                style={{height:55}}
                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`,img.path_ic)}
                                            >
                                                <img
                                                    src='/img/pdf.png'
                                                    alt='pdf.png'
                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                />
                                            </div>
                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </div>
                                        </div>
                                    )
                                }
                                if(tipe[1] === 'doc' || tipe[1] === 'docx'){  
                                    return(
                                        <div className='col-lg-3 col-md-12 mb-2'>
                                            <div
                                                className="d-flex justify-content-center"
                                                aria-hidden="true"
                                                style={{height:60}}
                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`,img.path_ic)}
                                            >
                                                <img 
                                                    src='/img/doc.png'
                                                    alt='doc.png'
                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                />
                                            </div>
                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </div>
                                        </div>
                                    )
                                }
                                if(tipe[1] === 'xls' || tipe[1] === 'xlsx'){
                                    return(
                                        <div className='col-lg-3 col-md-12 mb-2'>
                                            <div 
                                                className="d-flex justify-content-center"
                                                aria-hidden="true"
                                                style={{height:55}}
                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`,img.path_ic)}
                                            >
                                                <img 
                                                    src='/img/xls.png'
                                                    alt='xls.png'
                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                />
                                            </div>
                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </div>
                                        </div>
                                    )
                                }
                                if(tipe[1] === 'txt'){  
                                    return(
                                        <div className='col-lg-3 col-md-12 mb-2'>
                                            <div 
                                                className="d-flex justify-content-center"
                                                aria-hidden="true"
                                                style={{height:55}}
                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=comentarios/${img.path_ic}&token=${token}`,img.path_ic)}
                                            >
                                                <img 
                                                    src='/img/txt.png'
                                                    alt='txt.png'
                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                />
                                            </div>
                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                {img?.nombre_ic}
                                            </div>
                                        </div>
                                    )
                                }
                                return ''
                            })
                        }
                    </div>
            </Card>
            <div className="col-2 col-md-1 col-lg-1 align-self-end">
                <img src="/img/default-profile.png" className="profile-user-img img-fluid img-circle mb-4" alt="user-avatar" />
            </div>
            
        </div>
    )
}

ComentarioTicketCard.defaultProps = {
    canShowMore: false,
    buttons: null,
};

export default ComentarioTicketCard;
