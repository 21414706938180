import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import GrupoService from '@app/services/grupo.service';

const Styles = styled.div``;

const SelectGrupos = ({id, value, onChange, isInvalid, isClearable}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        GrupoService.getAll().then((response) => {
            setItems(response);
        })
    }, []);

    useEffect(() => {
        const newOptions = items.map((item) => {
            return {
                value: item.id_g,
                label: item.nombre_g
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.nombre_g,value:va.id_g})));
        }
    }, [items,value])
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectGrupos.defaultProps = {
    isClearable: false
};

export default SelectGrupos;
