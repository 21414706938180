import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

const TextMark = ({texto = '', textMark = ''}) => {
    console.log(textMark)
    const [textArrSearch, setTextArrSearch] = useState([]);
    useEffect(() => {
        if(textMark){
            const separador = textMark.split(' ')
            setTextArrSearch(separador)
        }
    }, [textMark])
    if (!textMark.trim()) return <span>{texto}</span>;
    return (
        <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={textArrSearch}
            autoEscape
            textToHighlight={texto}
        />
    )
}

export default TextMark
