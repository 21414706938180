import axios from 'axios';
const base = 'searchv2';
function searchAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

const SearchService = {
    searchAll
};
export default SearchService;
