import axios from 'axios';
const base = 'tratos';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function getById(id,query = null) {
    return axios.get([base, id].join('/'),{
        headers: query
    });
}

function update(data) {
    return axios.put([base, data.id_t].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

function getByIdCuenta(id) {
    return axios.get([base, id].join('/cuenta/'));
}

const TratoService = {
    getAll,
    register,
    getById,
    update,
    destroy,
    getByIdCuenta
};
export default TratoService;