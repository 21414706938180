import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm} from '@app/components/index';
import UserService from '@app/services/personas.service';
import GrupoService from '@app/services/grupo.service';
import ModalCanalPrivado from '@app/modals/canalSocket/ModalCanalPrivado';
import ModalGrupoCanal from '@app/modals/canalSocket/ModalGrupoCanal';
import { useNavigate } from 'react-router-dom';
import {connect} from 'react-redux';


const GruposPage = ({
    user
}) => {
    const [t] = useTranslation();
    const [itemsCP, setItemsCP] = useState([]);
    const navigate = useNavigate();
    const [itemsG, setItemsG] = useState([]);
    const datosCanalPriv = async () => {
        UserService.getAll({type : 'select'}).then((response) => {
            setItemsCP(response);
        })
    };
    const datosGrupos = async () => {
        GrupoService.getAll().then((r)=>{
            setItemsG(r)
        })
    };
    const onGuardarCP = () => {
        datosCanalPriv();
    };
    const onGuardarG = () => {
        datosGrupos();
    };
    const onEliminarCP = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Canal Privado',
            message: `¿Esta seguro que desea eliminar el Canal Privado de ${item.nombre_p} ?`,
            onConfirm: () => {
                UserService.cpBaja(item.id_p).then(() => {
                    datosCanalPriv();
                });
            }
        });
    };
    const onEliminarG = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Grupo',
            message: `¿Esta seguro que desea eliminar el Grupo ${item.nombre_g} ?`,
            onConfirm: () => {
                GrupoService.destroy(item.id_g).then(() => {
                    datosGrupos();
                });
            }
        });
    };

    const onNewCP = () => {
        showModal(ModalCanalPrivado, {onGuardar: onGuardarCP});
    };
    const onOpenEditarG = (item) => {
        showModal(ModalGrupoCanal, {item:item, onGuardar: onGuardarG});
    };
    

    const columnsCanalPriv = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre_p'
            },
            {
                Header: 'DNI',
                accessor: 'dni_p'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Button
                            theme="danger"
                            icon='fa fa-trash-alt'
                            onClick={() => onEliminarCP(row.original)}
                        >
                        </Button>
                    );
                }
            }
        ],
        []
    );
    const columnsGrupo = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre_g'
            },
            {
                Header: 'Integrantes',
                accessor: 'personal'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditarG(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminarG(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );
    useEffect(() => {
        if(user){
            if(user && user.id_p < 9999){
                datosCanalPriv();
                datosGrupos();
            }else{
                navigate('/')
            }
        }
    }, [user]);

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 m-2">
                        <div className="row">
                            <h3 className="col">Canal Privado</h3>
                            <Button color="primary" onClick={() => onNewCP()}>
                                NUEVO
                            </Button>
                            <TablePaginated columns={columnsCanalPriv} data={itemsCP} />
                        </div>
                    </div>
                    <div className="col-lg-6 m-2">
                        <div className="row">
                            <h3 className="col">Grupos</h3>
                            <Button color="primary" onClick={() => onOpenEditarG()}>
                                NUEVO
                            </Button>
                            <TablePaginated columns={columnsGrupo} data={itemsG} />
                        </div>
                    </div>
                </div>
            </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GruposPage);
