import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import axios from 'axios';
const base = 'contactoxempresa';

function register(data) {
    return axios.post(base, data);
}

function select(query=null,cancel=null){
    const params = AuxiliarFunction.formatQueryWithSearch(query);
    return axios.get('contactoxempresa/select',{
        params: params,
        cancelToken: cancel,
    });
}
const ContactosXempresaService = {
    register: register,
    select: select,
};
export default ContactosXempresaService;