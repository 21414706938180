import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import TratoService from '@app/services/tratos.service';
import { connect, useSelector } from 'react-redux';

const Styles = styled.div``;

const SelectTrato = ({id, value, onChange, isInvalid, isClearable, disabled, camera,filtroEsp, ...defaultProps}) => {
    const tratoscam = useSelector(state => state.default.tratoscam);
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id_t === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id_t === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };

    useEffect(() => {
        const type = 'multi';
        if(camera){
            setItems(tratoscam)
        }
    }, [tratoscam]);

    useEffect(() => {
        let newOptions = items?.map((item) => {
            return {
                value: item.id_t,
                label: item.direccion_t ? `${item.nombre_t} - ${item.direccion_t}` : item.nombre_t,
                lat: item.latitud_t,
                lng: item.longitud_t,
                isCapital: item?.isCapital_t
            };
        });
        if(filtroEsp && filtroEsp?.length > 0){
            filtroEsp.forEach((e)=>{
                if(e !== value){
                    const filtro = newOptions.filter((nOp)=>nOp.value !== e)
                    newOptions = filtro
                }
            })
        }
        setOptions(newOptions);
    }, [items,filtroEsp,value])
    useEffect(() => {
        if( options.length > 0 ){
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    options.find((opt) => opt.value === Number(value))
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>{
                    if(va?.nombre_t){
                        return {label:`${va?.nombre_t} - ${va?.direccion_t}`,value:va?.id_t}
                    }
                    if(typeof va === 'number'){
                        const buscar = options.find((opt) => opt.value === Number(va))
                        return {label:buscar?.label,value:buscar?.value}
                    }
                    return null
                }));
            }
        }
    }, [options])

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                isDisabled={disabled}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectTrato.defaultProps = {
    isClearable: false
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTrato)