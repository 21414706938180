import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, InputEditQuill, ModalConfirm} from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import { Card } from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import ModalEspSelectCamara from '@app/modals/ticket/ModalEspSelectCamara';
import TicketService from '@app/services/tickets.service';


function ModalEspNubicomCreated({show, hideModal, item, onGuardar,hideButton}) {
    const handleClose = () => hideModal();
    const [arrCamaras, setArrCamaras] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [comentario, setComentario] = useState('')
    const [titulo, setTitulo] = useState('')
    const onOpenCamarasImp = () =>{
        showModal(ModalEspSelectCamara, {item: item, onGuardar: onGuardar, setArrCamaras:setArrCamaras, arrCamaras:arrCamaras});
    }
    const onEliminarCam = (id) =>{
        showModal(ModalConfirm, {
            title: 'Se eliminara la camara',
            message: '¿Esta seguro que desea eliminar la camara seleccionada?',
            onConfirm: () => {
                const filterCam = arrCamaras.filter((arcam)=> arcam.id !== id)
                setArrCamaras(filterCam)
            }
        });
    }
    const onOpenCameraEdit = (itemCam) =>{
        showModal(ModalEspSelectCamara, {item: item, itemCam: itemCam, setArrCamaras:setArrCamaras, arrCamaras:arrCamaras});
    }
    const savePreTk = () =>{
        handleClose()
        onGuardar()
    }

    const onSubmit = async() =>{
        if(arrCamaras?.length > 0){
            const form = {
                titulo_tk: titulo,
                cuerpo_tk: comentario,
            }
            await Promise.all(arrCamaras.map(async(cam) => {
                form.fechavencimiento_tk = cam.fechavencimiento_tk
                form.fecHsVencimiento_tk = cam.fecHsVencimiento_tk
                form.motivo_tk = cam.motivo_tk
                form.prioridad_tk = cam.prioridad_tk
                form.tratoId_txt = cam.tratoId_txt
                await TicketService.registerEsp(form)
            })).then(()=>{
                setIsLoading(false)
                handleClose()
            }).catch(()=>{
                setIsLoading(false)
            })
        }
    }
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nuevo Ticket
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white'>
                <div className="col-12 row">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label className="label-control" htmlFor="titulo_tk">
                                Titulo
                            </label>
                            <input
                                type="text"
                                className='form-control'
                                id="titulo_tk"
                                name="titulo_tk"
                                autoComplete="off"
                                onChange={(e)=>setTitulo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="form-group">
                            <label className="label-control" htmlFor="cuerpo_tk">
                                Descripcion
                            </label>
                            <InputEditQuill
                                id="cuerpo_tk"
                                template=''
                                onChangeText={(html) => setComentario(html)}
                            />
                        </div>
                    </div>
                </div>
                    <div className='col-12 row'>
                        <div className='col-lg-7 col-md-12'>
                            <h3 className='text-right mt-1'>CAMARAS IMPLICADAS</h3>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <Button onClick={()=>onOpenCamarasImp()}>
                                Agregar Camaras
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 row mb-5'>
                        {arrCamaras.length > 0 ? (
                            arrCamaras.map((arCam)=>(
                                <div className='col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Body>
                                            <div className='col-12 text-center' style={{marginBottom: -15}}>
                                                <b className='text-center'>{arCam?.tratoData?.label}</b>
                                            </div>
                                            <br />
                                            <b>Motivo: </b>
                                            {arCam?.motivoName}
                                            <br />
                                            <b>Prioridad: </b>
                                            {arCam?.prioridad_tk}
                                            <br />
                                            {arCam?.fecHsVencimiento_tk &&(
                                                <>
                                                    <b>Vencimiento: </b>
                                                    {FechaFunction.format(arCam?.fecHsVencimiento_tk,'dd-MM-yyyy HH:mm:ss')}
                                                </>
                                            )}
                                            <div className='d-flex justify-content-center '>
                                                <Button
                                                    icon='fas fa-edit fa-1x'
                                                    theme="success"
                                                    onClick={()=>onOpenCameraEdit(arCam)}
                                                >
                                                    Editar
                                                </Button>
                                                <Button
                                                    icon='fas fa-trash-alt fa-1x'
                                                    theme="primary"
                                                    onClick={()=>onEliminarCam(arCam.id)}
                                                >
                                                    Eliminar
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))
                        ):(
                            <div className='alert alert-light mt-4 text-center col-12'>
                                <div className='mt-2 mb-2' style={{fontSize: 20, color: 'gray'}}>
                                    No selecciono ninguna camara
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        theme='default'
                        size='xl'
                        onClick={handleClose}
                        style={{width: 100}}
                        isLoading={isLoading}
                    >
                        Cerrar
                    </Button>
                    <Button
                        size='xl'
                        onClick={()=>onSubmit()}
                        style={{width: 100}}
                        disabled={(!arrCamaras || arrCamaras?.length === 0) || comentario?.length < 17 || titulo?.length < 5}
                        isLoading={isLoading}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}

ModalEspNubicomCreated.propTypes = {
    show: PropTypes.bool,
};

ModalEspNubicomCreated.defaultProps = {
    show: false,
};

export default ModalEspNubicomCreated;