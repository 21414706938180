import axios from 'axios';

const base = 'contactos';
function getAll(query=null){
    return axios.get(base,{
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}
function getById(id,query = null) {
    return axios.get([base, id].join('/'),{
        headers: query
    });
}
function update(data) {
    return axios.put([base, data.id_c].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

function registerFoto(data) {
    const form = new FormData();
    if(data.id_c){
        form.append('id_c',data.id_c);
    }
    if(data.cuenta){
        form.append('cuenta',data.cuenta);
    }
    if(Array.isArray(data.dniFrente) && data.dniFrente.length>0){
        form.append('dniFrente',data.dniFrente[0]);
    } else {
        form.append('dniFrente',data.dniFrente);
    }
    if(Array.isArray(data.dniDorso) && data.dniDorso.length>0){
        form.append('dniDorso',data.dniDorso[0]);
    } else {
        form.append('dniDorso',data.dniDorso);
    }
    return axios.post('contactos/foto', form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getImg(query = null) {
    return axios.get('img', {
        params: query
    });
}

function getByIdCuenta(id,query = null) {
    return axios.get([base, id].join('/cuenta/'),{
        headers: query
    });
}
function updateCuenta(data) {
    return axios.put([base, data.cuenta].join('/cuenta/'), data);
}

function registerCuenta(data) {
    return axios.post('contactos/cuenta', data);
}

function registerCuentaExistente(data) {
    return axios.post('contactos/contrato', data);
}

const ContactosService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy,
    registerFoto: registerFoto,
    getImg: getImg,
    getByIdCuenta: getByIdCuenta,
    updateCuenta: updateCuenta,
    registerCuenta: registerCuenta,
    registerCuentaExistente:registerCuentaExistente
};
export default ContactosService;