import React, { createContext } from 'react';
import useSocket  from '../hooks/useSocket';


export const SocketContext = createContext();


const SocketProvider = ({ children }) => {
                                /* LOCAL SV */
   /* const { sockets, online } = useSocket('https://911.nubicom.com.ar:8000/'); */

                                /* LOCAL SV */
const { sockets, online } = useSocket('https://911.nubicom.com.ar:8000/');
    
                                    /* HEROKU */
 /*    const { sockets, online } = useSocket('https://delatorre-desk.herokuapp.com/'); */

    return (
        <SocketContext.Provider value={{ sockets, online }}>
            { children }
        </SocketContext.Provider>
    )
}
export default SocketProvider;
