import React from 'react';
import {Navigate} from 'react-router-dom';
import {connect} from 'react-redux';

const PrivateRoute = ({children, isLoggedIn}) => {

    return isLoggedIn
        ? children
        : <Navigate to="/login" />
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PrivateRoute);
