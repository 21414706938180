import React, {useRef, useEffect, useState, useContext} from 'react';
import {connect,useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {showModal} from 'react-redux-modal-provider';
import { useNavigate } from 'react-router-dom';
import { actualizarLugares,actualizarMotivoTKCVV,actualizarTratosCam, actualizarComentarioNotify,actualizarTicketNotify } from '@app/store/slices/default.slice';
import Push from 'push.js'
import usePermisos from '@app/hooks/usePermisos';
import { finishTicketExternaReload, finishTicketGroupReload, finishTicketIndividualReload, finishTicketPendienteReload, finishTicketReload, startTicketExternaReload, startTicketGroupReload, startTicketIndividualReload, startTicketPendienteReload, startTicketReload } from '@app/store/actions/ticket';
import ModalCambiarContrasena from '@app/modals/login/ModalCambiarContrasena';
import { SocketContext } from '../../../../context/SocketContext';
import * as ActionTypes from '../../../../store/actions/auth';


const UserDropdown = ({user, onUserLogout}) => {
    const permisos = usePermisos();
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [dropdownState, updateDropdownState] = useState({
        isDropdownOpen: false
    });
    const { sockets } = useContext( SocketContext );
    const toggleDropdown = () => {
        updateDropdownState({isDropdownOpen: !dropdownState.isDropdownOpen});
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            updateDropdownState({isDropdownOpen: false});
        }
    };

    const logOut = (event) => {
        toggleDropdown();
        onUserLogout();
    };
    const onOpenModalChangePass = () => {
        toggleDropdown();
        showModal(ModalCambiarContrasena,{user:user, onUserLogout:onUserLogout })
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, false);
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutside,
                false
            );
        };
    });

    let className = 'dropdown-menu dropdown-menu-lg dropdown-menu-right rounded-lg';

    if (dropdownState.isDropdownOpen) {
        className += ' show';
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token === '' || !token || token === undefined || token === 'undefined' || token === 'null'){
            logOut();
        }
    }, [])
    useEffect(() => {
        if(user.dni_p){
            const idUser = user?.id_p.toString();
            const canalUser = user?.dni_p.toString();
            const canalUserReload = `${user?.dni_p.toString()}R`;

            sockets?.on( canalUser , () => {
                Push.create("Te Asignaron un Ticket!", {
                    body: "Se te asigno un ticket Individual.",
                    icon: '/img/911.png',
                    timeout: 6000,
                    onClick: () => {
                        navigate('/tickets')
                    }
                });
                toast.info('Te Asignaron un Ticket!', {
                    position: "top-right",
                    autoClose: 4000,
                    pauseOnHover: false,
                });
            })
            sockets.on( canalUserReload , () => {
                dispatch(startTicketIndividualReload());
                dispatch(finishTicketIndividualReload());
            })
            sockets.on( 'ticketRefresh' , (idSocket) => {
                const ticketId = localStorage.getItem('ticketId');
                if(ticketId === idSocket){
                    dispatch(startTicketReload());
                    dispatch(finishTicketReload());
                }
            })
            dispatch(actualizarTicketNotify());
            dispatch(actualizarComentarioNotify());

            sockets?.on( `ticketnotify${idUser}` , () => {
                dispatch(actualizarTicketNotify())
            })
            sockets?.on( `comentarionotify${idUser}` , () => {
                dispatch(actualizarComentarioNotify())
            })
        }
        if(user.canalGrupoId){
            const canalGrupo = user?.canalGrupoId;
            const canalGrupoReload = `${user?.canalGrupoId}R`;
            sockets?.on( canalGrupo , () => {
                Push.create("Te Asignaron un Ticket!", {
                    body: "Nuevo Ticket en el Grupo.",
                    icon: '/img/911.png',
                    closeOnClick: true,
                    pauseOnHover: false,
                    timeout: 6000,
                    onClick: () => {
                        navigate('/tickets')
                    }
                });
                toast.info('Nuevo Ticket al Grupo!', {
                    position: "top-right",
                    autoClose: 6000,
                    closeOnClick: true,
                    pauseOnHover: false,
                    onClick: () => {
                        navigate('/tickets')
                    }
                });
            })
            sockets.on( canalGrupoReload , () => {
                dispatch(startTicketGroupReload());
                dispatch(finishTicketGroupReload());
            })
        }
        if(user.dni_p === 38034824 || user.dni_p === 33235977){
            sockets?.on( 'newComment' , (nro) => {
                const ticketId = nro?.replace('cvv','')
                Push.create("Nuevo Comentario", {
                    body: `Nuevo Comentario en el Ticket ${ticketId}.`,
                    icon: '/img/911.png',
                    timeout: 6000,
                    onClick: () => {
                        navigate(`/tickets/${ticketId}/ver`)
                    }
                })
            })
        }

    }, [user]);

    useEffect(() => {

        if(permisos['lugar.getAll']){
            dispatch(actualizarLugares())
            sockets.on( 'newLugar' , () => {
                dispatch(actualizarLugares())
            })
        }

        if(permisos['trato.getAll911']){
            dispatch(actualizarTratosCam())
            dispatch(actualizarMotivoTKCVV())
            sockets.on( 'newTrato' , () => {
                dispatch(actualizarTratosCam())
            })
            sockets.on( 'newMotivoTKCvv' , () => {
                dispatch(actualizarMotivoTKCVV())
            })
            sockets.on( 'tkExterna' , () => {
                dispatch(startTicketExternaReload());
                dispatch(finishTicketExternaReload());
            })
        }
        if(permisos['ticket.update911']){
            sockets.on( 'tkPendiente' , () => {
                dispatch(startTicketPendienteReload());
                dispatch(finishTicketPendienteReload());
            })
        }
    }, [permisos])

    return (
        <li ref={dropdownRef} className="nav-item dropdown user-menu">
            <button
                onClick={toggleDropdown}
                type="button"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
            >
                <img
                    src={(user && user?.foto_p ? user?.foto_p : '/img/default-profile.png')}
                    className="user-image bg-gray-light"
                    alt="User"
                    style={{
                        boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 35%)'
                    }}
                />
            </button>
            <ul className={className}>
                <li className="user-header">
                    <img
                        src={(user && user?.foto_p ? user?.foto_p : '/img/default-profile.png')}
                        className="img-circle bg-gray-light"
                        alt="User"
                        style={{
                            boxShadow: '0px 0px 7px 2px rgb(0 0 0 / 20%)'
                        }}
                    />
                    <p>
                        {user?.nombre_p}
                        <small>{user?.razonSocial_p}</small>
                        <small>{user?.email_p}</small>
                    </p>
                </li>
                <li className="border-top rounded-lg">
                    { user && user?.razonSocial_p === 'Policia de la Provincia de Salta' && (
                        <button
                            type="button"
                            className="btn btn-danger btn-flat float-left"
                            onClick={onOpenModalChangePass}
                        >
                            Cambiar contraseña
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn btn-danger btn-flat float-right"
                        onClick={logOut}
                    >
                        {t('login.button.signOut')}
                    </button>
                </li>
            </ul>
        </li>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
    stack: state.modalProvider.stack,
});

const mapDispatchToProps = (dispatch) => ({
    onUserLogout: () => dispatch(ActionTypes.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
