import React, {useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,SelectUser} from '@app/components/index';
import {toast} from 'react-toastify';
import UserService from '@app/services/personas.service';


function ModalCambiarContrasena({show, hideModal, user, onUserLogout}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [contrasenaActual, setContrasenaActual] = useState('')
    const [contrasenaNew, setContrasenaNew] = useState('')
    const [contrasenaNewR, setContrasenaNewR] = useState('')
    const [mostrarpass, setMostrarpass] = useState(false)
    const [mostrarpassNew, setMostrarpassNew] = useState(false)
    const [mostrarpassNewR, setMostrarpassNewR] = useState(false)

    const onSubmit = () => {
        const form = {
            contrasenaActual,
            contrasenaNew,
            contrasenaNewR
        };
        if(contrasenaNew !== contrasenaNewR){
            toast.error('Las contraseñas no coinciden.', {});
            return false;
        }
        if(contrasenaNew && contrasenaNew?.length <= 5){
            toast.error('La contraseña debe tener mas de 5 caracteres.', {});
            return false;
        }
        setIsLoading(true)
        UserService.changePassUser(form).then(()=>{
            toast.info('Se cambio la contraseña correctamente', {});
            setTimeout(() => {
                handleClose()
                onUserLogout();
            }, 2000);
        }).catch(()=>{
            setIsLoading(false)
        })
        return true;
    };

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Cambiar contraseña
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className="label-control" htmlFor="contrasenaAct">
                    Contraseña Actual
                </label>
                <div className="form-group">
                    <div className="input-group">
                        <input
                            value={contrasenaActual || ''}
                            type={!mostrarpass ? `password` : ``}
                            id='contrasenaAct'
                            name='contrasenaAct'
                            onChange={(e) => setContrasenaActual(e.target.value)}
                            className="form-control"
                            placeholder="Ingrese la contraseña actual"
                        />
                        <div className="input-group-append">
                            <div aria-hidden className="input-group-text" onClick={()=>setMostrarpass(!mostrarpass)}>
                                <span className={mostrarpass ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                            </div>
                        </div>
                    </div>
                </div>
                <label className="label-control" htmlFor="contrasenaNew">
                    Nueva Contraseña
                </label>
                <div className="form-group">
                    <div className="input-group">
                        <input
                            value={contrasenaNew || ''}
                            type={!mostrarpassNew ? `password` : ``}
                            id='contrasenaNew'
                            name='contrasenaNew'
                            onChange={(e) => setContrasenaNew(e.target.value)}
                            className="form-control"
                            placeholder="Ingrese la nueva contraseña"
                        />
                        <div className="input-group-append">
                            <div aria-hidden className="input-group-text" onClick={()=>setMostrarpassNew(!mostrarpassNew)}>
                                <span className={mostrarpassNew ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                            </div>
                        </div>
                    </div>
                </div>
                <label className="label-control" htmlFor="contrasenaNewR">
                    Repita la nueva contraseña
                </label>
                <div className="form-group">
                    <div className="input-group">
                        <input
                            value={contrasenaNewR || ''}
                            type={!mostrarpassNewR ? `password` : ``}
                            id='contrasenaNewR'
                            name='contrasenaNewR'
                            onChange={(e) => setContrasenaNewR(e.target.value)}
                            className="form-control"
                            placeholder="Repita la nueva contraseña"
                        />
                        <div className="input-group-append">
                            <div aria-hidden className="input-group-text" onClick={()=>setMostrarpassNewR(!mostrarpassNewR)}>
                                <span className={mostrarpassNewR ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={!contrasenaActual || !contrasenaNew || !contrasenaNewR}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCambiarContrasena;