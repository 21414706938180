import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, SelectAcciones, SelectUser,} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import AccionesService from '@app/services/acciones.service';


const schema = Yup.object({
    nombre_r: Yup.string().max(200).required(),
});

function ModalRol({show, hideModal, item, onGuardar,Tipo}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [acciones, setAcciones] = useState(item.autorizado?.map(au=>au.accionId_rxa) || []);
    const [personas, setPersonas] = useState(item.users?.map(ur=>ur.id_p) || []);

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema),
    });


    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id_r,
            acciones:acciones,
            personas:personas
        };
        if(item && item.id_r > 0){
            AccionesService.update(form).then((r)=>{
                onGuardar();
                hideModal();
            })
        }else{
            AccionesService.register(form).then((r)=>{
                onGuardar();
                hideModal();
            })
        }
    }
    useEffect(() => {
        if(item.id_r > 0){
            setValue('nombre_r', item.nombre_r)
        }
    }, [item])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_r > 0 ? 'Editar Rol' : 'Registrar Rol'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>   
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_r">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                            id="nombre_r"
                            name="nombre_r"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                            required
                        />
                        {errors.nombre && (
                            <div className="invalid-feedback">
                                {errors.nombre.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Acciones
                        </label>  
                        <SelectAcciones
                            id="id_ac"
                            value={acciones}
                            onChange={(opt) => setAcciones(opt.map(op=>(op.value)))}
                            isMulti
                        />   
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Personal
                        </label>  
                        <SelectUser
                            id="id_p"
                            value={personas}
                            filter='all'
                            onChange={(opt) => setPersonas(opt.map(op=>(op.value)))}
                            isMulti
                        />   
                    </div>
                    
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    disabled={personas.length === 0}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalRol.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string
    })
};

ModalRol.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
    }
};

export default ModalRol;