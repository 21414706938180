import React from 'react'
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';
import { Button } from '@app/components/index';
import { useNavigate } from 'react-router-dom';

const Styles = styled.div``;

function TicketCard({
    item,
    buttons: ButtonComponent,
    hidenronom,
    hideModal,
    notHideModal
}) {
    const navigate = useNavigate();
    const redirec = () =>{
        if(hideModal && !notHideModal){
            hideModal();
        }
        /* navigate(`/tickets/${item?.id_tk}/ver`); */
        window.open(`/tickets/${item?.id_tk}/ver`)
    }
    return (
        <Styles className={`card border redondeado shadow p-3 m-3 estado ${item?.estado_tk}`}>
            <div className="card-title text-truncate">
                <div className="float-right">
                    {
                        ButtonComponent && (
                            <ButtonComponent />
                        )
                    }
                </div>
            </div>
            <div className={`pill pill${item?.estado_tk}`}>
                <span>
                    { item?.estado_tk === 'PORVENCER' && 'POR VENCER'}
                    { item?.estado_tk === 'VENCIDO' && item?.cerrado_tk
                        ? 'CERRADO-VENCIDO'
                        : item?.estado_tk !== 'PORVENCER' && item?.estado_tk
                    }
                </span>
            </div>
            <div className="col-lg-11 col-md-12 card-title text-truncate" style={{color: '#345380'}}>
                { `Ticket N° #${item?.id_tk} -  ${item?.titulo_tk} - ${item?.createdAt ? FechaFunction.format(item.createdAt,'dd/MM/yyyy'): ''}`}
            </div>

            <div className="card-subtitle text-truncate">
                { item && item.trato && (
                    `${item?.trato?.nombre_t}  ${item?.trato?.direccion_t || ''}`
                )}
                { !hidenronom && item?.id_t && (
                    `Contrato #${item?.id_t} - ${item?.nombre_t}`
                )}
                { !hidenronom && item?.cuentaContratoId_txt && (
                    `Contrato Softland #${item?.cuentaContratoId_txt} `
                )}
            </div>
            <div className="row align-self-end">
                <Button
                    className="btn btn-block"
                    style={{fontSize:"0.8em"}}
                    onClick={()=> redirec()}
                >
                    Ir al Ticket
                </Button>
            </div>
        </Styles>
    )
}

export default TicketCard
