import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, SelectLugares} from '@app/components/index';
import {useForm,Controller} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import ContactosService from '@app/services/contactos.service';

const schema = Yup.object({
    nombre_c: Yup.string().max(200).required(),
    lugarId_c: Yup.mixed().required(),
});

function ModalCVVEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue,control} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id_c: item.id_c,
            type: 'cvv'
        };
        setIsLoading(true);
        if (form.id_c > 0) {
            ContactosService.update(form).then(() => {
                onGuardar();
                hideModal();
            })
            .finally(() => {
                setIsLoading(true);
            });
        } else {
            form.isCamera_c = true;
            ContactosService.register(form).then(() => {
                onGuardar();
                hideModal();
            })
            .finally(() => {
                setIsLoading(true);
            });
    }
    };

    useEffect(() => {
        if (item.id_c > 0) {
            setValue('nombre_c', item.nombre_c);
            setValue('lugarId_c', item.lugarId_c);
            setValue('direccion_c', item.direccion_c);
        }
    }, []);

    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_c > 0 ? 'Editar CVV' : 'Registrar CVV'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 col-md-12">
                        <div className="form-group">
                            <label className="label-control" htmlFor="nombre_c">
                                Nombre
                            </label>
                            <input
                                className={`form-control ${errors.nombre_c? 'is-invalid': ''}`}
                                id="nombre_c"
                                name="nombre_c"
                                type="text"
                                autoComplete="off"
                                ref={register} 
                            />
                            {errors.nombre_c && (
                                <div className="invalid-feedback">
                                    {errors.nombre_c.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="form-group">
                            <label className="label-control" htmlFor="lugarId_c">
                                Localidad
                            </label>
                            <Controller
                                name="lugarId_c"
                                control={control}
                                render={({onChange,value},{invalid}) => (
                                    <SelectLugares
                                        id="lugarId_c"
                                        name="lugarId_c"
                                        value={item?.lugarId_c}
                                        isInvalid={invalid}
                                        onChange={(opt)=>onChange(opt.value)}
                                    />
                                )}
                            />
                            {errors.lugarId_c ? (
                                <div className="default-invalid-feedback">
                                    {errors.lugarId_c?.message}
                                </div>
                            ) : null}          
                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="form-group">
                            <label className="label-control" htmlFor="direccion_c">
                                Direccion
                            </label>
                            <input
                                className={`form-control ${errors.direccion_c? 'is-invalid': ''}`}
                                id="direccion_c"
                                name="direccion_c"
                                type="text"
                                autoComplete="off"
                                ref={register} 
                            />
                            {errors.direccion_c && (
                                <div className="invalid-feedback">
                                    {errors.direccion_c.message}
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalCVVEditar.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_c: PropTypes.number,
        nombre_c: PropTypes.string
    })
};

ModalCVVEditar.defaultProps = {
    show: false,
    item: {
        id_c: 0,
        nombre_c: ''
    }
};

export default ModalCVVEditar;