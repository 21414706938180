import FechaFunction from '@app/functions/FechaFunction';
import React from 'react'
import styled from 'styled-components';

const Styles = styled.div`
    width: 65%;
`;

function CuentaProductoCard({
    item,
}) {
    return (
        <Styles className="m-3">
            {item &&(
                <div className="card text-white bg-info mb-3 shadow-sm">
                    <div className="card-header text-center">{`${item?.articulo?.nombre} - ${item?.estado?.descripcion}`}</div>
                    <div className="card-body ml-4">
                        <label className='ml-5'>{`Tipo: ${item?.tipo}`}</label>
                        <label className='ml-5'>{`Precio: ${item?.articulo?.precio} ${item?.articulo?.moneda}`}</label>
                        <label className='ml-5'>{`Cantidad: ${item?.articulo?.cantidad}`}</label>
                        <br />
                        <label className='ml-5'>{`Vigencia: ${item?.articulo?.vigencia === 'S' ? 'SI' : 'NO'}`}</label>
                        {item?.articulo?.vigencia_desde && (
                            <label className='ml-5'>{`Desde: ${FechaFunction.format(item?.articulo?.vigencia_desde,'dd/MM/yyyy')}`}</label>
                        )}
                        {item?.articulo?.vigencia_hasta && (
                            <label className='ml-5'>{`Hasta: ${FechaFunction.format(item?.articulo?.vigencia_hasta,'dd/MM/yyyy')}`}</label>
                        )}
                        <br />
                        <label className='ml-5'>{`Condicion Pago: ${item?.condicion_pago?.descripcion}`}</label>
                        <label className='ml-5'>{`Contratista: ${item?.contratista?.descripcion}`}</label>
                        <br />
                        {item?.estado?.alta && (
                            <label className='ml-5'>{`Fecha de Alta: ${FechaFunction.format(item?.estado?.alta,'dd/MM/yyyy')}`}</label>
                        )}
                        {item?.estado?.modificacion && (
                            <label className='ml-5'>{`Ultima Modificacion: ${FechaFunction.format(item?.estado?.modificacion,'dd/MM/yyyy')}`}</label>
                        )}
                    </div>
                </div>
            )}
        </Styles>
    )
}

CuentaProductoCard.defaultProps = {
    buttons: null,
};

export default CuentaProductoCard
