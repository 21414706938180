import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, DateSingle, Select,SelectMotivoTicket, SelectTrato} from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import {toast} from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
function ModalEspSelectCamara({show, hideModal,setArrCamaras,itemCam,arrCamaras}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [prioridad, setPrioridad] = useState('Nivel 1 (Leves)');
    const [motivo, setMotivo] = useState(null);
    const [motivoName, setMotivoName] = useState('');
    const [tratoId, setTratoId] = useState(null);
    const [tratoData, setTratoData] = useState(null);
    const [fechaVencimiento, setFechaVencimiento] = useState(new Date());
    const [fechaHoraVencimiento, setFechaHoraVencimiento] = useState(new Date());
    const [filtroCamId, setFiltroCamId] = useState(arrCamaras?.length > 0 ? arrCamaras?.map(acc=>acc?.tratoId_txt) : []);

    const cerrarModal = () => hideModal();

    const onSubmit = () => {
        if(motivo && prioridad && tratoId && fechaVencimiento && fechaHoraVencimiento){
            const form = {
                id: uuidv4(),
                motivo_tk: motivo,
                prioridad_tk: prioridad,
                tratoId_txt: tratoId,
                fechavencimiento_tk: fechaVencimiento,
                fecHsVencimiento_tk: fechaHoraVencimiento,
                motivoName,
                tratoData
            };
            setIsLoading(true);
            if(itemCam?.id){
                const filterCams = arrCamaras.filter((arcam)=>arcam.id !== itemCam.id)
                if(filterCams?.length > 0){
                    setArrCamaras([...filterCams,form])
                }else{
                    setArrCamaras([form])
                }
            }else{
                setArrCamaras(prev=>[...prev,form])
            }
            setIsLoading(false);
            cerrarModal()
        }else{
            toast.error('Debe completar los datos.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
    };

    useEffect(() => {
        if(tratoData?.isCapital){
            switch (prioridad) {
                case 'Nivel 1 (Leves)':
                    setFechaVencimiento(FechaFunction.addDaysDate(new Date(),4))
                    setFechaHoraVencimiento(FechaFunction.addDaysDate(new Date(),4,true))
                    break;
                case 'Nivel 2 (Graves)':
                    setFechaVencimiento(FechaFunction.addHoursDate(new Date(),4))
                    setFechaHoraVencimiento(FechaFunction.addHoursDate(new Date(),4,true))
                    break;
                case 'Nivel 3 (Criticos)':
                    setFechaVencimiento(FechaFunction.addHoursDate(new Date(),4))
                    setFechaHoraVencimiento(FechaFunction.addHoursDate(new Date(),4,true))
                    break;
                default:
                    setFechaVencimiento(FechaFunction.format(new Date(),'yyyy-MM-dd'))
                    setFechaHoraVencimiento(new Date())
                    break;
            }
        }else{
            switch (prioridad) {
                case 'Nivel 1 (Leves)':
                    setFechaVencimiento(FechaFunction.addDaysDate(new Date(),4))
                    setFechaHoraVencimiento(FechaFunction.addDaysDate(new Date(),4,true))
                    break;
                case 'Nivel 2 (Graves)':
                    setFechaVencimiento(FechaFunction.addHoursDate(new Date(),4))
                    setFechaHoraVencimiento(FechaFunction.addHoursDate(new Date(),4,true))
                    break;
                case 'Nivel 3 (Criticos)':
                    setFechaVencimiento(FechaFunction.addHoursDate(new Date(),4))
                    setFechaHoraVencimiento(FechaFunction.addHoursDate(new Date(),4,true))
                    break;
                default:
                    setFechaVencimiento(FechaFunction.format(new Date(),'yyyy-MM-dd'))
                    setFechaHoraVencimiento(new Date())
                    break;
            }
        }
    }, [prioridad,tratoData])

    useEffect(() => {
        if(itemCam?.id){
            setPrioridad(itemCam?.prioridad_tk)
            setMotivo(itemCam?.motivo_tk)
            setMotivoName(itemCam?.motivoName)
            setTratoId(itemCam?.tratoId_txt)
            setTratoData(itemCam?.tratoData)
            setFechaVencimiento(itemCam?.fechavencimiento_tk)
            setFechaHoraVencimiento(itemCam?.fecHsVencimiento_tk)
        }
    }, [itemCam])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Prioridad Pre-Ticket
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group">
                        <label
                            className="label-control"
                            htmlFor="motivo_tk"
                        >
                            Camaras
                        </label>
                            <SelectTrato
                                camera
                                id="id_t"
                                value={tratoId}
                                filtroEsp={filtroCamId}
                                onChange={(opt) => {
                                    setTratoData(opt)
                                    setTratoId(opt.value)
                                }}
                            />
                    </div>
                    <div className="form-group">
                        <label
                            className="label-control"
                            htmlFor="motivo_tk"
                        >
                            Motivo
                        </label>
                        <SelectMotivoTicket
                            id="motivo_tk"
                            type='cvv'
                            value={motivo}
                            onChange={(opt)=>{
                                setMotivo(opt.value)
                                setMotivoName(opt.label)
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="Prioridad">
                            Prioridad
                        </label>
                        <Select
                            defaultValue={prioridad}
                            onChange={(opt)=>setPrioridad(opt?.value || null)}
                            options={[
                                {
                                    label: 'Nivel 1 (Leves)',
                                    value: 'Nivel 1 (Leves)',
                                },
                                {
                                    label: 'Nivel 2 (Graves)',
                                    value: 'Nivel 2 (Graves)',
                                },
                                {
                                    label: 'Nivel 3 (Criticos)',
                                    value: 'Nivel 3 (Criticos)',
                                },
                            ]}
                        />
                    </div>
                    {/* <div className="form-group">
                        <label className="label-control" htmlFor="vtoTicket">
                            Fecha de Vencimiento
                        </label>
                        <DateSingle
                            id="vtoTicket"
                            value={fechaVencimiento}
                            err
                            minDate={new Date()}
                            onChange={(value)=>setFechaVencimiento(value)}
                        />
                    </div> */}
                    <div className="form-group text-center">
                        <h6 className="label-control" htmlFor="vtoTicket2">
                            Fecha y Hora de Vencimiento
                        </h6>
                        <h5>
                            {FechaFunction.format(fechaHoraVencimiento,'dd-MM-yyyy HH:mm:ss')}
                        </h5>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={()=>onSubmit()}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalEspSelectCamara.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_can: PropTypes.number,
    })
};

ModalEspSelectCamara.defaultProps = {
    show: false,
    item: {
        id_can: 0,
    }
};

export default ModalEspSelectCamara;