import React, { useEffect, useMemo, useState } from 'react'
import {connect} from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import CustomExternalTicketService from '@app/services/customExternal.service';
import { Button, RangeDate } from '@app/components/index';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Badge from 'react-bootstrap/Badge'
import ModalPreTicketViewCreated from '@app/modals/preTicket/ModalPreTicketViewCreated';
import {showModal} from 'react-redux-modal-provider';
import ModalPreTicketPreview from '@app/modals/preTicket/ModalPreTicketPreview';
import FechaFunction from '@app/functions/FechaFunction';
import ModalLoadingExcel from '@app/modals/notifications/ModalLoadingExcel';
import TablaTicketService from '@app/services/tablaTicket.service';

const TablaTicketScreen = ({user}) => {
    const [items, setItems] = useState([])
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [ticketDate, setTicketDate] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const actualizar = (data) => {
        TablaTicketService.tablaTickets(data).then((c)=>{
            setItems(c)
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Nro',
                accessor: 'id_tk',
                Width: 80,
            },
            {
                Header: 'Fecha',
                accessor: 'createdAt',
                Filter: false,
                Width: 120,
                Cell: ({row}) => FechaFunction.format(new Date(row.original.createdAt), 'dd-MM-yyyy HH:mm:ss')
            },
            {
                Header: 'Camara',
                accessor: 'camara',
                Width: 100,
            },
            {
                Header: 'Nro Incidencia',
                accessor: 'idIncidenciaExt_tk',
                Width: 100,
            },
            {
                Header: 'Titulo',
                accessor: 'titulo_tk'
            },
            {
                Header: 'Estado',
                accessor: 'estado_tk',
                Width: 150,
            },
            {
                Header: 'Tipo',
                accessor: 'estado',
                Width: 150,
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <button
                            type="button"
                            className="btn-circle"
                            style={{background:'#CBC9C9',fontSize:'18px'}}
                            onClick={()=>window.open(`/tickets/${row.original?.id_tk}/ver`)}
                        >
                            <i className="fas fa-share" />
                        </button>
                    );
                }
            }
        ],
        []
    );
    const columnsEspecial = useMemo(
        () => [
            {
                Header: 'Nro',
                accessor: 'id_tk',
                Width: 80,
            },
            {
                Header: 'Fecha',
                accessor: 'createdAt',
                Filter: false,
                Width: 120,
                Cell: ({row}) => FechaFunction.format(new Date(row.original.createdAt), 'dd-MM-yyyy HH:mm:ss')
            },
            {
                Header: 'Camara',
                accessor: 'camara',
                Width: 100,
            },
            {
                Header: 'Nro Incidencia',
                accessor: 'idIncidenciaExt_tk',
                Width: 100,
            },
            {
                Header: 'Titulo',
                accessor: 'titulo_tk'
            },
            {
                Header: 'Estado',
                accessor: 'estado_tk',
                Width: 150,
            },
            {
                Header: 'Tipo',
                accessor: 'estado',
                Width: 150,
            },
            {
                Header: 'Empresa',
                accessor: 'empresa',
                Width: 150,
            },
            {
                Header: 'Asignado',
                accessor: 'asignado',
                Width: 150,
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <button
                            type="button"
                            className="btn-circle"
                            style={{background:'#CBC9C9',fontSize:'18px'}}
                            onClick={()=>window.open(`/tickets/${row.original?.id_tk}/ver`)}
                        >
                            <i className="fas fa-share" />
                        </button>
                    );
                }
            }
        ],
        []
    );

    const solicitarReporteTicket = () =>{
        const form = {
            fecIni: FechaFunction.format(ticketDate.startDate,'yyyy-MM-dd'),
            fecFin: FechaFunction.format(ticketDate.endDate,'yyyy-MM-dd')
        }
        showModal(ModalLoadingExcel,{form: form,tipo: 'ticket'})
    }
    useEffect(() => {
        actualizar()
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className='col-lg-5 col-md-12'>
                                <h2 className="text-gray-dark mt-lg-3 user-select-none">Tabla de tickets</h2>
                            </div>
                            <div className="col-lg-2 col-md-12 mt-lg-4 text-right">
                                <h5 className="text-gray-dark user-select-none">Seleccione la fecha:</h5>
                            </div>
                            <div className="col-lg-2 mt-lg-4">
                                <RangeDate
                                    minDate={new Date('2022-12-02')}
                                    initianDate={ticketDate}
                                    onChange={(e)=>setTicketDate(e)}
                                    isDisabled={isLoading}
                                />
                            </div>
                            <div className="col-lg-2 mt-lg-5 col-md-12">
                                <Button
                                    block
                                    onClick={()=>solicitarReporteTicket()}
                                    isLoading={isLoading}
                                    theme='success'
                                    tooltipText='El rango de fecha sera la informacion solicitada de arriba.'
                                >
                                    <i className="far fa-file-excel mr-1" />
                                    Solicitar Reporte Completo
                                </Button>
                            </div>
                        </div>
                    </div>
                <div className="col-12 col-lg-12">
                    {user && user?.id_p < 100000 ?(
                        <TablePaginated columns={columnsEspecial} data={items} />
                    ):(
                        <TablePaginated columns={columns} data={items} />
                    )}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TablaTicketScreen);