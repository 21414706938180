import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, InputReadQuill, ModalConfirm} from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import { Card } from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import ModalPreTicketPrioridad from '@app/modals/preTicket/ModalPreTicketPrioridad';
import CustomExternalTicketService from '@app/services/customExternal.service';
import ModalCancelarPreTicket from '@app/modals/preTicket/ModalCancelarPreTicket';


function ModalPreTicketPreview({show, hideModal, item, onGuardar,hideButton}) {
    const handleClose = () => hideModal();
    const [arrCamaras, setArrCamaras] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const onOpenCamarasImp = () =>{
        showModal(ModalPreTicketPrioridad, {item: item, onGuardar: onGuardar, setArrCamaras:setArrCamaras, arrCamaras:arrCamaras});
    }
    const onEliminarCam = (id) =>{
        showModal(ModalConfirm, {
            title: 'Se eliminara la camara',
            message: '¿Esta seguro que desea eliminar la camara seleccionada?',
            onConfirm: () => {
                const filterCam = arrCamaras.filter((arcam)=> arcam.id !== id)
                setArrCamaras(filterCam)
            }
        });
    }
    const onOpenCameraEdit = (itemCam) =>{
        showModal(ModalPreTicketPrioridad, {item: item, itemCam: itemCam, setArrCamaras:setArrCamaras, arrCamaras:arrCamaras});
    }
    const savePreTk = () =>{
        handleClose()
        onGuardar()
    }
    const onOpenTicketInvalid = () =>{
        showModal(ModalCancelarPreTicket, {item: item, onGuardar: savePreTk });
    }
    const onSubmit = () =>{
        if(arrCamaras?.length > 0){
            const form = {
                tickets: arrCamaras,
                idPreTicket: item?.id_ptk
            }
            setIsLoading(true)
            CustomExternalTicketService.createdTicketAll(form).then(()=>{
                if(onGuardar){
                    onGuardar();
                }
                setIsLoading(false)
                handleClose()
            }).catch(()=>{
                setIsLoading(false)
            })
        }
    }
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Incidencia #${item?.idIncidencia_ptk} - ${item?.titulo_ptk}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white'>
                    <div className='text-left'> Descripcion </div>
                    <Card>
                        <Card.Header>
                            {
                                item && (
                                    <InputReadQuill
                                        id="cuerpo_tk"
                                        template={item?.cuerpo_ptk}
                                    />
                                )
                            }
                            <div className="blockquote-footer text-right">
                                {item?.fechaCreated_ptk && (
                                    `${FechaFunction.format(new Date(item?.fechaCreated_ptk),'dd/MM/yyyy HH:mm:ss')}`
                                )}
                            </div>
                        </Card.Header>
                    </Card>
                    <div className='col-12 row'>
                        <div className='col-lg-7 col-md-12'>
                            <h3 className='text-right mt-1'>CAMARAS IMPLICADAS</h3>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                            <Button onClick={()=>onOpenCamarasImp()}>
                                Agregar Camaras
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 row mb-5'>
                        {arrCamaras.length > 0 ? (
                            arrCamaras.map((arCam)=>(
                                <div className='col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Body>
                                            <div className='col-12 text-center' style={{marginBottom: -15}}>
                                                <b className='text-center'>{arCam?.tratoData?.label}</b>
                                            </div>
                                            <br />
                                            <b>Motivo: </b>
                                            {arCam?.motivoName}
                                            <br />
                                            <b>Prioridad: </b>
                                            {arCam?.prioridad_tk}
                                            <br />
                                            {arCam?.fechavencimiento_tk &&(
                                                <>
                                                    <b>Vencimiento: </b>
                                                    {FechaFunction.format(arCam?.fechavencimiento_tk,'dd/MM/yyyy') }
                                                </>
                                            )}
                                            <div className='d-flex justify-content-center '>
                                                <Button
                                                    icon='fas fa-edit fa-1x'
                                                    theme="success"
                                                    onClick={()=>onOpenCameraEdit(arCam)}
                                                >
                                                    Editar
                                                </Button>
                                                <Button
                                                    icon='fas fa-trash-alt fa-1x'
                                                    theme="primary"
                                                    onClick={()=>onEliminarCam(arCam.id)}
                                                >
                                                    Eliminar
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))
                        ):(
                            <div className='alert alert-light mt-4 text-center col-12'>
                                <div className='mt-2 mb-2' style={{fontSize: 20, color: 'gray'}}>
                                    No selecciono ninguna camara
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!hideButton && (
                        <Button
                            theme='warning'
                            size='xl'
                            onClick={()=>onOpenTicketInvalid()}
                            isLoading={isLoading}
                        >
                            Ticket Invalido/Mal Cargado
                        </Button>
                    )}
                    <Button
                        theme='default'
                        size='xl'
                        onClick={handleClose}
                        style={{width: 100}}
                        isLoading={isLoading}
                    >
                        Cerrar
                    </Button>
                    <Button
                        size='xl'
                        onClick={()=>onSubmit()}
                        style={{width: 100}}
                        disabled={!arrCamaras || arrCamaras?.length === 0}
                        isLoading={isLoading}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}

ModalPreTicketPreview.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_ptk: PropTypes.number,
    })
};

ModalPreTicketPreview.defaultProps = {
    show: false,
    item: {
        id_ptk: 0,
    }
};

export default ModalPreTicketPreview;