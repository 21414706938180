import React, {useState, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {showModal} from 'react-redux-modal-provider';

import {useWindowWidth} from '@react-hook/window-size';
import LugaresPage from '@app/pages/configuraciones/lugares';
import MotivoTicketCVVPage from '@app/pages/configuraciones/motivoTicketCVV';
import TicketsPage from '@app/pages/tickets/TicketsPage';
import TratosCameraMapPage from '@app/pages/tratos/camaras/TratosCamMap';
import TratosCamPage from '@app/pages/tratos/tratosCam';
import TratoCamaraVerPage from '@app/pages/tratos/camaras/ver/TratoCamaraVerPage';
import CvvCamarasPage from '@app/pages/contactos/cvvcamaras';
import LoadingPage from '@app/pages/loading/loadingPage';
import OperadoresPage from '@app/pages/usuarios/operadoresPage';
import Dashboard from '@pages/Dashboard';
import ContactoVerCVVPage from '@app/pages/contactos/ver/ContactoVerCVV';
import TicketGetByIdCVVPage from '@app/pages/tickets/TicketGetByIdCVVPage';
import RedirectTicket from '@app/pages/loading/RedirectTicket';
import PermisoPage from '@pages/permisos/permisos';
import GruposPage from '@pages/grupos/grupos';
import UserNotifyPage from '@app/pages/configuraciones/userNotify';
import PreTicketScreen from '@app/pages/preticket/PreTicketScreen';
import TablaTicketScreen from '@app/pages/tablas/TablaTicketScreen';

import ModalNotificationsAlls from '@app/modals/notifications/ModalNotificationsAlls';
import usePermisos from '@app/hooks/usePermisos';

import Header from './header/Header';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import * as AuthAction from '../../store/actions/auth';

const Main = ({
    onUserLoad,
    user,
}) => {
    const permisos = usePermisos();
    const [menusidebarState, updateMenusidebarState] = useState({
        isMenuSidebarCollapsed: false
    });
    const ticketNotify = useSelector(state => state.default.ticketNotify);
    const comentarioNotify = useSelector(state => state.default.comentarioNotify);

    const [countNotifi, setCountNotifi] = useState(0);
    useEffect(() => {
        setCountNotifi((ticketNotify?.count || 0) + (comentarioNotify?.count || 0) )
    }, [ticketNotify,comentarioNotify])

    const onlyWidth = useWindowWidth();
    const toggleMenuSidebar = () => {
        updateMenusidebarState({
            isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed
        });
    };

    const onClickMenu = () => {
        if (onlyWidth < 992) {
            toggleMenuSidebar();
        }
    };
    const onOpenModalNotifi = () =>{
        showModal(ModalNotificationsAlls,{ticketNotify: ticketNotify, comentarioNotify: comentarioNotify })
    }
    useEffect(() => {
        onUserLoad();
    }, [onUserLoad]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token === '' || !token || token === undefined || token === 'undefined' || token === 'null'){
            AuthAction.logout()
        }
    }, [])

    return (
        <>
        <div
            className={`sidebar-mini layout-fixed ${
                menusidebarState.isMenuSidebarCollapsed
                    ? 'sidebar-open'
                    : 'sidebar-collapse'
            }`}
            style={{height:'auto'}}
        >
            <div className="wrapper">
                <Header toggleMenuSidebar={toggleMenuSidebar} showNotifi={onOpenModalNotifi} countNotifi={countNotifi} />
                <MenuSidebar toggleMenuSidebar={onClickMenu} />
                <div className="content-wrapper">
                    <div className="pt-1" />
                    <section className="content">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route
                                end
                                path="/lugares"
                                element={<LugaresPage />}
                            />
                            <Route
                                end
                                path="/tickets/:id/ver"
                                element={<TicketGetByIdCVVPage />}
                            />
                            <Route
                                end
                                path="/ticket/motivoscvv"
                                element={<MotivoTicketCVVPage />}
                            />
                            <Route
                                end
                                path="/tickets"
                                element={<TicketsPage />}
                            />
                            <Route
                                end
                                path="/tratosmap"
                                element={<TratosCameraMapPage />}
                            />
                            <Route
                                end
                                path="/cvv/:id/ver"
                                element={<ContactoVerCVVPage />}
                            />
                            <Route
                                end
                                path="/tratoscam"
                                element={<TratosCamPage />}
                            />
                            <Route
                                end
                                path="/tratos/:id/vercam"
                                element={<TratoCamaraVerPage />}
                            />
                            <Route
                                end
                                path="/cvv"
                                element={<CvvCamarasPage />}
                            />
                            <Route
                                end
                                path="/loading"
                                element={<LoadingPage />}
                            />
                            <Route
                                end
                                path="/operadores"
                                element={<OperadoresPage />}
                            />
                            <Route
                                end
                                path="/permisos"
                                element={<PermisoPage />}
                            />
                            <Route
                                end
                                path="/grupos"
                                element={<GruposPage />}
                            />
                            <Route
                                end
                                path="/notificaciones"
                                element={<UserNotifyPage />}
                            />
                            <Route
                                end
                                path="/preticket"
                                element={<PreTicketScreen />}
                            />
                            <Route
                                end
                                path="/tabla/ticket"
                                element={<TablaTicketScreen />}
                            />
                            <Route
                                end
                                path="/load"
                                element={<RedirectTicket />}
                            />
                            <Route path="*" element={<RedirectTicket />} />
                        </Routes>
                    </section>
                </div>
                <div
                    id="sidebar-overlay"
                    role="presentation"
                    onClick={toggleMenuSidebar}
                    onKeyDown={() => {}}
                />
            </div>
        </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    onUserLoad: () => dispatch(AuthAction.userme())
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
