import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {showModal} from 'react-redux-modal-provider';
import { Card, Dropdown } from 'react-bootstrap';
import LugarService from '@app/services/lugares.service';
import {Button, ModalConfirm} from '@app/components/index';
import UserNotifyService from '@app/services/userNotify.service';
import ModalNotifyPerson from '@app/modals/notifications/ModalNotifyPerson';


const UserNotifyPage = ({
    user
}) => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const actualizar = async () => {
        const response = await UserNotifyService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar el Lugar',
            message: '¿Esta seguro que desea eliminar el Lugar?',
            onConfirm: () => {
                LugarService.destroy(item.id_l).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (elemento, personalArr) => {
        showModal(ModalNotifyPerson, {elemento: elemento, personalArr: personalArr, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Destino',
                accessor: 'nombre_l'
            },
            {
                Header: 'Latitud',
                accessor: 'latitud_l'
            },
            {
                Header: 'Longitud',
                accessor: 'longitud_l'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        if(user){
            if(user && user.id_p < 9999){
                actualizar();
            }else{
                navigate('/')
            }
        }
    }, [user]);

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-12 my-lg-3 text-center">
                        <h3>Notificaciones</h3>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <Card className=''>
                            <h4 className='text-center'>Recibir Comentarios</h4>
                            <small style={{fontSize: 15, margin:5}}>Personal:</small>
                            <Card className='m-2 bg-gray'>
                                {items?.personalNameComentario}
                            </Card>
                            <div className='m-2'>
                                <Button color="primary" block className="" onClick={() => onOpenEditar('Comentarios',items?.personalIdComentario)}>
                                    EDITAR
                                </Button>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <Card>
                            <h4 className='text-center'>Recibir Tickets</h4>
                            <small style={{fontSize: 15, margin:5}}>Personal:</small>
                            <Card className='m-2 bg-gray'>
                                {items?.personalNameTicket}
                            </Card>
                            <div className='m-2'>
                                <Button color="primary" block className="" onClick={() => onOpenEditar('Tickets',items?.personalIdTicket)}>
                                    EDITAR
                                </Button>
                            </div>
                        </Card>

                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserNotifyPage);
