import React,{useEffect} from 'react';
import { SelectFaseTrato, SelectLugares } from '@app/components/index'
import * as Yup from 'yup';

export const schema = Yup.object({
    /* nombre_t: Yup.string().required().max(200),
    lugarId_c: Yup.mixed().required(), */
});

function FormTrato({
    isModal,
    errors,
    register,
    setValue,
    watch,
    item,
    trato
}) {
    const lugarId = watch('lugarId_t',item?.lugarId_c || trato?.lugarId_t)
    const faseTrato = watch('faseContrato_t',item?.faseContrato_c || trato?.faseContrato_t)
    useEffect(() => {
        if(item){
            setValue('nombre_t',`${item?.nombre_c} ${item?.apellido_c}`);
            setValue('direccion_t',item.direccion_c);
            setValue('celular_t',item.celular_c);
            setValue('contactoEmail_t',item.email_c);
            setValue('lugarId_t',item.lugarId_c);
        }
    }, [item])
    useEffect(() => {
        if(trato){
            setValue('nombre_t',trato.nombre_t);
            setValue('direccion_t',trato.direccion_t);
            setValue('celular_t',trato.celular_t);
            setValue('contactoEmail_t',trato.contactoEmail_t);
            setValue('lugarId_t',trato.lugarId_t);
        }
    }, [trato])
    
    return (
            <div className="col-12 bg-white my-2 my-md-0 mt-lg-3">
                <div className="row">
                    <div className={`col-12 ${isModal?'col-md-12':'col-md-3'} text-md-left text-gray mb-3`}>
                        <h5>
                            <i className="fas fa-angle-down mr-2"></i>
                            Datos del Contrato
                        </h5>
                        <span>Informacion sobre el contrato y cliente</span>
                    </div>
                    <div className={`col-12 ${isModal?'col-md-12':'offset-md-1 col-md-8'}`}>
                        <div className={`row rounded ${isModal?'':'border-md border shadow-sm'} bg-white  p-2`}>
                            <div className="col-12 col-md-7">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombre_t">
                                        Nombre
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.nombre_t? 'is-invalid': ''}`}
                                        id="nombre_t"
                                        name="nombre_t"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.nombre_t && (
                                        <div className="invalid-feedback">
                                            {errors.nombre_t.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-2 col-lg-2">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="categoria_t"
                                    >
                                        Categoria
                                    </label>
                                    <select className="form-control" ref={register} name="categoria_t" id="categoria_t" required>
                                        <option value="NORMAL">Normal</option>
                                        <option value="VIP">VIP</option>
                                        <option value="PYME">PYME</option>
                                        <option value="GOBIERNO">Gobierno</option>
                                    </select>
                                </div>
                            </div> 
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="lugarId_t">
                                        Localidad
                                    </label>
                                    <SelectLugares
                                        id="lugarId_t"
                                        name="lugarId_t"
                                        value={lugarId}
                                        onChange={(opt)=>setValue('lugarId_t',opt.value)}
                                    />       
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="direccion_t">
                                        Direccion
                                    </label>
                                    <input 
                                        className={`form-control ${errors.direccion_t? 'is-invalid': ''}`}  
                                        id="direccion_t" 
                                        name="direccion_t" 
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.direccion_t && (
                                        <div className="invalid-feedback">
                                            {errors.direccion_t.message}
                                        </div>
                                    )}
                                </div>                        
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="celular_t">
                                        Celular
                                    </label>
                                    <input 
                                        type="number" 
                                        className={`form-control ${errors.celular_t? 'is-invalid': ''}`}   
                                        id="celular_t" 
                                        name="celular_t" 
                                        autoComplete="off"
                                        ref={register}  
                                    />
                                    {errors.celular_t && (
                                        <div className="invalid-feedback">
                                            {errors.celular_t.message}
                                        </div>
                                    )}
                                </div>                        
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="contactoEmail_t">
                                        Email
                                    </label>
                                    <input 
                                        type="contactoEmail_t" 
                                        className={`form-control ${errors.contactoEmail_t? 'is-invalid': ''}`}    
                                        id="contactoEmail_t" 
                                        name="contactoEmail_t" 
                                        autoComplete="off"
                                        ref={register}   
                                    />
                                    {errors.contactoEmail_t && (
                                        <div className="invalid-feedback">
                                            {errors.contactoEmail_t.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="faseContrato_t">
                                        Fase del Contrato
                                    </label>
                                    <SelectFaseTrato
                                        id="faseContrato_t"
                                        name="faseContrato_t"
                                        value={faseTrato}
                                        onChange={(opt)=>setValue('faseContrato_t',opt.value)}
                                    />       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

FormTrato.defaultProps = {
    isModal: false,
    hideTareaForm: false,
};

export default FormTrato;
