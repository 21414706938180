import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, ComentarioNotifyCard, TicketNotifyCard } from '@app/components/index';
import LogService from '@app/services/log.service';


function ModalNotificationComentario({show, hideModal}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false)
    const [pageActual, setPageActual] = useState(0)
    const [pageFinal, setPageFinal] = useState(0)
    const [items, setItems] = useState([])

    const actualizar = () =>{
        const solicitar = pageActual + 1
        setIsLoading(true)
        LogService.getAllComentarioNotify({page:solicitar}).then((resp)=>{
            setItems((e)=>[...e,...resp.content])
            setPageActual(solicitar)
            setPageFinal(resp.totalPages)
            setIsLoading(false)
        }).catch(()=>{
            setIsLoading(false)
        })
    }
    useEffect(() => {
        actualizar()
    }, [])

    return (
        <Modal show={show} backdrop="static" size='lg' onHide={handleClose}>
            <Modal.Header closeButton>
            <div className='col-11 text-center ml-4'>
                <Modal.Title>Notificaciones de Comentarios</Modal.Title>
            </div>
            </Modal.Header>
            <div className='col-12 bg-white'>
                {items?.length > 0 && (
                    <>
                        {items?.map((ment)=>(
                            <div key={ment?.id_ment} className='col-12 m-1'>
                                <ComentarioNotifyCard item={ment} isModal toggleDropdown={()=>handleClose()} />
                                <div className="dropdown-divider mt-1 mb-1" />
                            </div>
                        ))}
                    </>
                )}
                { pageFinal > pageActual && (
                    <div className='d-flex justify-content-center'>
                        <Button
                            block
                            isLoading={isLoading}
                            theme='success'
                            onClick={()=>actualizar()}
                        >
                            Cargar Mas...
                        </Button>
                    </div>
                )}
            </div>
            <Modal.Footer>
                <Button onClick={()=>handleClose()} theme='default'>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalNotificationComentario;
