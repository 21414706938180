import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,FormTratosCam} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import TratoService from '@app/services/tratos.service';

const schema = Yup.object({
    nombre_t: Yup.string().max(255).required(),
    lugarId_t: Yup.mixed().required(),
    contactoId_t: Yup.mixed().required(),
    tipoCam_dcam: Yup.mixed().required(),
    tecnologiaCam_dcam: Yup.mixed().required(),
});

function ModalCamaraEdit({show, hideModal, item,onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue,control, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item,
    });
    
    const onSubmit = (data) => {
        const form = {
            ...data,
            isCamera_t: true,
            tipoMaterial_t: 'CAMARA',
            categoria_t:'GOBIERNO',
            type:'cvv'
        };
        if(item?.id_t > 0){
            form.id_t = item.id_t;
            form.id_dcam = item.id_dcam;
            TratoService.update(form).then(()=>{
                onGuardar();
                hideModal();
            })
            .finally(()=>{
                setIsLoading(false);
            })
        }else{
            if(form.contactoId_t === 1){
                form.isCapital_t = true;
            }
            TratoService.register(form).then(()=>{
                onGuardar();
                hideModal();
            })
            .finally(()=>{
                setIsLoading(false);
            })
        }
    };
    useEffect(() => {
        if(item){
            setValue('latitud_t',item?.latitud_t)
            setValue('longitud_t',item?.longitud_t)
            setValue('lugarId_t',item?.lugarId_t)
        }
    }, [])
    useEffect(() => {
        register('latitud_t');
        register('longitud_t');
        register('lugarId_t');
        register('caosCamara_t');
        register('caosConectividad_t');
        register('tipoCam_dcam');
        register('tecnologiaCam_dcam');
        register('microSD_dcam');
        register('cajaEstancoNubicom_dcam');
        register('soporteNubicom_dcam');
    }, [register])
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_t > 0 ? 'Editar Camara' : 'Registrar Camara'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormTratosCam isModal item={item} control={control} errors={errors} register={register} setValue={setValue} watch={watch} />
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalCamaraEdit.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_t: PropTypes.number,
        nombre_t: PropTypes.string
    })
};

ModalCamaraEdit.defaultProps = {
    show: false,
    item: {
        id_t: 0,
        nombre_t: ''
    }
};

export default ModalCamaraEdit;