import React from 'react'
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`

`;

function LogsTimeCard({
    item,
    keys
}) {
    let texto;
    if(item){

        switch (item?.isDeleted_uxt) {
            case true:
            case 1:
                if(item.tipo_uxt === 'DNI'){
                    texto=`El usuario ${item?.asignadod?.nombre_p || item?.nombre_p} estuvo asignado a el ticket por ${item?.transcurso}.`;
                }else if(item.tipo_uxt === 'GRUPO'){
                    texto=`El grupo ${item?.asignadog?.nombre_g || item?.nombre_g} estuvo asignado a el ticket por ${item?.transcurso}.`;
                }
            break;
            case false:
            case 0:
                if(item.tipo_uxt === 'DNI'){
                    texto=`El usuario ${item?.asignadod?.nombre_p || item?.nombre_p} lleva asignado al ticket por ${item?.transcurso}.`;

                }else if(item.tipo_uxt === 'GRUPO'){
                    texto=`El usuario ${item?.asignadog?.nombre_g || item?.nombre_g} lleva asignado al ticket por ${item?.transcurso}.`;
                }
            break;
            default:
                texto='Se ha producido un error.';
            break;
        }
    }
    return (
        <div className="log-card mt-3" key={keys}>
                <div id="nombre">{texto}</div>
            <footer className="blockquote-footer text-right">
            {item && (
                `${FechaFunction.format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')}`
            )}
            </footer>
        </div>
    )
}

LogsTimeCard.defaultProps = {
    canShowMore:false,
    buttons:null,
};

export default LogsTimeCard;
