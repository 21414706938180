import React from 'react';
import Form from 'react-bootstrap/Form';

function InputCheck({
    label,
    checked,
    value,
    onChange,
    ...defaultProps
}) {
    const onChangeInput = () => {
        onChange(!checked);
    };
    return (
        <>
            {
                label.length>0?(
                    <Form.Group>
                        <Form.Check
                            {...defaultProps}
                            label={label}
                            checked={Boolean(checked)}
                            value={value}
                            type="checkbox"
                            onChange={onChangeInput}
                        />
                    </Form.Group>
                ):(
                    <Form.Check
                        {...defaultProps}
                        checked={Boolean(checked)}
                        type="checkbox"
                        onChange={onChangeInput}
                    />
                )
            }
        </>
    );
}

InputCheck.defaultProps = {
    label:'',
    checked: false,
    onChange: () => {},
};

export default InputCheck;
