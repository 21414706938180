import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import { TicketVistaCard } from '@app/components/index';
import TablaTicketService from '@app/services/tablaTicket.service';

function TabsTicketGrupal({
    filtro,
    ticketrefresh
}) {
    const [tickets, setTickets] = useState([]);
    const [filtroTK, setFiltroTK] = useState([]);

    const actualizar = () =>{
        TablaTicketService.getAll({
            consulta: 'grupo',
            cvv: true
        })
        .then((response)=>{
            setTickets(response);
        })
    }
    useEffect(() => {
        if(filtro === 'TODOS'){
            setFiltroTK(tickets)
        }else{
            setFiltroTK(tickets?.filter((tk) => tk?.ticket?.estado_tk === filtro))
        }
    }, [filtro,tickets])

    useEffect(() => {
        if(ticketrefresh?.reloadingGroup){
            actualizar();
        }
    }, [ticketrefresh])

    useEffect(() => {
        actualizar();
    }, [])

    return (
        <div className="p-3 p-lg-5">
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    {tickets && filtroTK?.length > 0 ? (
                        filtroTK?.map((ticket)=>{
                            return <TicketVistaCard item={ticket?.ticket} tratos={ticket?.tratos} key={`${ticket?.ticket?.id_tk}-${ticket?.ticket?.createdPersonaId_tk}`} className="mb-3" hideClickMap />
                        })
                    ):(
                        <div className='alert alert-light mt-2 text-center'>
                            <i className="fas fa-users" style={{fontSize: 200, color: 'gray'}}></i>
                            <div style={{fontSize: 20, color: 'gray'}}>
                                Aún no se asignaron tickets a tu grupo
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ticketrefresh: state.ticketrefresh
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TabsTicketGrupal)