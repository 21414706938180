import React, {useEffect, useState} from 'react';
import { Marker, InfoWindow,m } from '@react-google-maps/api';

function MarkerTratos({
    item,
    ...defaultProps
}) {
    const [marker, setMarker] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    let newCoord;
    if(item){
        newCoord={
            lat: Number(item?.latitud_t || item?.latitud),
            lng: Number(item?.longitud_t || item?.longitud)
        }
    }
    const onLoadMarker = React.useCallback(function callback(loaded) {
        setMarker(loaded);
    }, []);

    const onLoadInfoWindow = React.useCallback(function callback(loaded) {
        setInfoWindow(loaded);
    }, []);

    const onClickMarker = () => {
        setIsVisible(true);
    }
    let icono;
    if(item && (item?.tipoCam_dcam === "FIJA" || item?.tipo === 'Fija' || item?.tipo === 'fija' || item?.tipo === 'Sw + Fija')){
        if(item?.operatividad === 'OPERATIVO'){
            icono = {
                url:'/img/fija.png',
                scaledSize: {
                    width:45,
                    height:45,
                }
            }
        }else if(item?.operatividad === 'OPERATIVO CON REQUERIMIENTO'){
            icono = {
                url:'/img/fija-amarillo.png',
                scaledSize: {
                    width:45,
                    height:45,
                }
            }
        }else{
            icono = {
                url:'/img/fija-rojo.png',
                scaledSize: {
                    width:45,
                    height:45,
                }
            }
        }
    }else if(item && (item?.tipoCam_dcam === "DOMO" || item?.tipo === 'Domo')){
        if(item?.operatividad === 'OPERATIVO'){
            icono = {
                url:'/img/domo.png',
                scaledSize: {
                    width:45,
                    height:45,
                }
            }
        }else if(item?.operatividad === 'OPERATIVO CON REQUERIMIENTO'){
            icono = {
                url:'/img/domo-amarillo.png',
                scaledSize: {
                    width:45,
                    height:45,
                }
            }
        }else{
            icono = {
                url:'/img/domo-rojo.png',
                scaledSize: {
                    width:45,
                    height:45,
                }
            }
        }
    }else if(item?.operatividad === 'SIN ESTANCO - SIN ENERGIA'){
        icono = {
            url:'/img/caja-estanco.png',
            scaledSize: {
                width:45,
                height:45,
            }
        }
    }

    return (
        <Marker
            {...defaultProps}
            onLoad={onLoadMarker}
            position={newCoord}
            onClick={onClickMarker}
            icon={icono}
        >
            { isVisible && (item?.id_t > 0 || item?.camara) && (
                <InfoWindow
                    className="card"
                    onLoad={onLoadInfoWindow}
                    position={newCoord}
                    onCloseClick={()=>{setIsVisible(false)}}
                >
                    <>
                        <div className="text-center">{`Nro #${item?.nombre_t || item?.camara}`}</div>
                        {item?.operatividad === 'SIN ESTANCO - SIN ENERGIA' &&(
                            <div className="text-center">{item?.operatividad}</div>
                        )}
                        <div className="text-center">{item?.tipoCam_dcam || item?.tipo}</div>
                        <div className="text-center">{item?.tecnologiaCam_dcam || item?.tecnologia}</div>
                        <div className="text-center">{`${item?.latitud_t || item?.latitud},${item?.longitud_t || item?.longitud}`}</div>
                    </>
                </InfoWindow>
            )}
        </Marker>
    );
}

export default MarkerTratos
