import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router';
import { useNavigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import TicketService from '@app/services/tickets.service';
import {Button, InputReadQuill,InputEditQuill, InputDropZone, Carousel,ComentarioTicketCard, ModalConfirm, ButtonBack, LoaderSpinner} from '@app/components/index';
import {Collapse,Card} from 'react-bootstrap';
import FechaFunction from '@app/functions/FechaFunction';
import {showModal} from 'react-redux-modal-provider';
import ModalTicketIdLogs from '@app/modals/ticket/ModalTicketIdLogs';
import ModalAsignarTicket from '@app/modals/ticket/ModalAsignarTicket';
import comentarioTicketService from '@app/services/comentarioTicket.service';
import { PhotoConsumer } from 'react-photo-view';
import ReactPlayer from 'react-player'
import {toast} from 'react-toastify';
import { saveAs } from 'file-saver';
import Countdown from 'react-countdown';
import ModalCierreTicket from '@app/modals/ticket/ModalCierreTicket';
import ModalTicketPrioridad from '@app/modals/ticket/ModalTicketPrioridad';
import ModalComentarioVisible from '@app/modals/ticket/ModalComentarioVisible';
import ModalReaperturaTicket from '@app/modals/ticket/ModalReaperturaTicket';
import Compressor from 'compressorjs';
import ModalLogsRecorrido from '@app/modals/ticket/ModalLogsRecorrido';
import ModalMultiCrearTicket911 from '@app/modals/ticket/ModalMultiCrearTicket911';
import usePermisos from '@app/hooks/usePermisos';
import { SocketContext } from '../../context/SocketContext';

function TicketGetByIdCVVPage({
    user,
    ticketrefresh
}) {
    const {id} = useParams();
    const [trato, setTrato] = useState();
    const [ticket, setTicket] = useState();
    const [datosTrato, setDatosTrato] = useState(false);
    const [comentario, setComentario] = useState('');
    const permisos = usePermisos();
    const [comentarioVisible, setComentarioVisible] = useState(false);
    const [detCam, setDetCam] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [foto, setFoto] = useState(null);
    const [foto2, setFoto2] = useState(null);
    const [foto3, setFoto3] = useState(null);
    const [isCerrado, setIsCerrado] = useState(false);
    const [isAutorizado, setIsAutorizado] = useState(false);
    const navigate = useNavigate();
    const { sockets } = useContext( SocketContext );
    const token = localStorage.getItem('token');
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const acceptInputFile = 'image/jpeg, image/png, image/jpg, image/svg, image/x-png, video/*,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const location = useLocation();
    const {state} = location

    const actualizar = () =>{
        setIsLoadingPage(true)
        TicketService.getById(id,{type:'cvv'}).then((resp)=>{
            /* TicketService.getTareaTask(id).then((respTask)=>{
                console.log(respTask)
            }) */
            setTicket(resp);
            setIsLoadingPage(false)
            if(resp?.tratos?.length > 0){
                setTrato(resp.tratos[0])
            }
        })
        .catch(()=>{
            navigate('/tickets');
        })
    }

    const CerrarTicket = () =>{
        showModal(ModalCierreTicket, {ticketId: id,onGuardar:actualizar});
    }

    const LogsRecorrido = () =>{
        showModal(ModalLogsRecorrido, {ticketId:id, ticketIdOld: ticket.ticketIdOld_tk});
    }

    const onOpenAsignarNubi = (item) => {
        showModal(ModalConfirm, {
            title: 'Asignar el ticket a Nubicom S.R.L',
            message: '¿Esta seguro que desea asignar el ticket a Nubicom S.R.L?',
            onConfirm: () => {
                TicketService.asignar({ticketId_uxt: id, tipo_uxt:'GRUPO',tipoId_uxt: 1, type: 'cvv' }).then(() => {
                    sockets?.emit( 'ticketRefresh', `cvv${id}` );
                    actualizar();
                });
            }
        });
    };
    const onOpenNewTicket = () =>{
        showModal(ModalMultiCrearTicket911, {onGuardar:actualizar, camaraId: detCam.tratoId_dcam, tickerId: id, cameras: true, ticketIdOld: ticket.ticketIdOld_tk});
    }
    const onOpenReapertura = () =>{
        showModal(ModalReaperturaTicket,{onGuardar:actualizar,ticket: ticket})
    }
    const onOpenLogs = () =>{
        showModal(ModalTicketIdLogs, {ticketId: id, ticketIdOld: ticket.ticketIdOld_tk, type:'cvv'});
    }
    const onOpenAsignacion = () => {
        let socketOld = 0;
        if( ticket?.habilitadoTK?.tipo_uxt === 'DNI'){
            socketOld = ticket?.habilitadoTK?.dni_p;
        }else if( ticket?.habilitadoTK?.tipo_uxt === 'GRUPO'){
            socketOld = ticket?.habilitadoTK?.id_g;
        }
        if(ticket?.comentarios?.length > 0){
            showModal(ModalAsignarTicket, {
                ticketId: id,
                onGuardar:actualizar,
                type: 'cvv',
                socketOld: socketOld,
                ultimoComentario: ticket?.comentarios[0]
            });
        }else{
            toast.error('Debe realizar un comentario.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
    }

    const onOpenModalCargarPrioridad = ()=>{
        showModal(ModalTicketPrioridad, {item: ticket, trato: trato, onGuardar: actualizar});
    }

    const onSubmit = () =>{
        const form={
            ticketId_com : id,
            cuerpo_com: comentario
        }
        if(comentario && comentario.length > 16){
            setIsLoading(true);
            form.foto = foto;
            form.foto2 = foto2;
            form.foto3 = foto3;
            if(user){
                if(permisos['comentario.autorizar']){
                    showModal(ModalComentarioVisible, {
                        title: 'Por favor elija el tipo de comentario',
                        onClickConfirmVisible: () => {
                            form.propertys= 'cvv';
                            form.isVisible_com= true;
                            comentarioTicketService.register(form).then(()=>{
                                sockets?.emit( 'ticketRefresh', `cvv${id}`, true );
                                setFoto(null)
                                setFoto2(null)
                                setFoto3(null)
                                setComentario('')
                                setIsLoading(false);
                            })
                            .catch(() => {
                                setIsLoading(false);
                            });
                        },
                        onClickConfirmOculto: () => {
                            form.propertys= 'cvv';
                            form.isVisible_com= false;
                            comentarioTicketService.register(form).then(()=>{
                                sockets?.emit( 'ticketRefresh', `cvv${id}`, true );
                                setFoto(null)
                                setFoto2(null)
                                setFoto3(null)
                                setComentario('')
                                setIsLoading(false);
                            })
                            .catch(() => {
                                setIsLoading(false);
                            });
                        },
                        onClickCloseModal: () =>{
                            setIsLoading(false)
                        }
                    });
                }else{
                    if(user?.razonSocial_p !== 'Policia de la Provincia de Salta'){
                        form.isVisible_com= comentarioVisible;
                        form.propertys= 'cvv';
                    }
                    comentarioTicketService.register(form).then(()=>{
                        sockets?.emit( 'ticketRefresh', `cvv${id}`, true );
                        setFoto(null)
                        setFoto2(null)
                        setFoto3(null)
                        setComentario('')
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                }
            }else{
                setIsLoading(false);
            }
        }else{
            toast.error('El comentario debe tener al menos 10 caractares.', {
                position: "top-right",
                autoClose: 2500,
                closeOnClick: true,
                pauseOnHover: false,
            });
        }
    }
    const onClosePostCierre = async() =>{
        setIsLoading(true);
        showModal(ModalConfirm, {
            title: 'Finalizar asignacion del ticket.',
            message: '¿Esta seguro que desea finalizar la asignacion del ticket?',
            onConfirm: () => {
                TicketService.closePostCierre(id).then(()=>{
                    setIsLoading(false);
                    actualizar()
                }).catch(()=>{
                    setIsLoading(false);
                })
            }
        });
    }

    useEffect(() => {
        if(permisos){
            if(permisos['comentario.register']){
                setComentarioVisible(false)
            }
            if(permisos['comentario.register.visible']){
                setComentarioVisible(true)
            }
            if(permisos && permisos['comentario.autorizar']){
                setIsAutorizado(true)
            }
        }
    }, [permisos])
    useEffect(() => {
        if(trato && trato.tratocam.length > 0){
            setDetCam(trato.tratocam[0])
        }
    }, [trato])

    useEffect(() => {
        actualizar();
        localStorage.setItem('ticketId', `cvv${id}`);
    }, [id])
    useEffect(() => {
        if(ticketrefresh?.reloading){
            actualizar();
        }
    }, [ticketrefresh])

    useEffect(() => {
        if(foto?.length > 0){
            const {name,size} = foto[0];
            if(size > 20000000){
                setFoto(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto(result);
                        }
                    })
                }
            }
        }
    }, [foto])
    useEffect(() => {
        if(foto2?.length > 0){
            const {name,size} = foto2[0];
            if(size > 20000000){
                setFoto2(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto2[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto2(result);
                        }
                    })
                }
            }
        }
    }, [foto2])
    useEffect(() => {
        if(foto3?.length > 0){
            const {name,size} = foto3[0];
            if(size > 20000000){
                setFoto3(null)
            }else{
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(foto3[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setFoto3(result);
                        }
                    })
                }
            }
        }
    }, [foto3])
    useEffect(() => {
        if(ticket && ticket.estado_tk === 'CERRADO'){
            setIsCerrado(true)
        }
    }, [ticket])

    return (
        <>
        {!isLoadingPage ?(
        <section className="content mt-3">
            <div className="container-fluid p-12">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card card-primary card-outline">
                            <ButtonBack
                                state={state}
                            />
                            <div className="card-body box-profile" style={{fontSize:'0.9em'}}>
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src="/img/ticket.jpg"
                                        alt="User profile"
                                    />
                                </div>
                                <h6 className="text-center mt-1" style={{opacity: 0.9}}>
                                    {`Camara #${trato?.nombre_t}`}
                                </h6>
                                <h5 className="text-center"><u>Datos del Ticket</u></h5>
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Estado</b>
                                        <span className="float-right">
                                            {ticket?.estado_tk}
                                        </span>
                                    </li>
                                    {ticket && ticket?.idIncidenciaExt_tk && (
                                        <li className="list-group-item">
                                            <b>Nro de Incidencia</b>
                                            <span className="float-right">
                                                {ticket?.idIncidenciaExt_tk || ''}
                                            </span>
                                        </li>
                                    )}
                                    {ticket && !ticket.isInvalid_tk ?(
                                        <>
                                            { ticket&& ticket?.estado_tk === "CERRADO" && ticket.externa_tk &&(
                                                <li className="list-group-item">
                                                    <b>Situacion Externa</b>
                                                    <b className="float-right" style={{color:'red'}}>
                                                            {ticket?.externa_tk}
                                                    </b>
                                                </li>
                                            )}
                                            {user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                                <>
                                                    {ticket && ticket?.fecHsVencimiento_tk && !ticket?.cerrado_tk && (
                                                        <li className="list-group-item">
                                                            <b>Tiempo Restante</b>
                                                            <span className="float-right">
                                                                <Countdown date={new Date(ticket?.fecHsVencimiento_tk)} onComplete={()=>console.log('1')}>
                                                                    <span>00:00:00:00</span>
                                                                </Countdown>
                                                            </span>
                                                        </li>
                                                    )}
                                                    {ticket && ticket?.fechavencimiento_tk && (
                                                        <li className="list-group-item">
                                                            <b>Fecha Vencimiento</b>
                                                            <span className="float-right">
                                                                {FechaFunction.format(ticket?.fechavencimiento_tk,'dd/MM/yyyy')}
                                                            </span>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                            {ticket && ticket?.cerrado_tk && (ticket.estado_tk === 'CERRADO' || ticket.estado_tk === 'VENCIDO') && (
                                                <li className="list-group-item">
                                                    <b>Fecha Cierre</b>
                                                    <span className="float-right">
                                                        {FechaFunction.format(new Date(ticket?.cerrado_tk),'dd/MM/yyyy HH:mm:ss')}
                                                    </span>
                                                </li>
                                            )}
                                            {user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' && ticket?.prioridad_tk === 'A DEFINIR' &&(
                                                <li className="list-group-item">
                                                    <b>Prioridad</b>
                                                    <span className="float-right">
                                                        {ticket?.prioridad_tk}
                                                    </span>
                                                </li>
                                            )}
                                            {user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' && ticket?.prioridad_tk === 'A DEFINIR' &&(
                                                <Button
                                                    onClick={onOpenModalCargarPrioridad}
                                                >
                                                    Cargar Prioridad
                                                </Button>
                                            )}
                                            { ticket &&( ticket.estado_tk === "ABIERTO" || ticket.estado_tk === "VENCEHOY"
                                            || ticket.estado_tk === "PORVENCER" || ticket.estado_tk === "VENCIDO"
                                            || (ticket.estado_tk === "CERRADO" && user?.razonSocial_p !== 'Policia de la Provincia de Salta') && ticket?.habilitadoTK?.tipo_uxt ) && (
                                                <li className="list-group-item">
                                                    <b>Asignado a:</b>
                                                    <span className="float-right">
                                                        {ticket && ticket.habilitadoTK && (ticket?.habilitadoTK?.tipo_uxt === "DNI") && (
                                                            ticket?.habilitadoTK.nombre_p
                                                        )}
                                                        {ticket && ticket.habilitadoTK && (ticket?.habilitadoTK?.tipo_uxt === "GRUPO") && (
                                                            ticket?.habilitadoTK.nombre_g
                                                        )}
                                                        {ticket && ticket?.asignadoCVV && !ticket.habilitadoTK && !ticket?.cerrado_tk && (ticket.estado_tk === "ABIERTO" || ticket.estado_tk === "VENCEHOY" || ticket.estado_tk === "PORVENCER" || ticket.estado_tk === "VENCIDO") &&(
                                                            'TIC 911'
                                                        )}
                                                        {ticket && !ticket?.asignadoCVV && !ticket.habilitadoTK && !ticket?.cerrado_tk && (ticket.estado_tk === "ABIERTO" || ticket.estado_tk === "VENCEHOY" || ticket.estado_tk === "PORVENCER" || ticket.estado_tk === "VENCIDO") &&(
                                                            'NUBICOM S.R.L'
                                                        )}
                                                    </span>
                                                </li>
                                            )}
                                            { ticket&& ticket?.trancurrido && !ticket?.cerrado_tk &&(
                                                <li className="list-group-item">
                                                    <b>Tiempo Transcurrido</b>
                                                    <span className="float-right">
                                                            {ticket?.trancurrido}
                                                    </span>
                                                </li>
                                            )}
                                            { ticket && !ticket?.trancurrido && ticket?.cerrado_tk &&(
                                                <li className="list-group-item">
                                                    <b>Tiempo Transcurrido</b>
                                                    <span className="float-right">
                                                            {ticket?.timeElapsed_tk?.time}
                                                    </span>
                                                </li>
                                            )}
                                            { ticket && ticket?.motivotk && ticket?.motivotk?.motivo_mt && user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                                <li className="list-group-item">
                                                    <b>Motivo</b>
                                                    <span className="float-right">
                                                            {ticket?.motivotk?.motivo_mt}
                                                    </span>
                                                </li>
                                            )}
                                            { ticket&& ticket?.estado_tk === "CERRADO" && !ticket.externa_tk && user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                                <li className="list-group-item">
                                                    <b>Cerrado por el Contrato</b>
                                                    <span className="float-right">
                                                            {ticket?.contratoNubi_tk}
                                                    </span>
                                                </li>
                                            )}
                                            { detCam && trato?.tipoMaterial_t !== 'CAMARA' &&(
                                                <li className="list-group-item">
                                                    <b>Observaciones</b>
                                                    <span className="float-right">
                                                            {trato?.obs_t}
                                                    </span>
                                                </li>
                                            )}
                                        </>
                                    ):(
                                        <>
                                            {ticket && (ticket.estado_tk === 'CERRADO' || ticket.estado_tk === 'VENCIDO') && ticket?.cerrado_tk &&(
                                                <li className="list-group-item">
                                                    <b>Fecha Cierre</b>
                                                    <span className="float-right">
                                                        {FechaFunction.format(new Date(ticket?.cerrado_tk),'dd/MM/yyyy HH:mm:ss')}
                                                    </span>
                                                </li>
                                            )}
                                            <li className="list-group-item text-center">
                                                <b style={{color:'red'}}>Ticket Invalido / Mal Cargado</b>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                { detCam && trato?.tipoMaterial_t === 'CAMARA' && (
                                    <>
                                    <Button
                                        block
                                        theme='gray'
                                        className="my-1"
                                        onClick={() => setDatosTrato(!datosTrato)}
                                    >
                                        Detalle de la Camara
                                    <i className="fa-chevron-down fas float-right mr-2 mt-1" />
                                    </Button>
                                    <Collapse in={datosTrato}>
                                        <ul className="list-group list-group-unbordered">
                                            {trato?.estadoCamara_t && (
                                                <li className="list-group-item">
                                                    <b>Estado</b>
                                                    <span className="float-right">
                                                        {trato?.estadoCamara_t}
                                                    </span>
                                                </li>
                                            )}
                                            {detCam?.modeloCam_dcam && (
                                                <li className="list-group-item">
                                                    <b>Modelo</b>
                                                    <span className="float-right">
                                                        {detCam?.modeloCam_dcam}
                                                    </span>
                                                </li>
                                            )}
                                            <li className="list-group-item">
                                                <b>Tipo</b>
                                                <span className="float-right">
                                                    {detCam?.tipoCam_dcam}
                                                </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Tecnologia</b>
                                                <span className="float-right">
                                                    {detCam?.tecnologiaCam_dcam}
                                                </span>
                                            </li>
                                            {detCam?.microSD_dcam && (
                                                <li className="list-group-item">
                                                    <b>Micro SD</b>
                                                    <span className="float-right">
                                                        {detCam?.microSD_dcam}
                                                    </span>
                                                </li>
                                            )}
                                            <li className="list-group-item">
                                                <b>Lugar</b>
                                                <span className="float-right">
                                                    {trato?.lugarNombre_t}
                                                </span>
                                            </li>
                                            <li className="list-group-item">
                                                <b>Dirección</b>
                                                <span className="float-right">
                                                    {trato?.direccion_t}
                                                </span>
                                            </li>
                                            {trato?.latitud_t && (
                                                <li className="list-group-item">
                                                    <b>Coordenadas</b>
                                                    <br />
                                                    <span className="float-right">
                                                        {`${trato?.latitud_t}, ${trato?.longitud_t}`}
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    </Collapse>
                                    </>
                                )}
                                { ticket && ticket?.imgtk?.length > 0  &&(
                                    <div className="col-12">
                                        <div className="row">
                                            <div className='col-12 mt-3 mb-2'>
                                                <h5 className="text-center"><u>Archivos Adjuntos</u></h5>
                                            </div>
                                            { ticket?.imgtk?.map((img)=>{
                                                const tipe = img.path_it.split('.');
                                                if(tipe[1] === 'jpg' || tipe[1] === 'png' || tipe[1] === 'jpeg' || tipe[1] === 'svg' || tipe[1] === 'x-png'){
                                                    return (
                                                        <div className='col-6'>
                                                            <div className="d-flex justify-content-center">
                                                                <PhotoConsumer key={`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`} src={`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`} intro={img.path_it}>
                                                                    <img
                                                                        src={`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`}
                                                                        alt={`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`}
                                                                        style={{width:60,height:60, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 85%)'}}
                                                                    />
                                                                </PhotoConsumer>
                                                            </div>
                                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                {img?.nombre_it}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if(tipe[1] === 'wmv' || tipe[1] === 'mp4' || tipe[1] === 'avi' || tipe[1] === 'mov'){
                                                    return(
                                                        <div className='col-6'>
                                                            <div className="d-flex justify-content-center">
                                                                <ReactPlayer
                                                                    key={img.path_it}
                                                                    url={`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`}
                                                                    controls
                                                                    width='180px'
                                                                    height='60px'
                                                                />
                                                            </div>
                                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                {img?.nombre_it}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if(tipe[1] === 'pdf'){
                                                    return(
                                                        <div className='col-6 mb-2'>
                                                            <div
                                                                className="d-flex justify-content-center"
                                                                aria-hidden="true"
                                                                style={{height:55}}
                                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`,img.path_it)}
                                                            >
                                                                <img
                                                                    src='/img/pdf.png'
                                                                    alt='pdf.png'
                                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                                />
                                                            </div>
                                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                {img?.nombre_it}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if(tipe[1] === 'doc' || tipe[1] === 'docx'){
                                                    return(
                                                        <div className='col-6 mb-2'>
                                                            <div
                                                                className="d-flex justify-content-center"
                                                                aria-hidden="true"
                                                                style={{height:60}}
                                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`,img.path_it)}
                                                            >
                                                                <img
                                                                    src='/img/doc.png'
                                                                    alt='doc.png'
                                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                                />
                                                            </div>
                                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                {img?.nombre_it}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if(tipe[1] === 'xls' || tipe[1] === 'xlsx'){
                                                    return(
                                                        <div className='col-6 mb-2'>
                                                            <div
                                                                className="d-flex justify-content-center"
                                                                aria-hidden="true"
                                                                style={{height:55}}
                                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`,img.path_it)}
                                                            >
                                                                <img
                                                                    src='/img/xls.png'
                                                                    alt='xls.png'
                                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                                />
                                                            </div>
                                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                {img?.nombre_it}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if(tipe[1] === 'txt'){
                                                    return(
                                                        <div className='col-6 mb-2'>
                                                            <div
                                                                className="d-flex justify-content-center"
                                                                aria-hidden="true"
                                                                style={{height:55}}
                                                                onClick={()=>saveAs(`https://911.nubicom.com.ar:8000/api/img?url=tickets/${img.path_it}&token=${token}`,img.path_it)}
                                                            >
                                                                <img
                                                                    src='/img/txt.png'
                                                                    alt='txt.png'
                                                                    style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                                />
                                                            </div>
                                                            <div className="text-center" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                {img?.nombre_it}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                return ''
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <Card>
                            <Card.Header>
                                <h3>
                                    {`Ticket #${ticket?.id_tk} -  ${ticket?.titulo_tk}`}
                                    { ((ticket && ticket.habilitadoTK && ticket.habilitadoTK.ticketId_uxt && ticket?.fechavencimiento_tk) || permisos['comentario.autorizar']) &&(
                                        <Button
                                            className="float-right"
                                            onClick={onOpenAsignacion}
                                        >
                                            Asignar Ticket
                                        </Button>
                                    )}
                                    {user && (user?.razonSocial_p === 'Policia de la Provincia de Salta') && (
                                        <Button
                                            className="float-right"
                                            onClick={LogsRecorrido}
                                        >
                                            Historial
                                        </Button>
                                    )}
                                    {user && (user?.razonSocial_p !== 'Policia de la Provincia de Salta') && (
                                        <Button
                                            className="float-right"
                                            onClick={onOpenLogs}
                                        >
                                            Historial
                                        </Button>
                                    )}
                                    { ticket && ticket?.asignadoCVV && !ticket?.habilitadoTK && ticket?.estado_tk !== 'CERRADO'  && (
                                        <Button
                                            className="float-right"
                                            onClick={onOpenAsignarNubi}
                                        >
                                            Asignar a Nubicom S.R.L
                                        </Button>
                                    )}
                                </h3>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                <div className='text-left'>
                                    Descripcion
                                </div>
                                    {
                                        ticket && (
                                            <InputReadQuill
                                                id="cuerpo_tk"
                                                template={ticket?.cuerpo_tk}
                                            />
                                        )
                                    }
                                    <div className="blockquote-footer text-right">
                                        {ticket && (
                                            `${ticket?.createdPersonaName_tk} ${FechaFunction.format(new Date(ticket?.createdAt),'dd/MM/yyyy HH:mm:ss')}`
                                        )}
                                    </div>
                                </Card.Text>
                                    <div>
                                        {user && permisos['ticket.cerrar911']  &&  !ticket?.cerrado_tk && (ticket?.prioridad_tk !== 'A DEFINIR') &&(
                                            <Button
                                                theme="danger"
                                                onClick={CerrarTicket}
                                                className="float-right"
                                            >
                                                Cerrar Ticket
                                            </Button>
                                        )}
                                        {user && permisos['ticketCvv.PostClose']
                                            && ticket?.cerrado_tk && (ticket?.estado_tk === 'CERRADO' || ticket?.estado_tk === 'VENCIDO' )
                                            && ticket?.habilitadoTK?.tipo_uxt
                                        &&(
                                            <Button
                                                theme="danger"
                                                onClick={()=>onClosePostCierre()}
                                                className="float-right"
                                            >
                                                Finalizar Asignacion
                                            </Button>
                                        )}
                                    </div>
                            </Card.Body>
                            <Card.Footer>
                                {ticket && ticket.comentarios.length > 0 ? (
                                    <>
                                        <div className='col-lg-9 col-md-7 mb-3 align-self-center'>
                                            <h4>
                                                Comentarios
                                            </h4>
                                        </div>
                                        {ticket.comentarios?.map((tk)=>{
                                            return <ComentarioTicketCard item={tk} key={tk.id_com} user={user} estado={isCerrado} onGuardar={actualizar} autorizado={isAutorizado} cvv />
                                        })}
                                    </>
                                ):(
                                    <div className='alert bg-gray-light mt-2 text-center'>
                                        Sin Comentarios
                                    </div>
                                )}
                                <div className="row mt-4 mb-3">
                                    <div className='col-lg-9 col-md-7 align-self-center'>
                                        <h4>{!ticket?.isLastComment_tk && 'Dejar un comentario'}</h4>
                                        <small style={{color:'red'}}>{!ticket?.isLastComment_tk && ticket?.cerrado_tk && 'Comentario para solicitar la reapertura.'}</small>
                                    </div>
                                    <div className='col-lg-3 col-md-5 align-self-center'>
                                        {ticket && ticket.externa_tk && !ticket.isOpenAgain_tk && (ticket.estado_tk === "CERRADO") && user && user?.razonSocial_p === 'Policia de la Provincia de Salta' &&(
                                            <Button
                                                onClick={onOpenNewTicket}
                                                className="float-right"
                                            >
                                                Abrir Nuevo Ticket
                                            </Button>
                                        )}
                                        {ticket && !ticket?.reopeningDate_tk && !ticket.isOpenAgain_tk && ticket?.cerrado_tk && (ticket.estado_tk === "CERRADO" || ticket.estado_tk === "VENCIDO") && user && user?.razonSocial_p !== 'Policia de la Provincia de Salta' &&(
                                            <Button
                                                onClick={onOpenReapertura}
                                                className="float-right"
                                            >
                                                Reapertura del Ticket
                                            </Button>
                                        )}
                                        {/* { ticket && (ticket.estado_tk === "ABIERTO" || ticket.estado_tk === "VENCEHOY" || ticket.estado_tk === "PORVENCER") &&(
                                            <button type="button" className="float-right btn-primary btn-circle btn-xl" onClick={()=>{setChecked(!checked)}}>
                                                <i className="fa fa-comments"></i>
                                            </button>
                                        )} */}
                                    </div>
                                    { ticket && (!ticket?.isLastComment_tk || user && user?.razonSocial_p !== 'Policia de la Provincia de Salta') &&(
                                        <div className='col-lg-12 col-md-12'>
                                            <ul className="list-group list-group-unbordered">
                                                <div className="row">
                                                    <div className='col-lg-12 col-md-12'>
                                                    <InputEditQuill
                                                        id="Comentario"
                                                        template={comentario}
                                                        onChangeText={(html)=>{
                                                            setComentario(html)
                                                        }}
                                                    />
                                                    </div>
                                                        <p className="col-12 mt-1" style={{opacity: 0.8}}><b>Limite permitido por archivo (20Mb)</b></p>
                                                    <div className="col-12 mb-3">
                                                        <div className='row'>
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className='col-5 mb-2'>
                                                                <InputDropZone
                                                                    id="fotos1"
                                                                    accept={acceptInputFile}
                                                                    defaultImage={foto}
                                                                    backgroundImage="fileUpload"
                                                                    onChange={setFoto}
                                                                    setFoto={setFoto}
                                                                    isDeletedNow={!foto}
                                                                />
                                                                {/* <span>Los videos no se visualizan</span> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className='col-5 mb-2'>
                                                                    <InputDropZone
                                                                        id="fotos2"
                                                                        accept={acceptInputFile}
                                                                        defaultImage={foto2}
                                                                        backgroundImage="fileUpload"
                                                                        onChange={setFoto2}
                                                                        setFoto={setFoto2}
                                                                        isDeletedNow={!foto2}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className='col-5'>
                                                                    <InputDropZone
                                                                        id="fotos3"
                                                                        accept={acceptInputFile}
                                                                        defaultImage={foto3}
                                                                        backgroundImage="fileUpload"
                                                                        onChange={setFoto3}
                                                                        setFoto={setFoto3}
                                                                        isDeletedNow={!foto3}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Button
                                                    onClick={onSubmit}
                                                    isLoading={isLoading}
                                                    className="float-right"
                                                >
                                                    Realizar Comentario
                                                </Button>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </Card.Footer>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
        ):(
            <LoaderSpinner />
        )}
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
    ticketrefresh: state.ticketrefresh
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketGetByIdCVVPage);