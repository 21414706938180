import axios from 'axios';
const base = 'usernotify';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

const UserNotifyService = {
    getAll: getAll,
    register: register
};
export default UserNotifyService;
