import types from "../types/types"

export const startTicketReload = () => ({
    type: types.ticketReloadStart
})

export const finishTicketReload = () => ({
    type: types.ticketReloadFinish
})

export const startTicketIndividualReload = () => ({
    type: types.ticketReloadIndStart
})

export const finishTicketIndividualReload = () => ({
    type: types.ticketReloadIndFinish
})

export const startTicketGroupReload = () => ({
    type: types.ticketReloadGroupStart
})

export const finishTicketGroupReload = () => ({
    type: types.ticketReloadGroupFinish
})

export const startTicketExternaReload = () => ({
    type: types.ticketReloadExternaStart
})

export const finishTicketExternaReload = () => ({
    type: types.ticketReloadExternaFinish
})

export const startTicketInvalidReload = () => ({
    type: types.ticketReloadInvalidStart
})

export const finishTicketInvalidReload = () => ({
    type: types.ticketReloadInvalidFinish
})

export const startTicketPendienteReload = () => ({
    type: types.ticketReloadPendienteStart
})

export const finishTicketPendienteReload = () => ({
    type: types.ticketReloadPendienteFinish
})