import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, SelectUser } from '@app/components/index';
import UserNotifyService from '@app/services/userNotify.service';


function ModalNotifyPerson({show, hideModal, elemento, personalArr,onGuardar}) {
    const handleClose = () => hideModal();
    const [personal, setPersonal] = useState(personalArr?.map(acc=>acc));
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () =>{
        setIsLoading(true)
        UserNotifyService.register({
            elemento: elemento,
            personal: personal
        }).then(()=>{
            handleClose()
            onGuardar()
        })
        .catch((err)=>{
            setIsLoading(false)
        })
    }
    return (
        <Modal show={show} backdrop="static" size='md' onHide={handleClose}>
            <Modal.Header closeButton>
            <div className='col-11'>
                <Modal.Title>
                    {`Modificar Personal de ${elemento}`}
                </Modal.Title>
            </div>
            </Modal.Header>
            <div className='col-12 bg-white'>
                <SelectUser
                    isMulti
                    filter='all'
                    value={personal}
                    onChange={(opt) => {
                        setPersonal(opt.map(op=>(
                            op.value
                        )))}
                    }
                />
            </div>
            <Modal.Footer>
                <Button onClick={()=>handleClose()} theme='default'>
                    CERRAR
                </Button>
                <Button
                    theme="primary"
                    onClick={()=>onSubmit()}
                    isLoading={isLoading}
                    disabled={!personal || personal.length <= 0}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalNotifyPerson;