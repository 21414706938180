import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

const Styles = styled.div`
    .containerCenter {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .boxCardHogar {
        width: 140px;
        height: 175px;
        border-radius: 12px;
        background: rgb(0,175,213);
        background: linear-gradient(180deg, rgba(0,175,213,1) 85%, rgba(0,194,238,1) 85%);
        position: relative;

      }
    .arrowCardHogar {
        border-left: 0px solid transparent;
        border-right: 30px solid transparent;
        border-top: 40px solid #00c2ee;
        position: absolute;
        top: 11em;
    }
    .titleBox {
        position: absolute;
        width: 151px;
        height: 50px;
        border-radius: 5px;
        background: #00326a;
        top: 1.7em;
        left: 0.7em;
        color: white;
        text-align: center;
    }
    .arrowtitleBox {
        border-left: 8px solid transparent;
        border-right: 0px solid transparent;
        border-top: 10px solid #00326a;
        position: absolute;
        top: 4.9em;
        left: 0.7em;
    }
    .textContentService {
        background: transparent;
        width: 135px;
        position: absolute;
        top: 5.3em;
        left: 1.4em;
        color: white
    }
`;

function ServiceHogarCard({
    item,
}) {

    const [fontTitle, setFontTitle] = useState('17px');
    const [fontCuerpo, setFontCuerpo] = useState('17px');

    useEffect(() => {
        if(item?.tipo_descripcion?.length <= 13) {
            setFontTitle('17px')
        }else if(item?.tipo_descripcion?.length <= 20){
          setFontTitle('12px')
        }else if(item?.tipo_descripcion?.length < 36){
          setFontTitle('10px')
        }else{
          setFontTitle('10px')
        }
    }, []);
    useEffect(() => {
        if(item?.nombre?.length < 13) {
            setFontCuerpo('21px')
        }else if(item?.nombre?.length < 20){
            setFontCuerpo('18px')
        }else if(item?.nombre?.length <= 30){
            setFontCuerpo('16px')
        }else if(item?.nombre?.length < 36){
            setFontCuerpo('13px')
        }else{
            setFontCuerpo('11px')
        }
    }, []);
        return (
        <Styles>
            <div className='containerCenter'>
                <div className='boxCardHogar'></div>
                <div className='arrowCardHogar'></div>
                <div className='titleBox' style={{backgroundColor: item?.tipo_descripcion === 'NUBI PyMES' ? '#f4763a' : '#00326a'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center', height: '50px'}}>
                        <span style={{fontSize: fontTitle}}>{item?.tipo_descripcion || ''}</span>
                    </div>
                </div>
                <div className='arrowtitleBox' style={{borderTop: `10px solid ${item?.tipo_descripcion === 'NUBI PyMES' ? '#f4763a' : '#00326a'}`}}></div>
                <div className='textContentService'>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:fontCuerpo, textAlign:'center'}}>
                        <p className="overflow-visible">{item?.nombre || ''}</p>
                    </div>
                </div>
            </div>
        </Styles>
    )
}


export default ServiceHogarCard
