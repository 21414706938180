import React, { useState } from 'react';
import {showModal} from 'react-redux-modal-provider';
import Modal from 'react-bootstrap/esm/Modal';
import { Button, ComentarioNotifyCard, TicketNotifyCard } from '@app/components/index';
import ModalNotificationTickets from '@app/modals/notifications/ModalNotificationTickets';
import ModalNotificationComentario from '@app/modals/notifications/ModalNotificationComentario';
import Dropdown from 'react-bootstrap/Dropdown';
import LogService from '@app/services/log.service';


function ModalNotificationsAlls({show, hideModal,ticketNotify,comentarioNotify}) {
    const handleClose = () => hideModal();
    const [viewData, setviewData] = useState('ticket')

    const onOpenModalTicketNotify = () =>{
        showModal(ModalNotificationTickets)
    }
    const onOpenModalComentarioNotify = () =>{
        showModal(ModalNotificationComentario)
    }

    return (
        <Modal show={show} backdrop="static" size='md' onHide={handleClose}>
            <Modal.Header closeButton>
            <div className='col-11'>
                <Modal.Title>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <Button
                                onClick={()=>setviewData('ticket')}
                                theme={viewData === 'ticket' ? 'primary' : 'outline-danger'}
                                block
                            >
                                TICKETS
                                {(ticketNotify?.count > 0 && (
                                    <span
                                        className={`ml-3 badge badge-${ viewData === 'ticket' ? 'white' : 'danger'} navbar-badge`}
                                        style={{fontSize: '18px',borderRadius:5}}
                                    >
                                        {ticketNotify?.count || 0}
                                    </span>
                                ))}
                            </Button>
                        </div>
                        <div className='col-lg-6'>
                            <Button
                                onClick={()=>setviewData('comentarios')}
                                theme={viewData === 'comentarios' ? 'primary' : 'outline-danger'}
                                block
                            >
                                COMENTARIOS
                                {(comentarioNotify?.count > 0 && (
                                    <span
                                        className={`ml-3 badge badge-${ viewData === 'comentarios' ? 'white' : 'danger'} navbar-badge`}
                                        style={{fontSize: '18px', borderRadius:5}}
                                    >
                                        {comentarioNotify?.count || 0}
                                    </span>
                                ))}
                            </Button>
                        </div>
                        <div className='col-1'>
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {LogService.ticketNotifyViewAll(); handleClose()}}
                                    >
                                        Marcar tickets como leídos
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {LogService.comentarioNotifyViewAll(); handleClose()}}
                                    >
                                        Marcar comentarios como leídos
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {LogService.notifyViewAll(); handleClose()}}
                                    >
                                        Marcar todo como leído
                                    </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                    </div>
                </div>
                </Modal.Title>
            </div>
            </Modal.Header>
            <div className='col-12 bg-white'>
                { viewData === 'ticket' &&(
                        ticketNotify?.content?.length > 0 ? (
                        <>
                            {ticketNotify?.content?.map((ment)=>{
                                return(
                                    <div key={ment?.id_ment} className='col-12 m-1'>
                                        <TicketNotifyCard item={ment} isModal toggleDropdown={()=>handleClose()} />
                                        <div className="dropdown-divider mt-1 mb-1" />
                                    </div>
                                )
                            })}
                            {ticketNotify?.totalPages > 1 && (
                                <div
                                    className='dropdown-item dropdown-footer bg-blue cursor-pointer'
                                    aria-hidden="true"
                                    onClick={()=> { handleClose(); onOpenModalTicketNotify();}}
                                >
                                    Ver todas las notificaciones
                                </div>
                            )}
                        </>
                    ):(
                        <div className='p-3 alert alert-light mt-2 text-center'>
                            Aún no registra una actividad.
                        </div>
                    )
                )}
                { viewData === 'comentarios' &&(
                        comentarioNotify?.content?.length > 0 ? (
                        <>
                            {comentarioNotify?.content?.map((ment)=>{
                                return(
                                    <div key={ment?.id_ment} className='col-12 m-1'>
                                        <ComentarioNotifyCard item={ment} isModal toggleDropdown={()=>handleClose()} />
                                        <div className="dropdown-divider mt-1 mb-1" />
                                    </div>
                                )
                            })}
                            {comentarioNotify?.totalPages > 1 && (
                                <div
                                    className='dropdown-item dropdown-footer bg-blue cursor-pointer'
                                    aria-hidden="true"
                                    onClick={()=> { handleClose(); onOpenModalComentarioNotify();}}
                                >
                                    Ver todas las notificaciones
                                </div>
                            )}
                        </>
                    ):(
                        <div className='p-3 alert alert-light mt-2 text-center'>
                            Aún no registra una actividad.
                        </div>
                    )
                )}
            </div>
            <Modal.Footer>
                <Button onClick={()=>handleClose()} theme='default'>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalNotificationsAlls;
