import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,SelectEmpresas,SelectInsertEmail,SelectUser} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import GrupoService from '@app/services/grupo.service';
const schema = Yup.object({
    nombre_g: Yup.string().required().max(50),
});

function ModalGrupoCanal({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const [empresa, setEmpresa] = useState(item?.empresaId_g);
    const handleClose = () => hideModal();
    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });
    const [personal, setPersonal] = useState(item?.idpersonal?.map(acc=>acc));
    const [emails, setEmails] = useState(item?.emailArr_g || []);

    const onSubmit = (data) => {
        const form = {
            ...item,
            ...data,
            personal:personal,
            empresaId_g:empresa,
            emails: emails
        };
        if(item && item?.id_g > 0){
            GrupoService.update(form).then(()=>{
                hideModal();
                onGuardar();
            })
        }else{
            GrupoService.register(form).then(()=>{
                hideModal();
                onGuardar();
            })
        }
    };

    useEffect(() => {
        if (item.id_g > 0) {
            setValue('nombre_g', item.nombre_g);
            setValue('aliasVisible_g', item.aliasVisible_g);
            setEmpresa(item?.empresaId_g);
        }
    }, []);
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id_g > 0 ? 'Editar Grupo' : 'Registrar Grupo'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_emp">
                            Nombre del Grupo
                        </label>
                        <input
                            className={`form-control ${errors.nombre_emp? 'is-invalid': ''}`}
                            id="nombre_g"
                            name="nombre_g"
                            type="text"
                            autoComplete="off"
                            ref={register}
                        />
                        {errors.nombre_emp && (
                            <div className="invalid-feedback">
                                {errors.nombre_emp.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="alias_emp">
                            Alias del Grupo
                        </label>
                        <input
                            className={`form-control ${errors.alias_emp? 'is-invalid': ''}`}
                            id="aliasVisible_g"
                            name="aliasVisible_g"
                            type="text"
                            autoComplete="off"
                            ref={register}
                        />
                        {errors.alias_emp && (
                            <div className="invalid-feedback">
                                {errors.alias_emp.message}
                            </div>
                        )}
                    </div>
                    {/* <div className="form-group">
                        <label className="label-control" htmlFor="empresaId_pr">
                            Empresas
                        </label>
                        <SelectEmpresas
                            id="empresaId_g"
                            name="empresaId_g"
                            value={empresa}
                            onChange={(opt)=>setEmpresa(opt.value)}
                        />
                    </div> */}
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_can">
                            Integrantes
                        </label>
                        <SelectUser
                            isMulti
                            grupo={item?.id_g}
                            filter="groupxuserselect"
                            id="selectUser"
                            value={personal}
                            isInvalid={errors.dni_p}
                            onChange={(opt) => {
                                setPersonal(opt.map(op=>(
                                    op.value
                                )))}
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_can">
                            Emails
                        </label>
                        <SelectInsertEmail
                            id="selectEmails"
                            value={emails}
                            onChange={setEmails}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    disabled={!personal || personal.length <= 0}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalGrupoCanal.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id_can: PropTypes.number,
        nombre_can: PropTypes.string
    })
};

ModalGrupoCanal.defaultProps = {
    show: false,
    item: {
        id_g: 0,
        nombre_g: ''
    }
};

export default ModalGrupoCanal;