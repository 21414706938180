import axios from 'axios';
const base = 'usuarios';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}
function register(data) {
    return axios.post(base, data);
}

function cpAlta(data) {
    return axios.post('usuarios/cpAlta', data);
}

function cpBaja(id) {
    return axios.delete(['usuarios/cpBaja', id].join('/'));
}

function renewPassUser(id) {
    return axios.put(['usuarios/repass', id].join('/'));
}

function changePassUser(data) {
    return axios.put('usuarios/changepass',data);
}

const UserService = {
    getAll,
    cpAlta,
    cpBaja,
    register,
    renewPassUser,
    changePassUser
};
export default UserService;