import React from 'react'
import styled from 'styled-components';
import { Button } from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import {useNavigate} from 'react-router-dom';
import TextMark from '@app/components/text/TextMark';

const Styles = styled.div``;

function TratoCard({
    textSearch = '',
    item,
    onCloseModal
}) {
    const navigate = useNavigate();
    const onOpenVer = (tra) => {
        if(onCloseModal){
            onCloseModal();
        }
        if(tra?.isCamera_t){
            navigate(`/tratos/${tra.id_t}/vercam`);
        }else{
            navigate(`/trato/${tra.id_t}/ver`);
        }
    }

    return (
        <div className="card border border-primary redondeado shadow-sm p-3 m-3">
            <div className='col-lg-12 col-md-12'>
                <div className='row'>
                    <div className='col-1 mt-2 ml-3'>
                        <img src="/img/911-logo.png" alt="login" className="iconoEmpTicketCard"></img>
                    </div>
                    <div className='col-lg-10 col-md-12'>
                        <div className="col-12">
                            <TextMark
                                texto={item?.isCamera_t ?
                                    `#${item?.nombre_t} ${item?.lugar?.nombre_l || item?.lugarNombre_t || ''} - ${item?.direccion_t || 'S/D'}`
                                    :
                                    `#${item?.id_t} - ${item?.nombre_t} ${item?.lugar?.nombre_l || item?.lugarNombre_t || ''} - ${item?.direccion_t || 'S/D'}`
                                }
                                textMark={textSearch}
                            />
                        </div>
                        <div className='row d-flex justify-content-center align-items-center'>
                            <div className='col-lg-4 col-md-12'>
                                <Button block onClick={()=>onOpenVer(item)}>
                                    {item?.isCamera_t ?'Ver Camara' : 'Ver Contrato'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TratoCard
