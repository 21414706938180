import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import { useNavigate} from 'react-router-dom';
import { Tabs, Tab} from 'react-bootstrap';
import TabContactoTickets from '@app/pages/tratos/camaras/ver/TabTratoTickets';
import TratoService from '@app/services/tratos.service';
import usePermisos from '@app/hooks/usePermisos';

import TabTratoEditar from '@app/pages/tratos/camaras/ver/TabTratoEditar';
import { MapBase, MarkerTratos, Button, ButtonBack, LoaderSpinner } from '@app/components/index';

function TratoCamaraVerPage({
    user
}) {

    const {id} = useParams();
    const permisos = usePermisos();
    const [key, setKey] = useState('tickets');
    const [isLoading, setIsLoading] = useState(true);
    const [trato, setTrato] = useState();
    const [detTrato, setDetTrato] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true)
        TratoService.getById(id,{type:'cvv'}).then((r)=>{
            setTrato(r)
            setIsLoading(false)
        if(r?.tratocam.length > 0){
                setDetTrato(r?.tratocam[0])
            }
        })
        .catch(()=>{
            navigate('/tratoscam');
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }, [id])
    return (
    <>
        {!isLoading ?(
            <section className="content">
                <div className="container-fluid p-1">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h2>
                                {trato && (`Camara #${trato?.nombre_t}`)}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card card-primary card-outline">
                                <ButtonBack />
                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        <img
                                            className="profile-user-img img-fluid img-circle"
                                            src="/img/default-service.png"
                                            alt="User profile"
                                        />
                                    </div>
                                    <h3 className="profile-username text-center">
                                        {trato && `# ${trato?.nombre_t}`}
                                    </h3>
                                    <Button
                                        block
                                        theme='gray'
                                        className="my-1"
                                    >
                                    Datos de la Camara
                                    </Button>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        <li className="list-group-item">
                                            <b>Nombre</b>
                                            <span className="float-right">
                                                {trato?.nombre_t}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Localidad</b>
                                            <span className="float-right">
                                                {trato?.lugarNombre_t}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Tipo</b>
                                            <span className="float-right">
                                                {detTrato?.tipoCam_dcam}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Tecnologia</b>
                                            <span className="float-right">
                                                {detTrato?.tecnologiaCam_dcam}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Direccion</b>
                                            <span className="float-right">
                                                {trato?.direccion_t}
                                            </span>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Total de Tickets</b>
                                            <span className="float-right">
                                                {trato?.tickets?.length}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div>
                                {
                                    !isLoading && (
                                        <Tabs
                                            id="tratosTab"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                        >
                                            <Tab eventKey="tickets" title="Tickets" tabClassName="card-body">
                                                <TabContactoTickets 
                                                    tickets={trato?.tickets}
                                                />
                                            </Tab>
                                            {permisos['trato.abm911'] &&(
                                                <Tab eventKey="editar" title="Detalles" tabClassName="card-body">
                                                    <TabTratoEditar 
                                                        item={trato} 
                                                    />
                                                </Tab> 
                                            )}
                                            <Tab eventKey="ubicacion" title="Ubicacion" tabClassName="card-body">
                                                <div className="col-12">
                                                    <MapBase
                                                        mapContainerStyle={{
                                                            width: '100%',
                                                            height: 400
                                                        }}
                                                        isSearchable={false}
                                                        coordinates={{
                                                            lat: Number(trato?.latitud_t),
                                                            lng: Number(trato?.longitud_t)
                                                        }}
                                                        options={{
                                                            maxZoom: 21,
                                                            minZoom: 14,
                                                        }}
                                                        zoom={16}
                                                    >
                                                        <MarkerTratos item={trato} />
                                                    </MapBase>
                                                </div>
                                            </Tab> 
                                        </Tabs>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        ):(
            <LoaderSpinner />
        )}
    </>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
})

export default connect(mapStateToProps, null)(TratoCamaraVerPage)
