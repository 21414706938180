import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectStageTicket = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
         
    const options = [
        {
            value: 'Abierto',
            label: 'Abierto'
        },
        {
            value: 'Fecha Asignada',
            label: 'Fecha Asignada'
        },
        {
            value: 'En Espera',
            label: 'En Espera'
        },
        {
            value: 'Reprogramar',
            label: 'Reprogramar'
        },
        {
            value: 'En Gestion Cobranza',
            label: 'En Gestion Cobranza'
        },
        {
            value: 'Cerrado',
            label: 'Cerrado'
        }
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value]);

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectStageTicket.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectStageTicket;