import React, {useState} from 'react';
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
import ControlPanel from '@app/components/map/ControlPanel';

const containerStyle = {
    width: '100%',
    height: '400px'
};
const libraries = ['places','drawing'];

function MapClickCamera({
    coordinates,
    options,
    zoom,
    onChange,
}) {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBtCQByICBfwfxHs-ny0LftBhY6iMwzMGg',
        libraries,
    });
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [currentZoom, setCurrentZoom] = useState(zoom);
    const [mapTypeId, setMapTypeId] = useState('roadmap');

    const onClickMap = (ev) => {
        if (onChange) {
            onChange({
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng()
            });
        }
        marker.setPosition(ev.latLng);
    };

    const onLoad = React.useCallback(function callback(mapLoaded) {
        setMap(mapLoaded);
    }, []);

    const onUnmount = React.useCallback(function callback() {
        setMap(null);
    }, []);

    const onLoadMarker = React.useCallback(function callback(markerLoaded) {
        setMarker(markerLoaded);
    }, []);
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={currentZoom}
            mapTypeId={mapTypeId}
            options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                ...options
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onClickMap}
        >
            <ControlPanel
                zoom={currentZoom}
                options={options}
                map={map}
                coordinates={coordinates} 
                mapTypeId={mapTypeId}
                onChangeMapType={setMapTypeId} 
                onChangeZoom={setCurrentZoom} 
                isSearchable={false}
            />
            <Marker 
                onLoad={onLoadMarker} 
                position={coordinates}
                icon={{
                    url:'/img/fija.png',
                    scaledSize: {
                        width:50,
                        height:50,
                    }
                }}
            />
        </GoogleMap>
    ) : (
        <></>
    );
}
/*
MapClickCamera.propTypes = {
    coordinates: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    }),
    onChange: PropTypes.func
};
*/
MapClickCamera.defaultProps = {
    coordinates: {
        lat: -24.789090,
        lng: -65.410255
    },
    onChange: null,
    zoom: 18,
    options: {},
};

export default React.memo(MapClickCamera);
