import axios from 'axios';

function getAll(query = null) {
    return axios.get('/preticket/ticket', {
        params: query
    });
}

function createdTicketAll(data) {
    return axios.post('/preticket/ticket',data);
}

function preTicketInvalido(data) {
    const form = new FormData();
    form.append('id_ptk',data.id_ptk);
    form.append('texto',data.texto);
    if(data?.adj1){
        if(Array.isArray(data.adj1) && data.adj1.length>0){
            form.append('adj1',data.adj1[0]);
        } else {
            form.append('adj1',data.adj1);
        }
    }
    if(data?.adj2){
        if(Array.isArray(data.adj2) && data.adj2.length>0){
            form.append('adj2',data.adj2[0]);
        } else {
            form.append('adj2',data.adj2);
        }
    }
    return axios.put(['/preticket/rechazado', data.id_ptk].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        }
    });
}

const CustomExternalTicketService = {
    getAll,
    createdTicketAll,
    preTicketInvalido
};
export default CustomExternalTicketService;
