import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectTipoTegnologiaCam = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    tipoCam,
    defaultProps,
    disabled
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [options, setOptions] = useState([]);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    useEffect(() => {
        if(tipoCam === 'DOMO' || tipoCam === 'Domo con Encoder' || tipoCam === 'Fija con Encoder'){
            setOptions([
                {
                    value: 'COMUN',
                    label: 'COMUN'
                },{
                    value: 'INFRARROJO',
                    label: 'INFRARROJO'
                }
            ])
        }else if(tipoCam === 'FIJA'){
            setOptions([
                {
                    value: 'INFRARROJO',
                    label: 'INFRARROJO'
                },{
                    value: 'DETECCION DE ROSTROS',
                    label: 'DETECCION DE ROSTROS'
                },{
                    value: 'DETECCION DE PATENTES',
                    label: 'DETECCION DE PATENTES'
                }
            ])
        }
    }, [tipoCam])


    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value,options]);

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={disabled}
            />
        </Styles>
    );
};

SelectTipoTegnologiaCam.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectTipoTegnologiaCam;