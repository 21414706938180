import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, TicketVistaCard} from '@app/components/index';


function ModalPreTicketViewCreated({show, hideModal, items, idIncidencia}) {
    const handleClose = () => hideModal();
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Incidencia #${idIncidencia}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white'>
                    <div className="row">
                        <div className="offset-lg-12 col-lg-12">
                            {items.length > 0 ? (
                                items.map((item)=>(
                                    <TicketVistaCard key={item?.id_tk} item={item} onCloseModal={handleClose} />
                                ))
                            ):(
                                <div className='alert alert-light mt-4 text-center col-12'>
                                    <i className="fa fa-tags" style={{fontSize: 120, color: 'gray'}}></i>
                                    <div className='mt-2 mb-2' style={{fontSize: 20, color: 'gray'}}>
                                        Sin Tickets creados.
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        theme='default'
                        size='xl'
                        onClick={handleClose}
                        style={{width: 100}}
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}

ModalPreTicketViewCreated.propTypes = {
    show: PropTypes.bool,
};

ModalPreTicketViewCreated.defaultProps = {
    show: false,
};

export default ModalPreTicketViewCreated;