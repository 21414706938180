import React, {useState, useEffect} from 'react';
import { Marker} from '@react-google-maps/api';
import {Spinner} from 'react-bootstrap';

function BoxPrefactibilidad({coordinates, onChange, prefactibilidad, isLoading}) {
    const [marker, setMarker] = useState(null);

    const onLoadMarker = React.useCallback(function callback(markerLoaded) {
        setMarker(markerLoaded);
    }, []);


    const onDragEnd = () => {
        const newCoordinates = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng(),
        };
        onChange(newCoordinates);
    };

    useEffect(() => {
        if(marker){
            marker.setPosition(coordinates);
        }
    }, [coordinates]);

      
    return (
        <>
            <Marker 
                onLoad={onLoadMarker} 
                position={coordinates} 
                draggable
                onDragEnd={onDragEnd}
                icon={{
                    url:'/img/location_home.svg',
                    scaledSize: {
                        width:50,
                        height:50,
                    }
                }}
            />
            <div 
                id="divcard" 
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "50%",
                    bottom: 0,
                    marginLeft: "-120px",
                    backgroundColor: prefactibilidad ? prefactibilidad.color : 'white',
                    textAlign: 'center',
                }}
            >
                {
                    !isLoading ? (
                        <label id="labelcoberturaMb">{prefactibilidad ? prefactibilidad.descripcion : 'Ingrese'}</label>
                    ) : (
                        <Spinner animation="border" />
                    )
                }
            </div>
        </>
    );  
}


BoxPrefactibilidad.defaultProps = {
    onChange: () => {},
    onClick: () => {},
};

export default BoxPrefactibilidad;
