import React, { useState} from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';
import { isBefore } from 'date-fns';

const Styles = styled.div`
    width: 100%;
`;

const RangeDate = ({minDate,onChange,isDisabled,initianDate}) => {
    const [startDate, setStartDate] = useState(initianDate?.startDate || minDate || new Date());
    const [endDate, setEndDate] = useState(initianDate?.endDate || minDate || new Date());
    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <input
                onClick={onClick}
                className="form-control text-center"
                value={valueField}
                ref={ref}
                readOnly
            />
        )
    );
    const onChangeData = (destino,fecha) =>{
        if(destino === 'start'){
            const anterior = isBefore(endDate,fecha)
            setStartDate(fecha)
            if(anterior){
                setEndDate(fecha)
                onChange({
                    startDate: fecha,
                    endDate: fecha
                })
            }else{
                onChange({
                    startDate: fecha,
                    endDate
                })
            }
        }else{
            setEndDate(fecha)
            onChange({
                startDate,
                endDate: fecha
            })
        }
    }

    return (
        <>
            Desde:
            <DatePicker
                selected={startDate}
                onChange={(date) => onChangeData('start',date)}
                dateFormat="P"
                disabled={isDisabled}
                customInput={<CustomInput />}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                minDate={minDate || new Date()}
            />
            Hasta:
            <DatePicker
                selected={endDate}
                onChange={(date) => onChangeData('end',date)}
                dateFormat="P"
                disabled={isDisabled}
                customInput={<CustomInput />}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
            />
        </>
    );
}

export default RangeDate;
