import axios from 'axios';

function login(email,pass){
    return axios.post('auth/login',{
        email:email,
        pass:pass,
    });
}

function usermeTK(query = null){
    return axios.get('auth/checkmetk',{
        params: query
    });
}

function logout(){
    return axios.post('logout');
}

const AuthService = {
    login,
    logout,
    usermeTK
};
export default AuthService;