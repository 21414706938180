import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import { Tabs, Tab } from 'react-bootstrap';
import SearchService from '@app/services/search.service';
import TabsSearchTicket from './TabsSearchTicket';
import TabsSearchTrato from './TabsSearchTrato';
import TabsSearchAll from './TabsSearchAll';

function ModalBuscador({show, hideModal, textSearch}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [titulo, setTitulo] = useState(textSearch || '')
    const [data, setData] = useState([])
    const inputRef = useRef(null);
    const [key, setKey] = useState('todos');

    useEffect(() => {
        if (show) {
            inputRef.current.focus();
        }
    }, [show]);

    const onSearchData = async() => {
        await SearchService.searchAll({typeSearch:key,texto: titulo}).then((resp)=>{
            setData(resp)
        })
    }

    useEffect(() => {
        const delayWrite = setTimeout(() => {
            onSearchData()
        }, 600)
        return () => clearTimeout(delayWrite)
    }, [titulo,key])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <div className='col-lg-10' style={{display: 'flex', alignItems: 'center'}}>
                    <h6 className="label-control" htmlFor="titulo_tk" style={{marginTop: -10}}>
                        Buscardor:
                    </h6>
                    <div className="col-lg-6 col-md-10">
                        <div className="form-group">
                            <input
                                placeholder='Escribe aquí lo que estás buscando'
                                type="text"
                                className='form-control'
                                id="titulo_tk"
                                name="titulo_tk"
                                autoComplete="off"
                                value={titulo}
                                onChange={(e)=>setTitulo(e.target.value)}
                                ref={inputRef}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className='bg-white'>
                <div className="row">
                    <div className="col-12">
                        <Tabs
                            id="BuscadorGeneral"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="todos" title="Todos" tabClassName="card-body">
                                <TabsSearchAll data={data} handleCloseModal={handleClose} textSearch={titulo} />
                            </Tab>
                            <Tab eventKey="ticket" title="Ticket" tabClassName="card-body">
                                <TabsSearchTicket data={data} handleCloseModal={handleClose} textSearch={titulo} />
                            </Tab>
                            <Tab eventKey="incidencia" title="N° Incidencia" tabClassName="card-body">
                                <TabsSearchTicket data={data} handleCloseModal={handleClose} textSearch={titulo} />
                            </Tab>
                            <Tab eventKey="camara" title="Camara" tabClassName="card-body">
                                <TabsSearchTrato data={data} handleCloseModal={handleClose} textSearch={titulo} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

ModalBuscador.propTypes = {
    show: PropTypes.bool,
};

ModalBuscador.defaultProps = {
    show: false,
};

export default ModalBuscador;