import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

const Styles = styled.div``;

const SelectLugares = ({id, value, onChange, isInvalid, isClearable,cvv,disabled}) => {
    const [options, setOptions] = useState([]);
    const [Lugare, seLugare] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const lugares = useSelector(state => state.default.lugares);

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(ev){
            onChange(ev);
        } else {
            onChange(null);
        }
    };
    useEffect(() => {
        seLugare(lugares)
    }, [lugares])

    useEffect(() => {
        let newOptions;
        if(cvv > 0){
            newOptions = Lugare
            .filter((lug) => lug.cvv_l === cvv)
            .map((item) => {
                return {
                    value: item.id_l,
                    label: item.nombre_l,
                    lat: item.latitud_l,
                    lng: item.longitud_l
                };
            });
        }else{
            newOptions = Lugare?.map((item) => {
                return {
                    value: item.id_l,
                    label: item.nombre_l,
                    lat: item.latitud_l,
                    lng: item.longitud_l
                };
            });
        }
        setOptions(newOptions);
    }, [Lugare,cvv]);

    useEffect(() => {
        if( options.length > 0 ){
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    options.find((opt) => opt.value === Number(value))
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>({label:va.nombre_l,value:va.id_l})));
            }
        }
    }, [options])
    return (
        <Styles>
            <Select
                isDisabled={disabled}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectLugares.defaultProps = {
    isClearable: false
};


const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLugares)

