import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import { TicketVistaCard } from '@app/components/index';
import TablaTicketService from '@app/services/tablaTicket.service';

function TabsTicketSituacionExterna({
    ticketrefresh
}) {
    const [tickets, setTickets] = useState([]);
    const actualizar = () =>{
        TablaTicketService.getAll({
            consulta: 'situacionExterna',
            cvv: true
        })
        .then((response)=>{
            setTickets(response);
        })
    }
    const onGuardar = () => {
        actualizar();
    };

    useEffect(() => {
        actualizar();
    }, [])

    useEffect(() => {
        if(ticketrefresh?.reloadingExterna){
          actualizar();
        }
    }, [ticketrefresh])
    
    return (
        <div className="p-3 p-lg-5">
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    {tickets && tickets?.length > 0 ? (
                        tickets?.map((ticket)=>{
                            return <TicketVistaCard item={ticket} tratos={ticket.tratos} key={ticket.id_tk} onGuardar={onGuardar} className="mb-3" />
                        })
                    ):(
                        <div className='alert alert-light mt-2 text-center'>
                            <i className="fas fa-inbox" style={{fontSize: 200, color: 'gray'}}></i>
                            <div style={{fontSize: 20, color: 'gray'}}>
                                No se encuentran tickets con situacion externa
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ticketrefresh: state.ticketrefresh
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TabsTicketSituacionExterna)