import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';
import { useSelector } from 'react-redux';

const Styles = styled.div`

`;

function LogsCard({
    item,
    type,
    keyst
}) {
    let texto;
    const [mods, setMods] = useState({})
    const [modsNew, setModsNew] = useState({})
    const motivoTk = useSelector(state => state.default.motivoTk);
    const motivoTkCvv = useSelector(state => state.default.motivoTkCvv);
    const subMotivoTk = useSelector(state => state.default.subMotivoTk);
    const tipoTk = useSelector(state => state.default.tipoTk);
    const subTipoTk = useSelector(state => state.default.subTipoTk);
    const nivelAdmTk = useSelector(state => state.default.nivelAdmTk);
    const motivoBajaTk = useSelector(state => state.default.motivoBajaTk);

    useEffect(() => {
        if(item?.old_lt){
            setMods(item?.old_lt)
            setModsNew(item?.new_lt)
        }
    }, [item])
    
    if(item){
        switch (item?.accion_lt) {
            case 'ALTA':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} CREO el ticket.`;
                break;
            case 'CIERRE':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} realizo el CIERRE del ticket.`;
                break;
            case 'REABRIO':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} ABRIO nuevamente el ticket.`;
                break;
            case 'ASIGNOP':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} ASIGNO el ticket a ${item?.asignoNombre_p || item?.creadoNombre_p}.`;
                break;
            case 'ASIGNOG':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} ASIGNO el ticket al grupo ${item?.asignoNombre_g || item?.creadoNombre_g}.`;
                break;
            case 'RESOLUCION':
                    texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} realizo la RESOLUCION del ticket.`;
                break;
            case 'MODIFICO':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} realizo las siguientes modificaciones en el ticket.`;
                break;
            case 'REAPERTURA':
                texto=`El usuario ${item?.creado?.nombre_p || item?.creadoNombre_p} realizo la REAPERTURA del ticket.`;
                break;
            default:
                break;
        }
    }
    return (
        <div className="log-card mt-3" key={keyst}>
                <div id="nombre">{texto}</div>
                <div className="col-12">
                    {
                        Object.keys(mods).length > 0 && (
                            <table className="table table-bordered table-sm">
                                <thead className="bg-primary t-white">
                                    <tr>
                                        <th>CAMPO</th>
                                        <th>ANTES</th>
                                        <th>AHORA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.entries(mods).map(([key,index])=>{
                                        let old = mods[key]
                                        let nuevo = modsNew[key];
                                        let keys = key;
                                        let auxOld = 0;
                                        let auxNew = 0;
                                        switch (key) {
                                            case 'titulo_tk':
                                                keys='Titulo';
                                            break;
                                            case 'fechavencimiento_tk':
                                                keys='Fecha de Vencimiento';
                                                if(old){
                                                    old = FechaFunction.format(old, 'dd/MM/yyyy');
                                                }else{
                                                    old = '-'
                                                }
                                                nuevo = FechaFunction.format(nuevo, 'dd/MM/yyyy');
                                            break;
                                            case 'prioridad_tk':
                                                keys='Prioridad';
                                            break;
                                            case 'estado_tk':
                                                keys='Estado';
                                            break;
                                            case 'stageTicket_tk':
                                                keys='Etapa';
                                            break;
                                            case 'motivo_tk':
                                                keys='Motivo Ticket';
                                                if(type === 'multi'){
                                                    auxOld = motivoTk.find( mt => mt.id_mt === old );
                                                    auxNew = motivoTk.find( mt => mt.id_mt === nuevo );
                                                    old = auxOld?.motivo_mt || '-';
                                                    nuevo = auxNew?.motivo_mt || '-';
                                                }else{
                                                    auxOld = motivoTkCvv.find( mt => mt.id_mt === old );
                                                    auxNew = motivoTkCvv.find( mt => mt.id_mt === nuevo );
                                                    old = auxOld?.motivo_mt || '-';
                                                    nuevo = auxNew?.motivo_mt || '-';
                                                }
                                            break;
                                            case 'submotivo_tk':
                                                keys='Sub-Motivo';
                                                auxOld = subMotivoTk.find( mt => mt.id_smt === old );
                                                auxNew = subMotivoTk.find( mt => mt.id_smt === nuevo );
                                                old = auxOld?.titulo_smt || '-';
                                                nuevo = auxNew?.titulo_smt || '-';
                                            break;
                                            case 'tipoTicketId_tk':
                                                keys='Tipo Ticket';
                                                auxOld = tipoTk.find( mt => mt.id_ttk === old );
                                                auxNew = tipoTk.find( mt => mt.id_ttk === nuevo );
                                                old = auxOld?.nombre_ttk || '-';
                                                nuevo = auxNew?.nombre_ttk || '-';
                                            break;
                                            case 'subTipoTicketId_tk':
                                                keys='Sub-Tipo Ticket';
                                                auxOld = subTipoTk.find( mt => mt.id_sttk === old );
                                                auxNew = subTipoTk.find( mt => mt.id_sttk === nuevo );
                                                old = auxOld?.nombre_sttk || '-';
                                                nuevo = auxNew?.nombre_sttk || '-';
                                            break;
                                            case 'nivelAdmTkId_tk':
                                                keys='Nivel Administrativo'
                                                auxOld = nivelAdmTk.find( mt => mt.id_natk === old );
                                                auxNew = nivelAdmTk.find( mt => mt.id_natk === nuevo );
                                                old = auxOld?.nombre_natk || '-';
                                                nuevo = auxNew?.nombre_natk || '-';
                                            break;
                                            case 'motivoBajaTk_tk':
                                                keys='Motivo de Baja';
                                                auxOld = motivoBajaTk.find( mt => mt.id_mbtk === old );
                                                auxNew = motivoBajaTk.find( mt => mt.id_mbtk === nuevo );
                                                old = auxOld?.nombre_mbtk || '-';
                                                nuevo = auxNew?.nombre_mbtk || '-';
                                            break;
                                            case 'reopeningDate_tk':
                                                keys='Reapertura';
                                                if(old){
                                                    old = FechaFunction.format(new Date(old),'dd/MM/yyyy HH:mm:ss')
                                                }else{
                                                    old = '-'
                                                }
                                                if(nuevo){
                                                    nuevo = FechaFunction.format(new Date(nuevo),'dd/MM/yyyy HH:mm:ss')
                                                }else{
                                                    nuevo = '-'
                                                }
                                            break;
                                            case 'cerrado_tk':
                                                keys='Cierre';
                                                if(old){
                                                    old = FechaFunction.format(new Date(old),'dd/MM/yyyy HH:mm:ss')
                                                }else{
                                                    old = '-'
                                                }
                                                if(nuevo){
                                                    nuevo = FechaFunction.format(new Date(nuevo),'dd/MM/yyyy hh:MM:ss')
                                                }else{
                                                    nuevo = '-'
                                                }
                                            break;
                                            default:
                                            break;
                                        }
                                        return (
                                            <tr key={index}>
                                                <td>{keys}</td>
                                                <td>{old}</td>
                                                <td>{nuevo}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        )
                    }
                    <footer className="blockquote-footer text-right">
                        {`${FechaFunction.format(new Date(item.createdAt),'dd/MM/yyyy HH:mm:ss')}`}
                    </footer>
                </div>
        </div>
    )
}

LogsCard.defaultProps = {
    canShowMore:false,
    buttons:null,
};

export default LogsCard;
