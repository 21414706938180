import { Button } from '@app/components/index';
import React, {useState, useCallback, useEffect} from 'react'
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

const StylesThumbContainer = styled.div`
    width: 100%;
    height: 100%;
    img{
        object-fit: cover;
    }
`;

const StylesMessages = styled.div`
    font-size: small;
    height: 14;

    .dropzone-accepted{
        font-weight: bold;
    }
    .dropzone-rejected{
        color: red;
    }
`;
  

function InputDropZone({
    id,
    defaultImage,
    backgroundImage,
    enablePreview,
    accept,
    onChange,
    setFoto,
    isDeletedNow
}) {
    const [files, setFiles] = useState([]);
    const [detalleDocument, setDetalleDocument] = useState(null);
    const [visible, setVisible] = useState(false);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];
    const extensionPermitidaVideo = ['wmv','mp4','avi','mov'];
    const extensionPermitidaDoc = ['pdf','doc','docx','txt','xls','xlsx'];
    const onDrop = useCallback(acceptedFiles => {
        onChange(acceptedFiles);
        setFiles(acceptedFiles?.map(file => {
            if(file?.size < 20000000){
                const nombreCortado = file?.name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setVisible(true)
                    return Object.assign(file, {preview: URL.createObjectURL(file)})
                }
                if(extensionPermitidaVideo.includes( extension )){
                    setDetalleDocument(`${file?.name || ''} - ${file?.size || ''}`)
                    return Object.assign('/img/tarjeta-tarea.png', {preview: '/img/tarjeta-tarea.png'})
                }
                if(extensionPermitidaDoc.includes( extension )){
                    setDetalleDocument(`${file?.name || ''} - ${file?.size || ''}`)
                    if(extension === 'pdf'){
                        return Object.assign('/img/pdf.png', {preview: '/img/pdf.png'})
                    }
                    if((extension === 'doc' || extension === 'docx')){
                        return Object.assign('/img/doc.png', {preview: '/img/doc.png'})
                    }
                    if(extension === 'txt'){
                        return Object.assign('/img/txt.png', {preview: '/img/txt.png'})
                    }
                    if((extension === 'xls' || extension === 'xlsx')){
                        return Object.assign('/img/xls.png', {preview: '/img/xls.png'})
                    }
                    return Object.assign('/img/foto.png', {preview: '/img/foto.png'})
                }
                return Object.assign('/img/sin_datos.png', {preview: '/img/sin_datos.png'})
            }
            setVisible(false)
            return Object.assign(backgroundImage, {preview: backgroundImage})
        }    
        ));
    
    }, [])
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        maxFiles:1,
        accept: accept,
        multiple: false
    })
    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file?.path}>
            {`${file?.path} ${file?.size} bytes`}
        </li>
    ));
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file?.path}>
            {`${file?.path} ${file?.size} bytes`}
            <ul>
                {errors.map(e => (
                <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const thumbs = files?.map(file =>(
        <StylesThumbContainer key={file?.name}>
            {file?.preview === 'fileUpload' ? (
                <div className="imgcontactonubi" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <svg style={{width:'100px',height:'100px', color: 'gainsboro'}} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
                    </svg>
                </div>
            ):(
                <img className="imgcontactonubi" src={file?.preview} alt="" />
            )}
        </StylesThumbContainer>
    ));

    useEffect(() => {
        if(isDeletedNow){
            setFiles([Object.assign(backgroundImage, {preview: backgroundImage})])
            setVisible(false)
            setFoto(null)
        }
    }, [isDeletedNow])

    return (
        <section>
                {defaultImage &&(
                    <Button
                        style={{background:'red',position:'absolute'}}
                        onClick={()=>{
                            setFiles([Object.assign(backgroundImage, {preview: backgroundImage})])
                            setVisible(false)
                            setFoto(null)
                        }}
                    >
                        X
                    </Button>
                )}
            <div {...getRootProps()}>
                <input {...getInputProps()} id={id} />
                {
                    files?.length === 0 && backgroundImage && (
                        defaultImage ? (
                            <img className="imgcontactonubi" src={defaultImage} alt="" />
                        ) : (
                            <>
                                {backgroundImage === 'fileUpload' ? (
                                    <div className="imgcontactonubi" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <svg style={{width:'100px',height:'100px', color: 'gainsboro'}} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
                                        </svg>
                                    </div>
                                ):(
                                    <img className="imgcontactonubi" src={backgroundImage} alt="" />
                                )}
                            </>
                        )
                    )
                }
                {
                    (files?.length === 0 && !backgroundImage) && (
                        isDragActive ?
                        ( <p>Drop the files here ...</p> ):
                        ( <p>Drag n drop some files here, or click to select files</p> )
                    )
                }
                {
                    files?.length > 0 && !enablePreview && (
                        <i className="fas fa-file" />
                    )
                }
                {
                    files?.length > 0 && enablePreview && (
                        thumbs
                    )
                }
            </div>
            <StylesMessages>
                {visible ?(
                    <>
                    <div className="dropzone-accepted">{acceptedFileItems}</div>
                    <div className="dropzona-rejected">{fileRejectionItems}</div>
                    </>
                ):(
                    detalleDocument && (
                        <div className="dropzone-accepted">
                            <li>{detalleDocument}</li>
                        </div>
                    )
                )}
            </StylesMessages>
        </section>
    )
}

InputDropZone.defaultProps = {
    accept: 'image/jpeg, image/png, image/jpg, image/svg, image/x-png',
    backgroundImage: null,
    defaultImage: null,
    enablePreview: true,
    id: '',
    onChange: () => {},
};

export default InputDropZone;