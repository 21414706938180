import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LugaresService from '@app/services/lugares.service'
import MotivoTicketService from '@app/services/motivoTicket.service';
import TratoService from '@app/services/tratos.service';
import LogService from '@app/services/log.service';

export const actualizarLugares = createAsyncThunk(
    "default/lugares", async () => LugaresService.getAll()
);
export const actualizarTratosCam = createAsyncThunk(
    "default/tratos", async () => TratoService.getAll({type: 'cvv'})
);

export const actualizarMotivoTKCVV = createAsyncThunk(
    "default/motivoTKCVV", async () => MotivoTicketService.getAll({type: 'cvv'})
);
export const actualizarTicketNotify = createAsyncThunk(
    "default/ticketNotify", async () => LogService.getAllTicketNotify()
);
export const actualizarComentarioNotify = createAsyncThunk(
    "default/comentarioNotify", async () => LogService.getAllComentarioNotify()
);

export const defaultSlice = createSlice({
    name: 'default',
    initialState: {
        lugares: [],
        tratoscam: [],
        motivoTkCvv: [],
        subMotivoTk:[],
        ticketNotify:[],
        comentarioNotify:[],
    },
    reducers: {
    },
     extraReducers: (builder) => {

        builder.addCase(actualizarLugares.fulfilled, (state, { payload }) => {
            state.lugares = payload;
        });

        builder.addCase(actualizarTratosCam.fulfilled, (state, { payload }) => {
            state.tratoscam = payload;
        });

        builder.addCase(actualizarMotivoTKCVV.fulfilled, (state, { payload }) => {
            state.motivoTkCvv = payload;
        });

        builder.addCase(actualizarTicketNotify.fulfilled, (state, { payload }) => {
            state.ticketNotify = payload;
        });

        builder.addCase(actualizarComentarioNotify.fulfilled, (state, { payload }) => {
            state.comentarioNotify = payload;
        });
    }
});


export default defaultSlice.reducer
