import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
    .cont {
        height: 75vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loader {
        margin: 0 auto;
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background: linear-gradient(#d55353, #dcdcdc);
        animation: animate .7s linear infinite;
    }
    
    .loader:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        background: white;
        border-radius: 50px;
    }
    
    @keyframes animate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
const LoaderSpinner = () => {

    return (
        <Styles>
            <div className='cont'>
                <div className="loader">
                </div>
            </div>
        </Styles>
    )
}

export default LoaderSpinner;
