import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {TicketCard} from '@app/components/index';
import usePermisos from '@app/hooks/usePermisos';
import { Button } from 'react-bootstrap';

function ModalTicketTrato({show, hideModal, item, history}) {
    const handleClose = () => hideModal();
    const permisos = usePermisos();
    const [filtroTK, setfiltroTK] = useState('');
    const [filtro, setfiltro] = useState(item);

    useEffect(() => {
        setfiltro(item?.tratoxticket?.filter((tk) => tk.ticket.estado_tk === filtroTK))
        if(filtroTK === ''){
            setfiltro(item.tratoxticket)
        }
    }, [filtroTK])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {`Camara #${item.nombre_t}`}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="">
                                <div className="row">
                                    <Button style={{width: '100px'}} variant={filtroTK === 'ABIERTO' ? 'success' : 'outline-success'} onClick={()=>{setfiltroTK('ABIERTO')}}>Abiertos</Button>
                                    {permisos['ticket.update911'] &&(
                                        <>
                                            <Button style={{width: '100px'}} variant={filtroTK === 'PORVENCER' ? 'warning' : 'outline-warning'} onClick={()=>{setfiltroTK('PORVENCER')}}>Por Vencer</Button>
                                            <Button style={{width: '100px'}} variant={filtroTK === 'VENCEHOY' ? 'danger' : 'outline-danger'} onClick={()=>{setfiltroTK('VENCEHOY')}}>Vence Hoy</Button>
                                            <Button style={{width: '100px'}} variant={filtroTK === 'VENCIDO' ? 'dark' : 'outline-dark'} onClick={()=>{setfiltroTK('VENCIDO')}}>Vencidos</Button>
                                        </>
                                    )}    
                                    <Button style={{width: '100px'}} variant={filtroTK === 'CERRADO' ? 'dark' : 'outline-dark'} onClick={()=>{setfiltroTK('CERRADO')}}>Cerrados</Button>
                                    <Button style={{width: '100px'}} variant={filtroTK === '' ? 'primary' : 'outline-danger'} onClick={()=>{setfiltroTK('')}}>Todos</Button>
                                <h5 className="m-2">
                                    Total Ticket Filtrados: 
                                    {filtro?.length}
                                </h5>
                                </div>
                                <div className="row">
                                    <div className="offset-lg-12 col-lg-12">
                                        { filtro.length > 0 &&(
                                            filtro?.map((ticket)=>{
                                                return <TicketCard hideModal={hideModal} history={history} item={ticket.ticket} key={ticket.ticket.id_tk} className="mb-3" hidenronom />
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalTicketTrato;
