import React, {useState,useContext} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { useSelector } from 'react-redux';
import {toast} from 'react-toastify';
import {Button, ButtonToggle, SelectGrupos, SelectUser} from '@app/components/index';
import TicketService  from '@app/services/tickets.service';
import { SocketContext } from '../../context/SocketContext';

function ModalAsignarTicket({show, hideModal, ticketId, onGuardar,type,socketOld,ultimoComentario}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [asigar, setAsignar] = useState('GRUPO');
    const [asignadoTK, setAsignadoTK] = useState();
    const [asignadoPG, setAsignadoPG] = useState();
    const { sockets } = useContext( SocketContext );
    const user = useSelector((state) => state.auth.currentUser);

    const Asignar = [
        {
            name: 'PERSONAL',
            value: 'DNI',
        },
        {
            name: 'GRUPO',
            value: 'GRUPO',
        }
    ];
    const onSubmit = async() =>{
        if(ultimoComentario?.createdPersonaId_com === user?.id_p){
            const form ={
                ticketId_uxt:Number(ticketId),
                tipo_uxt:asigar,
                tipoId_uxt: asignadoTK,
                type: type
            };
            await TicketService.asignar(form).then(()=>{
                onGuardar();
                sockets?.emit('newTicket',asignadoPG.toString() )
                sockets?.emit( 'ticketRefresh', `cvv${ticketId}` )
                if(socketOld){
                    sockets?.emit( 'refreshTicket', `${socketOld.toString()}R` )
                }
                hideModal();
            })
        }else{
            toast.error('Debe realizar un comentario.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
        }
    }

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Asignacion del ticket # ${ticketId} `}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="col-12 text-center mb-3">
                <ButtonToggle
                    name="tipoTareaId"
                    items={Asignar}
                    value={asigar}
                    onChange={(opt) => {
                        setAsignar(opt);
                        setAsignadoTK(null)
                        setAsignadoPG(null)
                    }}
                />
            </div>
            <div>
                { asigar === 'DNI' ? (
                    <div className="col-12">
                        <SelectUser
                            id="selectUser"
                            filter="select"
                            onChange={(opt) => {
                                setAsignadoTK(opt.value);
                                setAsignadoPG(opt.dni)
                            }}
                        />
                    </div>
                ):(
                    <div className="col-12">
                        <SelectGrupos
                            id="selectGrupo"
                            contactoId={asignadoTK}
                            onChange={(opt) => {
                                setAsignadoTK(opt.value);
                                setAsignadoPG(opt.value);
                            }}
                        />
                    </div>
                )}
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button
                    theme="primary"
                    onClick={onSubmit}
                    disabled={!asignadoTK}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalAsignarTicket;
