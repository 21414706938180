import axios from 'axios';
const base = 'productos';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    const form = new FormData();
    form.append('empresaId_pr',data.empresaId_pr);
    form.append('categoria_pr',data.categoria_pr);
    form.append('nombre_pr',data.nombre_pr);
    form.append('nombreCorto_pr',data.nombreCorto_pr);
    form.append('marca_pr',data.marca_pr);
    form.append('moneda_pr',data.moneda_pr);
    form.append('moneda_pr',data.moneda_pr);
    form.append('importe_pr',data.importe_pr);
    form.append('descripcion_pr',data.descripcion_pr);
    if(Array.isArray(data.img_pr) && data.img_pr.length>0){
        form.append('img_pr',data.img_pr[0]);
    } else {
        form.append('img_pr',data.img_pr);
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function update(data) {
    const form = new FormData();
    form.append('id_pr',data.id_pr);
    form.append('empresaId_pr',data.empresaId_pr);
    form.append('categoria_pr',data.categoria_pr);
    form.append('nombre_pr',data.nombre_pr);
    form.append('nombreCorto_pr',data.nombreCorto_pr);
    form.append('marca_pr',data.marca_pr);
    form.append('modelo_pr',data.modelo_pr);
    form.append('moneda_pr',data.moneda_pr);
    form.append('importe_pr',data.importe_pr);
    form.append('descripcion_pr',data.descripcion_pr);
    if(Array.isArray(data.img_pr) && data.img_pr.length>0){
        form.append('img_pr',data.img_pr[0]);
    } else {
        form.append('img_pr',data.img_pr);
    }
    return axios.put([base, data.id_pr].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const ProductosService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy
};
export default ProductosService;