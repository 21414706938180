import React, {useState} from 'react'
import { Autocomplete } from '@react-google-maps/api';
import styled from 'styled-components';
import { Button } from '@app/components/index';
import {useWindowWidth} from '@react-hook/window-size';

const StylesContainer = styled.div`

    .control-panel-map-type{
        position: absolute;
        top: ${props => props.isMovil?'10px':'10px'};
        left: 10px;
    }
    .control-panel-zoom{
        position: absolute;
        bottom: ${props => `${props.bottomMin}px`};
        right: 10px;
        flex-direction: column;
        display: flex;
    }
`;

function ControlPanel({
    bottomMin,
    coordinates,
    zoom,
    map,
    options,
    mapTypeId,
    isSearchable,

    onChangeMapType,
    onChangeZoom,
    onSearch,
}) {
    const onlyWidth = useWindowWidth();
    const [searchBox, setSearchBox] = useState(null);

    const onClickMapType = (newMapTypeId) => {
        if((mapTypeId === 'roadmap' && newMapTypeId === 'satellite') || (mapTypeId === 'satellite' && newMapTypeId === 'satellite')){
            onChangeMapType('hybrid');
        } else {
            onChangeMapType(newMapTypeId);
        }
    }

    const onLoadSearchBox = React.useCallback(function callback(newSearchBox) {
        setSearchBox(newSearchBox);
    }, []);

    const onPlaceChanged = () => {
        const field = searchBox.getPlace();
        if(field.geometry){
            const newCoords = {
                lat: field.geometry.location.lat(),
                lng: field.geometry.location.lng(),
            }
            map.setCenter(newCoords);
            onSearch({
                coordenadas: newCoords,
                direccion: field.address_components[0].long_name,
            })
        }
    };

    const defaultBounds = {
        north: coordinates.lat + 0.1,
        south: coordinates.lat - 0.1,
        east: coordinates.lng + 0.1,
        west: coordinates.lng - 0.1,
    };
    const isMovil = onlyWidth < 992;
    return (
        <StylesContainer isMovil={isMovil} bottomMin={bottomMin}>
            <div className="control-panel-map-type">
                <Button
                    disabled={mapTypeId === 'roadmap'}
                    onClick={()=>onClickMapType('roadmap')} 
                >
                    NORMAL
                </Button>
                <Button
                    onClick={()=>onClickMapType('satellite')} 
                >
                    SATELITE
                </Button>
            </div>
            <div className="control-panel-zoom">
                <Button 
                    icon="fas fa-plus"
                    disabled={options?.maxZoom <= zoom}
                    onClick={()=>onChangeZoom(zoom+1)} 
                />
                <Button 
                    icon="fas fa-minus"
                    disabled={options?.minZoom >= zoom}
                    onClick={()=>onChangeZoom(zoom-1)} 
                />
            </div>
            {
                isSearchable && (
                    <Autocomplete
                        onLoad={onLoadSearchBox}
                        onPlaceChanged={onPlaceChanged}
                        options={{
                            bounds: defaultBounds,
                            origin: coordinates,
                            componentRestrictions: {country: "AR"}
                        }}
                    >
                        <input
                            className="mt-2"
                            type="text"
                            placeholder="Buscar direccion"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `240px`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-120px"
                            }}
                        />
                    </Autocomplete>
                )
            }
        </StylesContainer>
    )
}

ControlPanel.defaultProps = {
    bottomMin: 10,
    isSearchable: true,

    onChangeMapType: () => {},
    onSearch: () => {},
    onChangeZoom: () => {},
}

export default ControlPanel

