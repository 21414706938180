import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import FaseTratoService from '@app/services/faseTratos.service';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectFaseTrato = ({id, value, onChange, isInvalid, isClearable}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        FaseTratoService.getAll().then((response) => {
            setItems(response);
            const newOptions = response.map((item) => {
                return {
                    value: item.id_ft,
                    label: item.nombre_ft
                };
            });
            setOptions(newOptions);
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    newOptions.find((opt) => opt.value === Number(value))
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>({label:va.nombre_ft,value:va.id_ft})));
            }
        });
    }, []);
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectFaseTrato.defaultProps = {
    isClearable: false
};

export default SelectFaseTrato;
