import React, {useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,SelectUser} from '@app/components/index';
import UserService from '@app/services/personas.service';


function ModalCanalPrivado({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [persona, setPersona] = useState()

    const onSubmit = (data) => {
        const form = {
            id_p:persona
        };
        UserService.cpAlta(form)
            .then(() => {
                onGuardar();
                hideModal();
            })
            .finally(() => {
                setIsLoading(true);
            });
    };

    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Nuevo Canal Privado
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre_can">
                            Nombre
                        </label>
                        <SelectUser
                            id="selectUser"
                            filter='FalseCanalPriv'
                            onChange={(opt) => {
                                setPersona(opt.value);
                            }}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={!persona}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCanalPrivado;