import React from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {useForm,Controller} from 'react-hook-form';
import Sidebar from '@app/modules/main/menu-sidebar/SideBar';
import SideItem from '@app/modules/main/menu-sidebar/SideItem';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import usePermisos from '@app/hooks/usePermisos';
import { InputTicketSearch } from '@app/components/index';

const MenuSidebar = ({user, toggleMenuSidebar}) => {
    const {control} = useForm();
    const permisos = usePermisos();
    const navigate = useNavigate();
    const redireccionar = (tk) =>{
        navigate(`/tickets/${tk.id_tk}/ver`)
    }
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{backgroundColor: '#345380'}}>
            <Link to="/" className="brand-link" style={{borderColor:'#818181',borderWidth: 0.1}}>
                <img
                    src="/img/911-50.png"
                    alt=""
                    style={{
                        width: '100%',
                        maxWidth: 60,
                        filter: 'contrast(0.8) brightness(1.5)',
                        marginRight: 12,
                    }}
                />
                <img
                    className="font-weight-normal"
                    src="/img/emergencias-50.png"
                    alt=""
                    width="40%"
                    height="40%"
                    style={{filter: 'contrast(0.8) brightness(1.2)'}}
                />
            </Link>
            <OverlayScrollbarsComponent
                options={{
                    scrollbars: {
                        autoHide: 'leave',
                        clickScrolling: true
                    },
                    className: 'os-theme-light',
                    sizeAutoCapable: true
                }}
                className="sidebar"
            >
                <div className="user-panel mt-2 pb-3 mb-3 d-flex" style={{borderColor:'#818181',borderWidth: 0.1}}>
                    <div className="image">
                        <img
                            src={(user && user?.foto_p ? user?.foto_p : '/img/default-profile.png')}
                            className="img-circle mt-2"
                            alt="User"
                            style={{
                                boxShadow: '0px 1px 3px 1px rgb(209 216 255 / 50%)'
                            }}
                        />
                    </div>
                    <div className="info">
                        <Link to="" className="d-block mt-2" style={{lineHeight:'1.5em'}}>
                            {user?.nombre_p}
                        </Link>
                    </div>
                </div>

                <nav className="mt-2">
                    <Sidebar toggleMenuSidebar={toggleMenuSidebar}>
                        <div className='d-md-none d-sm-inline-block'>
                            <div className='col-lg-3 col-md-6 mb-3 searchBox'>
                                <i className="fas fa-search"></i>
                                <Controller
                                    as={InputTicketSearch}
                                    control={control}
                                    name="clienteNombreBuscador"
                                    id="clienteNombreBuscador"
                                    onSelect={(opt) => {
                                        if (opt && opt.length > 0) {
                                            redireccionar(opt[0])
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <SideItem
                            icon="fa-desktop"
                            text="Escritorio"
                            to="/"
                        />
                        <SideItem
                            icon="fas-tags"
                            text="Tickets"
                            to="/tickets"
                        />
                        { user && user?.razonSocial_p && user?.razonSocial_p !== 'Policia de la Provincia de Salta' && permisos['ticket.update911'] && (
                            <SideItem
                                icon="fas-tags"
                                text="Pre-Tickets"
                                to="/preticket"
                            />
                        )}
                        <SideItem icon="fa-table" text="Tablas" style={{backgroundColor: '#345380'}}>
                            <SideItem
                                icon="fa-database"
                                text="Tickets"
                                to="/tabla/ticket"
                            />
                        </SideItem>
                        {user && (permisos['config.nubi.esp'] ) &&(
                            <SideItem icon="fa-cogs" text="Configuracion" style={{backgroundColor: '#345380'}}>
                                <SideItem
                                    icon="fas-search"
                                    text="Grupos"
                                    to="/grupos"
                                />
                                <SideItem
                                    icon="fas-check"
                                    text="Motivo Ticket CVV"
                                    to="/ticket/motivoscvv"
                                />
                            </SideItem>
                        )}
                        {user && (permisos['grupo.abm'] ) &&(
                            <SideItem icon="far-address-book" text="Administrador" style={{backgroundColor: '#345380'}}>
                                <SideItem
                                    icon="fas-search"
                                    text="Notificaciones"
                                    to="/notificaciones"
                                />
                                <SideItem
                                    icon="fas-search"
                                    text="Permisos"
                                    to="/permisos"
                                />
                                <SideItem
                                    icon="fas-search"
                                    text="Grupos"
                                    to="/grupos"
                                />
                                <SideItem
                                    icon="fas-map-marked-alt"
                                    text="Lugares"
                                    to="/lugares"
                                />
                                <SideItem
                                    icon="fas-check"
                                    text="Motivo Ticket CVV"
                                    to="/ticket/motivoscvv"
                                />
                            </SideItem>
                        )}
                        {user && permisos['trato.getAll911'] &&(
                            <>
                                <SideItem
                                    icon="fa-vr-cardboard"
                                    text="CVV"
                                    to="/cvv"
                                />
                                <SideItem
                                    icon="fa-video"
                                    text="Camaras"
                                    to="/tratoscam"
                                />
                                <SideItem
                                    icon="fa-map-marked-alt"
                                    text="Mapa"
                                    to="/tratosmap"
                                />
                            </>
                        )}
                            {user && (user?.role?.nombre_r === 'ADMIN_ROL') &&(
                                <SideItem
                                    icon="far-user"
                                    text="Operadores"
                                    to="/operadores"
                                />
                            )}
                    </Sidebar>
                </nav>
            </OverlayScrollbarsComponent>
        </aside>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(MenuSidebar);
