import { useMemo, useEffect, useState } from 'react';
import io from 'socket.io-client';

const useSocket = ( serverPath ) => {
    const token = localStorage.getItem('token');
    const sockets = useMemo( () => io.connect( serverPath, { 
        transports: ['websocket'],
        autoConnect: true,
        forceNew: true,
        query: {
            'nun-otoken': token
        }
    })
    , [ serverPath ] );
    const [ online, setOnline ] = useState(false);

    useEffect(() => {
        setOnline( sockets.connected );
    }, [ sockets ])

    useEffect( () => {
        sockets.on('connect', () => {
            setOnline( true );
        })

    }, [ sockets ])

    useEffect( () => {

        sockets.on('disconnect', () => {
            setOnline( false );
        })

    }, [ sockets ])

    return {
        sockets,
        online
    }
}
export default useSocket;