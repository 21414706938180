import types from "@app/store/types/types";

const defaultData = {
    reloading:false,
    reloadingInd:false,
    reloadingGroup:false,
    reloadingPendiente: false,
    reloadingExterna: false,
    reloadingInvalid: false
}
const ticketReducer = (state = defaultData, action) => {

    switch (action.type) {
        case types.ticketReloadStart:
            return {
                ...state,
                reloading: true
            }
        case types.ticketReloadFinish:
            return {
                ...state,
                reloading: false
            }
        case types.ticketReloadIndStart:
            return {
                ...state,
                reloadingInd: true
            }
        case types.ticketReloadIndFinish:
            return {
                ...state,
                reloadingInd: false
            }
        case types.ticketReloadGroupStart:
            return {
                ...state,
                reloadingGroup: true
            }
        case types.ticketReloadGroupFinish:
            return {
                ...state,
                reloadingGroup: false
            }
        case types.ticketReloadExternaStart:
            return {
                ...state,
                reloadingExterna: true
            }
        case types.ticketReloadExternaFinish:
            return {
                ...state,
                reloadingExterna: false
            }
        case types.ticketReloadPendienteStart:
            return {
                ...state,
                reloadingPendiente: true
            }
        case types.ticketReloadPendienteFinish:
            return {
                ...state,
                reloadingPendiente: false
            }
        case types.ticketReloadInvalidStart:
            return {
                ...state,
                reloadingInvalid: true
            }
        case types.ticketReloadInvalidFinish:
            return {
                ...state,
                reloadingInvalid: false
            }
        default:
            return state;
    }
}

export default ticketReducer;