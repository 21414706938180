import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '@app/context/SocketContext';
import FechaFunction from '@app/functions/FechaFunction';

const TicketNotifyCard = ({item,toggleDropdown,isModal}) => {
    const navigate = useNavigate();
    const { sockets } = useContext( SocketContext );
    const arrAcciones = ['APERTURA','CIERRE POR UNA SITUACION EXTERNA','CIERRE INVALIDO / MAL CARGADO','CIERRE','RE-APERTURA']
    const redirect = ()=>{
        if(!item?.visto_tkntf){
            sockets?.emit('newViewTicketNotify', item?.id_tkntf )
        }
        if(toggleDropdown){
            toggleDropdown();
        }
        navigate(`/tickets/${item?.ticketId_tkntf}/ver`)
    }
    return (
        <div className={isModal ? "col-12" : "dropdown-item"} onClick={() => redirect()} aria-hidden="true" style={{backgroundColor: !item?.visto_tkntf ? '#E3F2FF' : '#F8F8F8', cursor: 'pointer' }}>
            <div className="media">
                <img src="/img/911-logo.png" alt="login" className="iconoEmpTicketCard" />
                <div className="media-body ml-3">
                    {arrAcciones.includes(item?.accion_tkntf) ? (
                        <p style={{fontSize:!isModal && '13px'}}>
                            {`El personal ${item?.senduser?.nombre_p || ''} `}
                            {(item?.accion_tkntf === 'APERTURA'  || item?.accion_tkntf === 'RE-APERTURA')
                                ? 'realizo la' : ' realizo el' 
                            }
                            <b>{` ${item?.accion_tkntf} `}</b>
                            del ticket 
                            <b>{` Nro ${item?.ticketId_tkntf}`}</b>
                            .
                        </p>
                    ):(
                        <p style={{fontSize:!isModal && '13px'}}>
                            {`${item?.senduser?.nombre_p || ''} ${item?.tipoDestino_tkntf === 'DNI' ? 'Te asigno en el ticket' : 'Asigno al grupo en el ticket'}`}
                            <b>{` Nro ${item?.ticketId_tkntf}`}</b>
                        </p>
                    )}
                    <div className='row'>
                        { item?.createdAt && (
                            <p className="text-muted" style={{fontSize:!isModal && '11px'}}>
                                <i className="far fa-clock mr-1" />
                                <span>
                                {FechaFunction.timeAgoMentions(new Date(item?.createdAt))}
                                </span>
                            </p>
                        )}
                        {item?.visto_tkntf &&(
                            <>
                                <p className="text-muted" style={{fontSize:!isModal && '11px'}}>
                                    <i className={`fas fa-eye mr-1 ${isModal && 'ml-md-2'}`} />
                                    {FechaFunction.format(new Date(item?.visto_tkntf),'dd/MM/yyyy HH:mm:ss')}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketNotifyCard;