import React, { useEffect, useState } from 'react'
import { Button, TicketVistaCard } from '@app/components/index';
function TabsTicketCreate({
    filtro,
    items,
    setTkCreatedPage,
    hasMore,
    isLoading
}) {
    const [tickets, setTickets] = useState(items);
    useEffect(() => {
        if(filtro==='TODOS'){
            setTickets(items);
        }else{
            setTickets(items?.filter(ticket => ticket.estado_tk === filtro));
        }
    }, [filtro,items])
    return (
        <div className="p-3 p-lg-5">
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    { tickets && tickets?.length > 0 ? (
                        <>
                        
                        {tickets?.map((ticket)=>{
                            return <TicketVistaCard item={ticket} tratos={ticket.tratos} key={ticket.id_tk} className="mb-3" hideClickMap />
                        })}
                        {hasMore && (
                            <Button
                                onClick={()=> setTkCreatedPage((prev)=> prev+1)}
                                isLoading={isLoading}
                                tooltipText='Se traeran los tickets anteriores (Limite 10).'
                            >
                                Cargar Mas
                            </Button>
                        )}
                        </>
                    ):(
                        <div className='alert alert-light mt-2 text-center'>
                            <i className="fas fa-inbox" style={{fontSize: 200, color: 'gray'}}></i>
                            <div style={{fontSize: 20, color: 'gray'}}>
                                Aún no creaste tickets
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    )
}


export default TabsTicketCreate;