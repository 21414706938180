import React, { useEffect, useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import {showModal} from 'react-redux-modal-provider';
import { Button } from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import { Card } from 'react-bootstrap';
import ModalTicketPrioridad from '@app/modals/ticket/ModalTicketPrioridad';
import TextMark from '@app/components/text/TextMark';

function TicketVistaCard({
    textSearch = '',
    item,
    tratos,
    hideIrTicket,
    onGuardar,
    onCloseModal
}) {
    const navigate = useNavigate();
    const onVerTicket = () => {
        if(onCloseModal){
            onCloseModal()
        }
        navigate(`/tickets/${item?.id_tk}/ver`);
    };
    const onOpenEditar = (ticket,tr) => {
        showModal(ModalTicketPrioridad, {item: ticket, trato: tr, onGuardar: onGuardar});
    };
    const [trato, settrato] = useState()
    useEffect(() => {
        if(tratos && tratos?.length >= 1){
            settrato(tratos[0])
        }
    }, [])
    return (
        <Card style={{backgroundColor:'#F8F8F8'}}>
            <div className={`ml-3 mt-2 pill pill${item?.estado_tk}`}>
                <span>
                    { item?.estado_tk === 'PORVENCER' && 'POR VENCER'}
                    { item?.estado_tk === 'VENCIDO' && item?.cerrado_tk
                        ? 'CERRADO-VENCIDO'
                        : item?.estado_tk !== 'PORVENCER' && item?.estado_tk
                    }
                </span>
            </div>
            <div className='col-lg-12 col-md-12'>
                <div className='row'>
                    <div className='col-1 mt-2 ml-3'>
                        <img src="/img/911-logo.png" alt="login" className="iconoEmpTicketCard"></img>
                    </div>
                    <div className='col-lg-10 col-md-12'>
                        <Card.Body>
                            <div className="row">
                                <div className="col-10">
                                    <h6 style={item?.visto_tk ? {color:'#345380'} : {fontWeight:'bold',color:'#345380'}}>
                                        <TextMark texto={`# ${item?.id_tk} - ${item?.titulo_tk}`} textMark={textSearch} />
                                    </h6>
                                    <div className="row">
                                        { trato && item?.createdEmp_tk === 'CVV' && (
                                            <Link style={{marginLeft:15, fontSize: 12 }} to={`/tratos/${trato?.id_t}/vercam`}>{`${trato?.nombre_t},${trato?.direccion_t || ''}`}</Link>
                                        )}
                                        {(item?.createdAt || item?.idIncidenciaExt_tk) && (
                                            <footer className="blockquote-footer ml-2">
                                                <TextMark
                                                    texto={`${item?.idIncidenciaExt_tk ? 'N° Incidencia:': ''} ${item?.idIncidenciaExt_tk ? item?.idIncidenciaExt_tk: ''} ${item?.createdAt ? FechaFunction.format(new Date(item?.createdAt),'dd/MM/yyyy HH:mm:ss'): ''}`}
                                                    textMark={textSearch}
                                                />
                                            </footer>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-2 mt-3 col-md-12">
                                    {hideIrTicket ?(
                                        <Button
                                            className="btn btn-block"
                                            style={{fontSize:"0.8em"}}
                                            onClick={()=>onOpenEditar(item,trato)}
                                        >
                                            Cargar Prioridad
                                        </Button>
                                    ):(
                                        <Button
                                            className="btn btn-block"
                                            style={{fontSize:"0.8em"}}
                                            onClick={onVerTicket}
                                        >
                                            Ir al Ticket
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </div>
                </div>
            </div>
        </Card>

    )
}

export default TicketVistaCard;