import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import SubMotivoTicketService from '@app/services/subMotivoTicket.service';

const Styles = styled.div``;

const SelectSubMotivoTicket = ({id, value, onChange, isInvalid, isClearable,emp,grupoId}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        SubMotivoTicketService.getAll({
            emp: emp
        }).then((response) => {
            setItems(response);
        });
    }, []);
    useEffect(() => {
        let array = items;
        if(grupoId){
            array = items.filter((item)=> item.grupoId_smt === grupoId);
        }
        const newOptions = array.map((item) => {
            return {
                value: item.id_smt,
                label: item.titulo_smt,
                descripcion: item.cuerpo_smt,
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.motivo_smt,value:va.id_smt})));
        }
    }, [grupoId,items])
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectSubMotivoTicket.defaultProps = {
    isClearable: false
};

export default SelectSubMotivoTicket;
