import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location
   useEffect(() => {
      if(state === null){
        navigate(-1)
      }else{
        navigate(state,{state: 'load'})
      }
  }, [])

  return(
  <div>
  </div>
  )
};

export default RedirectTicket;