import React, {useState, useEffect, useContext} from 'react';
import {connect, useSelector} from 'react-redux';
import MotivoTicketService from '@services/motivoTicket.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalMotivoTareaEditar from '@app/modals/motivoTicket/ModalMotivoTicket';
import {Button, ModalConfirm} from '@app/components/index';
import { SocketContext } from '@app/context/SocketContext';
import { useNavigate } from 'react-router-dom';

const MotivoTicketCVVPage = ({
    user
}) => {
    const [items, setItems] = useState([]);
    const motivoTkCvv = useSelector(state => state.default.motivoTkCvv);
    const { sockets } = useContext( SocketContext );
    const navigate = useNavigate();

    useEffect(() => {
        setItems(motivoTkCvv);
    }, [motivoTkCvv])

    const actualizar = () => {
        sockets?.emit('newMotivoTKCvv');
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Motivo Ticket',
            message: '¿Esta seguro que desea eliminar el motivo ticket?',
            onConfirm: () => {
                MotivoTicketService.destroy(item.id_mt).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalMotivoTareaEditar, {item: item, onGuardar: actualizar, type: 'cvv'});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'motivo_mt'
            },
            {
                width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="danger"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        if(user){
            if(user && user.id_p > 9999){
                navigate('/')
            }
        }
    }, [user]);

    return (
        <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8 col-lg-6 my-2 my-lg-0 my-lg-3">
                            <h3 className="">Motivos Ticket</h3>
                        </div>
                        <div className="offset-1 col-3 offset-lg-4 col-lg-2 my-2 my-lg-3">
                    <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                        NUEVO
                    </Button>
                        </div>
                        <div className="col-12">
                            <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MotivoTicketCVVPage);
