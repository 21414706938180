import axios from 'axios';
const base = 'logs';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function getAllTareas(query = null) {
    return axios.get(`${base}/tareas`, {
        params: query
    });
}

function getAllTicketNotify(query = null) {
    return axios.get(`${base}/ticketnotify`, {
        params: query
    });
}

function getAllComentarioNotify(query = null) {
    return axios.get(`${base}/comentarionotify`, {
        params: query
    });
}

function ticketNotifyViewAll() {
    return axios.post('logs/ticketnotify/allview');
}

function comentarioNotifyViewAll() {
    return axios.post('logs/comentarionotify/allview');
}

function notifyViewAll() {
    return axios.post('logs/notify/allview');
}

const LogService = {
    getAll,
    getAllTareas,
    getAllTicketNotify,
    getAllComentarioNotify,
    ticketNotifyViewAll,
    comentarioNotifyViewAll,
    notifyViewAll
};

export default LogService;