import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import LoadingPage from '@app/pages/loading/loadingPage';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import './utils/axios';
import './utils/date';
import './App.scss';
library.add(fab, fas, far);

const App = () => {

    return (
        <Routes>
            <Route
                path="/login"
                element={(
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                )}
            />
            <Route
                path="/loading"
                element={(
                    <PrivateRoute>
                        <LoadingPage />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/*"
                element={(
                    <PrivateRoute>
                        <Main />
                    </PrivateRoute>
                )}
            />
        </Routes>
    );
};



export default App;

