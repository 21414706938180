import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import { PreTicketVistaCard, TicketVistaCard } from '@app/components/index';
import TablaTicketService from '@app/services/tablaTicket.service';
import CustomExternalTicketService from '@app/services/customExternal.service';

function TabsTicketPendiente({
    ticketrefresh
}) {
    const [tickets, setTickets] = useState([]);
    const actualizar = () =>{
        CustomExternalTicketService.getAll()
        .then((response)=>{
            setTickets(response);
        })
    }
    const onGuardar = () => actualizar();
    useEffect(() => {
        actualizar();
    }, [])

    useEffect(() => {
        if(ticketrefresh?.reloadingPendiente){
            actualizar();
        }
    }, [ticketrefresh])

    return (
        <div className="p-3 p-lg-5">
            <div className="row">
                <div className="offset-lg-12 col-lg-12">
                    {tickets && tickets?.length > 0 ? (
                        tickets?.map((ticket)=>{
                            return <PreTicketVistaCard item={ticket} key={ticket.id_ptk} onGuardar={onGuardar} className="mb-3" />
                        })
                    ):(
                        <div className='alert alert-light mt-2 text-center'>
                            <i className="fas fa-inbox" style={{fontSize: 200, color: 'gray'}}></i>
                            <div style={{fontSize: 20, color: 'gray'}}>
                                No hay tickets pendientes
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ticketrefresh: state.ticketrefresh
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TabsTicketPendiente)